/** Функция устанавливает настройки timepicker */
  $(document).on('blur change keyup', 'form #date_period_from, form #date_period_to', function(event) {
    var pattern       = '';
    var startDateTime = '';
    var endDateTime   = '';

    pattern = /(\d+).(\d+).(\d+)\s(\d+):(\d+):?(\d*)/ig;

    if($(this).val() !== '') {
      var dateTimeFrom = $(this).parents('.box-interval').find('#date_period_from').val();
      startDateTime = new Date(dateTimeFrom.replace(pattern, '$3-$2-$1T$4:$5:$6Z')).valueOf();

      var dateTimeTo = $(this).parents('.box-interval').find('#date_period_to').val();
      endDateTime = new Date(dateTimeTo.replace(pattern, '$3-$2-$1T$4:$5:$6Z')).valueOf();
    }

    var errorBox = $(this).parents('.box-interval').find('span#loging_datetime');

    if(!!endDateTime && startDateTime > endDateTime) {
      $(errorBox).parent().addClass('has-error').end().text('Некорректный временной интервал');
      return false;
    } else {
      $(errorBox).parent().removeClass('has-error').end().removeClass('has-error').text('');
    }
  });