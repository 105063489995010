function addInterceptorGroup(self) {
  var $select = $('.add-group-employer-select');
  var id = $select.find('option:selected').val() || 0;

  $.send('/interceptors/exists/' + id, {}, function(response){
    if (response.result === 'error') {
      addMessageError($select, 'Сотрудник отсутсвует');
    } else {
      removeMessageError($select);
      addEmployerToGroup(self);
    }
  });
}