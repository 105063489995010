  function createModal(modal, header, url, method, type) {
    var form   = $(modal);

    header = (header === undefined) ? form.data('title')  : header;
    method = (method === undefined) ? form.data('method') || 'post' : (method || 'post');
    url    = (url    === undefined) ? form.data('url')    : url;
    type   = (type   === undefined) ? form.data('type')   || 'json'  : (type   || 'json');

    /** Получение данных всех полей формы */
    function getDataForm() {
      var result = {};

      $(modal).find ('input, textarea, select').each(function() {
        result[this.name] = $(this).val();
      });

      return result;
    }

    makeModal({
      id       : modal,
      title    : header,
      messages : $('<div>').append(form.removeClass('hide')).html(),
      buttons  : [{
        text     : 'Сохранить', handler: function () {

          if(!isModalFormValid()) {return false;}

          $.ajax({
            type      : method,
            dataType  : type,
            url       : url,
            data      : getDataForm(),
            beforeSend: function () {
              $(modal + ' .btn.btn-success').prop('disabled', true).html('Отправка...');
            },
            success   : function (response) {
              if (response.result === 'success') {
                location.reload();
              }
            },
            error     : function (response) {
              console.warn('Произошла ошибка.');
            }
          });
          return false;
        }
      }],

      events: [
      {
        name: 'hidden.bs.modal', func: function () {
          $('body').append(form);
          $(form).addClass('hide');
          $(modal).remove();

          return false;
        }
      }
      ]
    }); 
  }

/**
 * Функция отправляет запрос на редектирование данных
 */
$(document).on('click', '.buttons a[href="#"]#edit, td a[href="#"]#edit', function(event){

  event.preventDefault ? event.preventDefault() : event.returnValue = false;

  var header = 'Редактирование';
  var modal  = $(this).data('modal');
  var source = $(this).data('source');
  var target = $(this).data('target');
  
  // Заполняем форму модального окна
  $.ajax({
    type      : 'get',
    dataType  : 'json',
    url       : source,
    success   : function (response) {
      $(modal).find ('input, textarea, select').each(function() {
        // Имена полей формы соответсвуют названию полей из БД, при формировании ответвета
        $(this).val(response.result[this.name]);
      });
    },
    error     : function (response) {
      console.warn('Произошла ошибка.');
    }
  });

  // Открываем модольное окно с новым заголовком и URL
  createModal(modal, header, target);
});

/**
 * Функция отправляет запрос на удаление данных
 */
$('.buttons a[href="#"]#delete, td a[href="#"]#delete').click(function(event) {
  event.preventDefault ? event.preventDefault() : event.returnValue = false;

  var target = $(this).data('target');

  Confirm('Удалить группу вызова?', function(){
    $('#modal').find('.btn-success').loading();

    $.ajax({
      type      : 'get',
      dataType  : 'json',
      url       : target,
      success   : function (response) {
        if (response.result === 'success') {
          location.reload();
        }
      },
      error     : function (response) {
        console.warn('Произошла ошибка.');
      }
    });
  });
});