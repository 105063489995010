function saveCompany(self) {
  var $form = $(self).closest('form');
  var $clone = $form.clone(true);

  var selects = [
    'define_number_id'
  ];

  $clone.find('.hide').remove();
  $form.removeAttr('data-form_changed');
  $form.find('.save-company').loading();

  for (var i in selects) {
    if ($('select[name=' + selects[i] + '] option').length > 1) {
      $clone.find('select[name=' + selects[i] + ']').val($form.find(':not(.hide) > select[name=' + selects[i] + ']').val());
    }
  }

  $.send($clone.attr('action'), $clone.serializeArray(), function(result){
    makeModal({
      messages: 'Данные сохранены',
      buttons : [{text: 'OK', handler: function() {
        $('#modal').modal('hide');
        location.assign('/' + $clone.attr('action').split('/')[1]);
      }}]
    });
  });

  return false;
}

function delCompany(self) {
  Confirm('Удалить компанию?', function(){
    $('#modal').find('.btn-success').loading();
    $.send('/companies/del/' + $(self).data('id'), {}, function(result){
      $(self).closest('tr').remove();
    });
  });
}