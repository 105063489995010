function voiceSynthesizer() {
  /**
   * Обработчик
   * @author Yury Lugovoy
   */
  this.handler = function() {
    $(document).ready(function(){
      if($(document).find('#jquery_jplayer')){
        initJPlayer("#jquery_jplayer", {});
      }
    });
  };

  /**
   * Отправка запроса на получение звукового файла
   * @author Yury Lugovoy
   */
  this.getFile = function() {
    text = $('#text').val();
    var alias = $('#alias').val();

    if(!!text) {
      document.location = '/voice_synthesizer/get/' + text + '&' + alias;
    }
  };

  /**
   * Сохранение файла
   * @author Yury Lugovoy
   */
  this.save = function () {
    var name = $('#name').val(),
      text = $('#text').val(),
      alias = $('#alias').val();
    $.send('/voice_synthesizer/save', {name: name, alias: alias, text: text}, function(result) {
      if (!result.length) {
        location.href = '/audio_files';
      }
    });
  };

  /**
   * Модалка с плеером
   * @author Yury Lugovoy
   */
  this.showRecordFormModal = function(){
    var modalBody = '<div id="jquery_jplayer_modal_body"></div>',
      text = $('#text').val();
    if ( !$.trim(text) ) {
      return false;
    }
    makeModal({
      id      :"#jquery_jplayer_modal",
      title   :"",
      messages:modalBody,
      buttons :false,
      events  : [
        {name:"show.bs.modal",func:function(){
          $("#jquery_jplayer_tpl > div").appendTo("#jquery_jplayer_modal_body");
        }},
        {name:"shown.bs.modal",func:function(){
          $("#jquery_jplayer").jPlayer("setMedia",{mp3: '/voice_synthesizer/get/' + text}).jPlayer("play");
        }},
        {name:"hide.bs.modal",func:function(){
          $("#jquery_jplayer_modal_body > div").appendTo("#jquery_jplayer_tpl");
          $("#jquery_jplayer").jPlayer("clearMedia");
        }}
      ],
    });
  };

  /**
   * Инициализация плеера
   * @author Yury Lugovoy
   * @param el
   * @param mediaObjects
   */
  initJPlayer = function(el, mediaObjects){
    var myPlayer = $(el),
      myPlayerData,
      fixFlash_mp4, // Flag: The m4a and m4v Flash player gives some old currentTime values when changed.
      fixFlash_mp4_id, // Timeout ID used with fixFlash_mp4
      ignore_timeupdate, // Flag used with fixFlash_mp4
      options = {
        ready: function (event) {
          // Hide the volume slider on mobile browsers. ie., They have no effect.
          if(event.jPlayer.status.noVolume) {
            // Add a class and then CSS rules deal with it.
            $(".jp-gui").addClass("jp-no-volume");
          }
          // Determine if Flash is being used and the mp4 media type is supplied. BTW, Supplying both mp3 and mp4 is pointless.
          fixFlash_mp4 = event.jPlayer.flash.used && /m4a|m4v/.test(event.jPlayer.options.supplied);
          // Setup the player with media.
          $(this).jPlayer("setMedia", mediaObjects);
        },
        timeupdate: function(event) {
          if(!ignore_timeupdate) {
            myControl.progress.slider("value", event.jPlayer.status.currentPercentAbsolute);
          }
        },
        volumechange: function(event) {
          if(event.jPlayer.options.muted) {
            myControl.volume.slider("value", 0);
          } else {
            myControl.volume.slider("value", event.jPlayer.options.volume);
          }
        },
        swfPath: "../js",
        supplied: "mp3, m4a, oga",
        cssSelectorAncestor: "#jp_container",
        wmode: "window",
        keyEnabled: true
      },
      myControl = {
        progress: $(options.cssSelectorAncestor + " .jp-progress-slider"),
        volume: $(options.cssSelectorAncestor + " .jp-volume-slider")
      };
    // Instance jPlayer
    myPlayer.jPlayer(options);
    // A pointer to the jPlayer data object
    myPlayerData = myPlayer.data("jPlayer");
    // Create the progress slider control
    myControl.progress.slider({
      animate: "fast",
      max: 100,
      range: "min",
      step: 0.1,
      value : 0,
      slide: function(event, ui) {
        var sp = myPlayerData.status.seekPercent;
        if(sp > 0) {
          // Apply a fix to mp4 formats when the Flash is used.
          if(fixFlash_mp4) {
            ignore_timeupdate = true;
            clearTimeout(fixFlash_mp4_id);
            fixFlash_mp4_id = setTimeout(function() {
              ignore_timeupdate = false;
            },1000);
          }
          // Move the play-head to the value and factor in the seek percent.
          myPlayer.jPlayer("playHead", ui.value * (100 / sp));
        } else {
          // Create a timeout to reset this slider to zero.
          setTimeout(function() {
            myControl.progress.slider("value", 0);
          }, 0);
        }
      }
    });
    // Create the volume slider control
    myControl.volume.slider({
      animate: "fast",
      max: 1,
      range: "min",
      step: 0.01,
      value : $.jPlayer.prototype.options.volume,
      slide: function(event, ui) {
        myPlayer.jPlayer("option", "muted", false);
        myPlayer.jPlayer("option", "volume", ui.value);
      }
    });
  };

  /**
   * Простой транслит для формирование алиаса файла
   * За одно и уберем все не нужные символы
   * @param text
   * @returns {string}
   */
  function translit(text) {
    var newStr = '';
    var ru = ['а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ъ', 'ы', 'ь', 'э', 'ю', 'я', ' ', '_'],
      en = ['a', 'b', 'v', 'g', 'd', 'e', 'e', 'zh', 'z', 'i', 'i', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'f', 'kh', 'ts', 'ch', 'sh', 'shch', 'ie', 'y', '', 'e', 'iu', 'ia', '_', '_'];
    for (var i = 0; i < text.length; i++) {
      if ((text[i].toLowerCase().charCodeAt(0) >= 97 && text[i].toLowerCase().charCodeAt(0) <= 122) ||
        (text[i].toLowerCase().charCodeAt(0) >= 48 && text[i].toLowerCase().charCodeAt(0) <= 57)) {
        newStr += text[i].toLowerCase();
      } else {
        index = ru.indexOf(text[i].toLowerCase());
        if (index === -1) {
          newStr += '';
        } else {
          newStr += en[index];
        }
      }
    }
    return newStr;
  }

  /**
   * Функция добавляет подписи к кнопкам и делает их неактивными.
   */
  $('.form-group-textarea-icons span').each(function(key, val) {
    $(val).attr('data-toggle', 'tooltip').attr('data-placement', 'top').addClass('disabled');

    switch(key) {
      case 0: $(val).attr('title', 'Прослушать');
        break;
      case 1: $(val).attr('title', 'Скачать');
        break;
    }
  });

  /**
   * Функция проверяет поля на заполненность и наличие ошибок.
   * И в зависимсоти от этого меняет видимость кнопок "Прослушать" и "Скачать"
   *
   * @author  Sedov Stas
   */
  $(document).on('blur keyup','#name, #text', function(event) {
    var name  = $('#name');
    var nameError = $(name).parent().hasClass('has-error');
    var alias = $('#alias');
    var aliasError = $(alias).parent().hasClass('has-error');
    var text  = $('#text');
    var bar = $('.form-group-textarea-icons');

    if(!!$(text).val() &&
      (!!$(alias).val() && !aliasError) && (!!$(name).val() && !nameError)
    ) {
      $(bar).find('span').removeClass('disabled');
    }
    else {
      $(bar).find('span').addClass('disabled');
    }

    if ($('#alias').val() != null) {
      $('#alias').val(translit($('#name').val()));
    }
  });

}