/*
* @Author: Sedov Stas
* @Date:   2019-01-16 15:34:05
* @Last Modified by:   Sedov Stas
*/

/**
 * Функция получает GET-параметр из URL.
 *
 * @author  Sedov Stas
 */
var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
  sURLVariables = sPageURL.split('&'),
  sParameterName,
  i;
  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
};

(function() {
  var limit = parseInt(getUrlParameter('limit'), 10) || 50;
  var page  = parseInt(getUrlParameter('page'), 10) || 1;

  // Изменение активности кол-ва записей на странице
  var paging_limit = $(document).find('.paging-limit');

  $(paging_limit).find('li').each(function(key, val) {
    var count = $(val).find('a').data('limit');
    $(this).find('a').removeClass('black').addClass('link_dotted');
    
    if(count === limit) {
      $(this).find('a').removeClass('link_dotted').addClass('black');
    }
  });

  // Изменение активности номера текущей страницы
  var paging_page = $(document).find('.paging-page');

  $(paging_page).find('li').each(function(key, val) {
    var count = parseInt($(val).find('a').text(), 10);
    $(this).find('a').removeClass('black');
    
    if(count === page) {
      $(this).find('a').removeClass('link_dotted').addClass('black');
    }
  });
}());
