/**
 * Функция отправляет файл Excel для импорта в БД
 */
$(document).on('change', '.link-upload-inline', function(event) {
  var data = new FormData();

  var method = $(this).find('label').data('method');
  var action = $(this).find('label').data('action');

  var allowedMimetypes = [
      'application/vnd.ms-excel',
      'application/vnd-xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/xls',
      'application/x-xls',
      'application/vnd.ms-excel',
      'application/msexcel',
      'application/x-msexcel',
      'application/x-ms-excel',
      'application/x-excel',
      'application/x-dos_ms_excel',
      'application/excel'
  ];

  var errorField = $(this).parents('.row').find('.form-group .help-block');
  var errors = [];

  $.each($(this).find('input[type=file]').get(0).files, function (key, value) {
    data.append('files', value);

    if(allowedMimetypes.indexOf(value.type) === -1) {
      errors.push('Формат файла должен быть xls или xlsx');
    }

    if(value.size > 5242880) {
      errors.push('Размер файла не должен превышать 5 Мб');
    }
  });

  if (data.getAll('files').length === 0) {
    errors.push('Файл не выбран');
  }

  if(errors.length > 0) {
    $(errors).each(function(key, val) {
      addMessageError(errorField, val);
    });
  } else {
    removeMessageError(errorField);

    $.send(action, data, {
      processData: false,
      contentType: false,
      success: function (response) {
        if (response.result === 'success') {
          location.reload();
        }
      }
    });
  }
});