$(document).ready(function() {
  jQuery.fn.extend({
    scrollTo: function(position) {
      if ( ! this.length) {
        return;
      }
      
      var top = 0;
      
      // Если передаётся координата - используем её, иначе вычисляем
      top = (position !== undefined) ? position : this.offset().top;

      $('html, body').animate({
        scrollTop: top - 15
      }, Math.abs(window.scrollY - top) / 600 * 1000);
    },

    classes: function (callback) {
      var classes = [];
      $.each(this, function (i, v) {
        var splitClassName = v.className.split(/\s+/);
        for (var j in splitClassName) {
          var className = splitClassName[j];
          if (-1 === classes.indexOf(className)) {
            classes.push(className);
          }
        }
      });
      if ('function' === typeof callback) {
        for (var i in classes) {
          callback(classes[i]);
        }
      }
      return classes;
    },

    loading: function(btn_class_to, btn_class_from, text, exit_if_loading) {
      var $button = this;
      exit_if_loading = exit_if_loading || true;

      if (exit_if_loading && $button.hasClass('loading')) {
        return true;
      }

      btn_class_to = btn_class_to || 'warning';
      text = text || 'подождите...';
      if ( ! btn_class_from) {
        var classes = $button.classes(), btnClassExists;
        for (var cl in classes) {
          if ($.inArray(classes[cl], ['btn-block', 'btn-sm', 'btn-lg', 'btn-xs']) != -1) {
            continue;
          }
          btnClassExists = classes[cl].match(/^btn-([a-z\-]+)$/);
          if (btnClassExists) {
            btn_class_from = btnClassExists[1];
            break;
          }
        }
      }

      $button
        .addClass('loading' + (!exit_if_loading ? '-async' : ''))
        .removeClass('btn-' + btn_class_from)
        .addClass('btn-' +    btn_class_to)
        .attr('loading-class-from', btn_class_from)
        .attr('loading-class-to',   btn_class_to)
        .attr('loading-text-from',  $button.text())
        .text(text);

      return false;
    },

    pos: function () {
      var left = 0;
      var top = 0;
      var elem = this.get(0);
      if (!elem) {
        return {};
      }
      var width = elem.offsetWidth;
      var height = elem.offsetHeight;
      var scrollTop = typeof(window.pageYOffset) == 'undefined' ? document.documentElement.scrollTop : window.pageYOffset;
      var scrollLeft = typeof(window.pageXOffset) == 'undefined' ? document.documentElement.scrollLeft : window.pageXOffset;

      if (elem.getBoundingClientRect) {
        var bounds = elem.getBoundingClientRect();
        left = bounds.left + scrollLeft;
        top = bounds.top + scrollTop;
      } else {
        while (elem != null) {
          left += elem.offsetLeft;
          top += elem.offsetTop;
          elem = elem.offsetParent;
        }
      }
      return {left: left, top: top, right: left + width, bottom: top + height, width: width, height: height};
    }
  });
  (function($){
    $.setError = function(error) {
      if ( ! $('.container_content_title').next('.alert-error').length) {
        $('.container_content_title').after('<div class="alert alert-error hide"></div>');
      }
      $('.alert-error').removeClass('hide').html(error).scrollTo();
    };

    $.clearError = function() {
      $('.alert-error').addClass('hide').html('');
    };

    $.clearBlockErrors = function(block) {
      if (!block) {
        $('.form-group.has-error').removeClass('has-error').find('.help-block').html('');
        return;
      }

      $('.validate-entity-' + block + ' .help-block').html('');
      $('.validate-entity-' + block + ' .form-group').removeClass('has-error');
    }
  })(jQuery);
  incrementInput();
  formStyled();
  switchCheckboxes();
  handleFormChangeLeaveNotification();
  handleTableSort();
});

/**
 * Изменение значения числового input-а со стрелками
 */
function incrementInput(){
  //увеличение значения по клику на правую стрелку
  $(document).on('click', '.inputIncrement__rightArr', function(event){
    event.preventDefault();
    handleChangeIncrementInput(this, 1);
  });
  //уменьшение значения по клику на левую стрелку
  $(document).on('click', '.inputIncrement__leftArr', function(event){
    event.preventDefault();
    handleChangeIncrementInput(this, -1);
  });

  function handleChangeIncrementInput(self, change) {
    var input = $(self).parents('.inputIncrement').find('.inputIncrement__input'),
      inputVal = parseInt(input.val()),
      inputMin = ($.isNumeric(input.data('min')) ? parseInt(input.data('min')) : 1),
      inputMax = ($.isNumeric(input.data('max')) ? parseInt(input.data('max')) : 99);
    if (isNaN(inputVal)) {
      inputVal = parseInt(inputMin);
    }

    inputVal += change;

    if (input.data('no_zero') && inputVal == 0) {
      if (inputVal + change < inputMin) {
        inputVal -= change
      }
      else {
        inputVal += change;
      }
    }
    if (inputVal > inputMax) {
      inputVal = inputMax;
    }
    if (inputVal < inputMin) {
      inputVal = inputMin;
    }
    input.val(inputVal);
    input.keyup();
  }
}

/**
 * Стилизует элементы формы
 */
function formStyled(){
  inputRadioCheckboxStyled();
  inputFileStyled();
  $("select:not([multiple])").filter(':not(".select-search")').selectmenu({
    width:'100%',
    select:function(event, ui){
      var $target = $(event.target);
      $target.find('option').removeAttr('selected');
      $target.find('option[value="' + ui.item.value + '"]').attr('selected', 'selected');
      $target.val(ui.item.value); // иначе не работает в IE, Firefox
      $target.change();
    }
  }).css("height","150px");
  $('select[multiple]').multiselect({
    checkAllText     : 'Выбрать все',
    uncheckAllText   : 'Отключить все',
    noneSelectedText : 'Выбрать',
    selectedText     : function(numChecked, numInputs, checked){
      return $(checked).map(function(){
        if($(this).hasClass('icheck-input')){
          return $(this).parent().next().html();
        } else {
          return $(this).next().html();
        }
      }).get().join(', ');
    },
    selectedList:3,
    appendTo: '.modal',
    beforeopen: function(){
      inputRadioCheckboxStyled();
      //проверка для IE8
      if(Array.prototype.indexOf){
        $('.ui-multiselect-checkboxes:not(.ps-container)').perfectScrollbar({
          suppressScrollX : false
        }, 10);
      }
    },
    open: function(){
      $('.ui-multiselect-checkboxes').perfectScrollbar('update');
    },
    close: function(){
      $('.ui-multiselect-checkboxes').perfectScrollbar('destroy');
    },
    click: function(event, ui){
      var $target = $(event.target);
      setTimeout(function(){
        var values = $target.val();
        $target.find('option').removeAttr('selected');
        if (values) {
          for (var i in values) {
            $target.find('option[value="' + values[i] + '"]').attr('selected', 'selected');
          }
        }
        $target.val(values);
        $target.change();
      }, 0);
    }
  }).multiselectfilter({
    label:'<span class="icon icon-search"></span>',
    placeholder:''
  });

  selectMenuFilter('select.select-search');

  $.datepicker.regional['ru'] = {
    closeText: 'Закрыть',
    prevText: '<Пред',
    nextText: 'След>',
    currentText: 'Сегодня',
    monthNames: ['Январь','Февраль','Март','Апрель','Май','Июнь', 'Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
    monthNamesShort: ['Янв','Фев','Мар','Апр','Май','Июн','Июл','Авг','Сен','Окт','Ноя','Дек'],
    dayNames: ['воскресенье','понедельник','вторник','среда','четверг','пятница','суббота'],
    dayNamesShort: ['вск','пнд','втр','срд','чтв','птн','сбт'],
    dayNamesMin: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
    weekHeader: 'Не',
    dateFormat: 'dd.mm.yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  };
  $.datepicker.setDefaults($.datepicker.regional['ru']);

  $.timepicker.regional['ru'] = {
    timeOnlyTitle: 'Выберите время',
    timeText: 'Время',
    hourText: 'Часы',
    minuteText: 'Минуты',
    secondText: 'Секунды',
    currentText: 'Сейчас',
    closeText: 'ОК',
    ampm: false
  };
  $.timepicker.setDefaults($.timepicker.regional['ru']);

  $('.datepicker').datepicker();

  $('.datetimepicker').datetimepicker({
    showHour: true,
    showMinute: true,
    timeFormat: "HH:mm:ss"
  });

  $('.timepicker').timepicker();

  $("body").tooltip({
      selector: '[data-toggle="tooltip"]'
  });

  $('.mask-ip').mask('0ZZ.0ZZ.0ZZ.0ZZ', {
    translation: {
      'Z': {
        pattern: /[0-9]/, optional: true
      }
    }
  });
}

/**
 * Функция формирует селект с фильтром
 * @author Sedov Stas
 * @param  object|string field Объект поля select
 */
function selectMenuFilter(field) {
  if(typeof(field) === 'string') {
    field = $(document).find(field);
  }

  $(field).multiselect({
    checkAllText     : 'Выбрать все',
    uncheckAllText   : 'Отключить все',
    noneSelectedText : 'Выбрать',
    selectedText     : function(numChecked, numInputs, checked){
      return $(checked).map(function(){
        if($(this).hasClass('icheck-input')){
          return $(this).parent().next().html();
        } else {
          return $(this).next().html();
        }
      }).get().join(', ');
    },
    selectedList:1,
    multiple: false,
    appendTo: '.modal',
    beforeopen: function(){
      inputRadioCheckboxStyled();
      //проверка для IE8
      if(Array.prototype.indexOf){
        $('.ui-multiselect-checkboxes:not(.ps-container)').perfectScrollbar({
          suppressScrollX : false
        }, 10);
      }
    },
    open: function(){
      $('.ui-multiselect-checkboxes').perfectScrollbar('update');
    },
    close: function(){
      $('.ui-multiselect-checkboxes').perfectScrollbar('destroy');
    },
    click: function(event, ui){
      var $target = $(event.target);
      setTimeout(function(){
        var values = $target.val();
        $target.find('option').removeAttr('selected');
        if (values) {
          $target.find('option[value="' + values + '"]').attr('selected', 'selected');
        }
        $target.val(values);
        $target.change();
      }, 0);
    }
  }).multiselectfilter({
    label:'<span class="icon icon-search"></span>',
    placeholder:'',
    width: '462px'
  });
}

/**
 * Переключатель всех чекбоксов в блоке
 */
function switchCheckboxes(obj) {
  //включить все
  $(document).on('click', '.check_all', function(event){
    event.preventDefault();
    var item = $(this),
      itemText = $(this).text(),
    //если указан родитель, то поиск checkbox-ов внутри этого блока
      parent = item.parents('.wrap_checkboxes'),
      parent = (parent.length ? parent : $(document)),
      checkboxItems = parent.find('input[type="checkbox"]');
    checkboxItems.prop('checked',true).icheck('checked');
    item.text('Снять выделение').removeClass('check_all').addClass('uncheck_all');
  });
  //отключить все
  $(document).on('click', '.uncheck_all', function(event){
    event.preventDefault();
    var item = $(this),
      itemText = $(this).text(),
      parent = item.parents('.wrap_checkboxes'),
      parent = (parent.length ? parent : $(document)),
      checkboxItems = parent.find('input[type="checkbox"]');
    checkboxItems.prop('checked',false).icheck('unchecked');
    item.text('Выделить всё').removeClass('uncheck_all').addClass('check_all');
  });
  // При снятии одной галочки - менять общий обработчик
  $(document).on('click', 'input[type="checkbox"]', function() {
    var items_checked = $(this).parents('.wrap_checkboxes').find('input[type="checkbox"]:checked');
    var items_all     = $(this).parents('.wrap_checkboxes').find('input[type="checkbox"]');
    var all           = $(this).parents('.wrap_checkboxes').find('.check_all, .uncheck_all');
    if (items_checked.length == items_all.length) {
      all.text('Снять выделение').removeClass('check_all').addClass('uncheck_all');
    } else {
      all.text('Выделить всё').removeClass('uncheck_all').addClass('check_all');
    }
  });
}

/**
 * Формирует и отображает модальное окно bootstrap
 * settings- объект с настройками
 */
function makeModal(settings) {
  var modalContainer,
    modalTitleContainer,
    modalBodyContainer,
    modalBodyHtml,
    modalFooterContainer,
    settings = $.extend({
      id                  : '#modal',
      //класс для определения размера (modal-sm, modal-lg)
      modalDialogClass    : '',
      //заголовок в modal-title
      title               : '',
      //объект с текстовыми сообщениями добавляются в modal-body через <br>
      messages            : [],
      //кнопки в modal-footer
      buttons             : [],
      //объект со стандартными событиями модального окна bootstrap
      //[{name:'hidden.bs.modal','func':function},{},...]
      events              : [{name:'hidden.bs.modal',func:function(){$(settings.id).remove()}}],
      //стандартные настройки bootstrap
      backdrop            : true,
      keyboard            : true,
      show                : true
    },settings),
    defButtons = {
      'OK':     {text: 'OK',     handler: function() { $(settings.id).modal('hide'); return false;}, icon: ''},
      'CANCEL': {text: 'Отмена', handler: function() { $(settings.id).modal('hide'); return false;}, attrClass: 'btn-default', icon: ''}
    };

  //Формирует html модального окна
  function constructModalDOM(){
    modalContainer = $(document.createElement('div'))
      .attr('id', settings.id.replace('#',''))
      .attr('tabindex', '-1')
      .attr('role', 'dialog')
      .attr('aria-hidden', 'true')
      .attr('data-backdrop', 'static')
      .attr('data-keyboard', 'false')
      .attr('aria-labelledby', 'modalLabel'+settings.id)
      .addClass("modal fade")
      .append(
        $(document.createElement('div')).addClass('modal-dialog '+settings.modalDialogClass).append(
          $(document.createElement('div')).addClass('modal-content p-b-md')
            .append($(document.createElement('button'))
              .addClass('close')
              .attr('data-dismiss', 'modal')
              .attr('aria-hidden', 'true')
              .html('<span class="glyphicon glyphicon-remove-circle"></span>'))
            .append($(document.createElement('div'))
              .addClass('modal-header p-b-0')
              .append('<h3 class="modal-title ' + (!settings.titleNotCapse ? 'text-uppercase' : '') + ' " id="modalLabel'+settings.id+'"></h3>'))
            .append(
              $(document.createElement('div')).addClass('modal-body'),
              $(document.createElement('div')).addClass('modal-footer p-t-0').hide()
            )
        )
      );
    $('body').append(modalContainer);
    return modalContainer;
  }

  //Формирует html блок с кнопками
  function constructButtonDOM(button) {
    if (typeof(button) != 'object') {
      if (defButtons[button]) {
        button = defButtons[button];
      } else {
        button = {text: button};
      }
    }
    $(document.createElement('div'))
      .attr({
        href: (button.link ? button.link : '#'),
        'class': 'btn '+(button.attrClass ? button.attrClass : 'btn-success btn_w225')
      })
      .html(button.text ? (button.icon ? '<span class="glyphicon '+ button.icon +'"></span> ' : '')+button.text : '')
      .click(button.handler)
      .appendTo(modalFooterContainer);
  }

  modalContainer = $(document).find(settings.id);
  //формируем html модального окна
  if(!modalContainer.length){
    modalContainer = constructModalDOM();
  }
  modalTitleContainer = modalContainer.find('.modal-title').text(settings.title);
  modalBodyContainer = modalContainer.find('.modal-body').html('');
  modalFooterContainer = modalContainer.find('.modal-footer').html('').hide();

  modalBodyHtml = '';
  if (typeof(settings.messages) == 'object') {
    //если messages объект добавляем поочередно все элементы
    $.each(settings.messages, function(key,item){
      modalBodyHtml += item+'<br/>';
    });
  } else {
    modalBodyHtml = settings.messages;
  }
  modalBodyContainer.html(modalBodyHtml);

  //формируем кнопки в modal-footer
  if (typeof(settings.buttons) == 'object' && !$.isEmptyObject(settings.buttons)) {
    $(settings.buttons).each(function(i, button){
      constructButtonDOM(button)
    });
    modalFooterContainer.show();
  }

  //события модального окна
  if (typeof(settings.events) == 'object' && !$.isEmptyObject(settings.events)) {
    $(settings.events).each(function(i, ev) {
      if (typeof(ev.name) != 'undefined' && typeof(ev.func) == 'function') {
        modalContainer.on(ev.name,function(e){
          func = ev.func;
          func.call(e);
        })
      }
    });
  }

  modalContainer.modal(settings);
  //стилизуем элементы формы
  formStyled();

  return false;
}

/**
 * Стилизует checkbox и radio
 */
function inputRadioCheckboxStyled(){
  var inputItems = $(document).find('input:not(.icheck-input)');
  inputItems.each(function(key,item){
    if($(item).parents('.well,.modal,.tab-content,.ui-multiselect-checkboxes').length){
      $(item).icheck({
        checkboxClass: 'icheckbox_square',
        radioClass: 'iradio_square'
      });
    } else {
      $(item).icheck({
        checkboxClass: 'icheckbox_square-white',
        radioClass: 'iradio_square-white'
      });
    }
  })
}

/**
 * Отображаем выбранные файлы в стилизованном input file
 */
function inputFileStyled() {
  $(document).on('change','input[type="file"]',function(){
    var input = $(this),
      inputWrap = input.parent(),
      inputValue = input.val(),
      containerValue = inputWrap.find('.input_file_value'),
      reWin = /.*\\(.*)/,
      reUnix = /.*\/(.*)/,
      id = input.attr('id');
    if (!id) {
      id = 'input-file-' + Math.random().toString().substring(7);
      input.attr('id', id);
    }
    if(!$.isEmptyObject(input[0]['files'])){
      if($(this)[0]['files'].length==0){
        containerValue.text(' – Файл не выбран');
      }
      else {
        if($(this)[0]['files'].length==1){
          inputValue = inputValue.replace(reWin, "$1");
          inputValue = inputValue.replace(reUnix, "$1");
          containerValue.html('&nbsp;<span class="trim-text">&ndash; ' + inputValue + '</span><a href="#" onclick="resetInputFile(\'' + id + '\');return false;" class="icon icon-remove-circle"></a>');
        }
        if($(this)[0]['files'].length>1){
          containerValue.html('&nbsp;&ndash; Число файлов:'+$(this)[0]['files'].length+' <a href="#" onclick="resetInputFile(\'' + id + '\');return false;" class="icon icon-remove-circle"></a>');
        }
      }
    }
  })
}

function resetInputFile(id) {
  var input = $("#" + id),
    inputWrap = input.parent(),
    containerValue = inputWrap.find('.input_file_value');
  input.replaceWith(input.val('').clone(true));
  containerValue.text('');
  containerValue.html('<samll class="help-text">&nbsp;&mdash; не более 5 МБ</samll>');
}

function getPass(len) {
  var pass = '';
  var symbols = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  var l = symbols.length - 1;
  for(var i = 0; i < len; i++) {
    pass += symbols[Math.round(l * Math.random())];
  }
  if ( !/(?!^[0-9]*$)(?!^[a-zA-Z]*$)(?!^[a-z0-9]*$)(?!^[A-Z0-9]*$)^([a-zA-Z0-9]{8,})$/.test(pass) ) {
    return getPass(len);
  }
  return pass;
}


/**
 * @about  Обёртка к AJAX-функции
 * @param  {string} url URL запроса
 * @param  {object} data данные для отправки
 * @param  {object|function} params параметры для стандартной $.ajax, либо callback-функция, вызываемая при успешном завершении (done)
 * @param  {function} fail callback-функция, вызывается при ошибке
 * @param  {function} always callback-функция, выполняющаяся после получения ответа
 * @return {void}
 * @author Kirill Vertiporokh
 */
$.send = function(url, data, params, fail, always) {
  if ($.isFunction(params)) {
    params = {done: params};
  }

  params = params || {};

  fail   = fail   || params['fail']   || function(){};
  always = always || params['always'] || function(){};

  params['url']  = url  || '';
  params['data'] = data || {};

  if (!params['method']) {
    params['method'] = 'POST';
  }

  var failCallback = function(result){
    var alertError = false;
    if (result.responseJSON) {
      if (result.status == 403) {
        Alert(result.responseJSON.error);
      } else {
        if (result.responseJSON.error) {
          $.setError(result.responseJSON.error);
        }
      }
      if (typeof(result.responseJSON.invalid) == 'object') {
        var block, field, fieldErrors, error, $fieldHelp, $fieldFormGroup, $firstErrorHelp;

        var fieldsPositions  = [];
        var minFieldPosition, index = 0;

        for (block in result.responseJSON.invalid) {
          $('.validate-entity-' + block + ' .help-block').html('');
          $('.validate-entity-' + block + ' .form-group').removeClass('has-error');
          for (field in result.responseJSON.invalid[block]) {
            fieldErrors = result.responseJSON.invalid[block][field];
            error = fieldErrors.length ? fieldErrors.join('<br/>') : fieldErrors;
            $fieldHelp = $('.validate-entity-' + block + ' .help-block[validate=' + field + ']');
            $fieldFormGroup = $fieldHelp.closest('.form-group');

            index++;

            fieldsPositions[index] = $fieldFormGroup.offset().top;

            minFieldPosition = fieldsPositions.filter(function (x) { return x !== 0; })
                .reduce(function (a, b) { return Math.min(a, b); }, Infinity);

            if ( ! $firstErrorHelp) {
              $firstErrorHelp = $fieldFormGroup;
            }
            $fieldHelp.html(error);
            $fieldFormGroup.addClass('has-error');
            if (!$fieldHelp.length) {
              alertError = true;
            }
          }
        }
        if ($firstErrorHelp) {
          $firstErrorHelp.scrollTo(minFieldPosition);
        }
        if (alertError) {
          Alert('Указаны некорректные данные');
        }
      }
    }
    fail(result);
  };

  var alwaysCallback = function(result) {
    $('.loading').each(function() {
      $(this)
        .addClass('btn-'    + $(this).attr('loading-class-from'))
        .removeClass('btn-' + $(this).attr('loading-class-to'))
        .removeClass('loading')
        .text($(this).attr('loading-text-from'))
        .attr('loading-class-from', null)
        .attr('loading-class-to',   null)
        .attr('loading-text-from',  null);
    });
    always(result);
  };

  $.clearError();
  $.clearBlockErrors();

  $.ajax(params)
    .done(params['done'] || function(){})
    .fail(failCallback)
    .always(alwaysCallback);
};

var Confirm = function (message, callback, callbackCancel, params) {
  params = params ? params : {};
  var deferred = jQuery.Deferred();
  var hide = function(){$('#modal').modal('hide');return false};
  var title = params.title ?  params.title : '';
  makeModal({
    title           : title,
    modalDialogClass: 'modal-sx modal-confirm',
    messages        : [message],
    buttons         : [
      {text: (params.button_text && params.button_text.ok ? params.button_text.ok : 'ОК'), handler: function(){ hide(); setTimeout(function(){ callback(); deferred.resolve(); }, 400); return false}, attrClass: 'btn-success', icon: ''},
      {text: 'Отмена', handler: function(){callbackCancel ? callbackCancel() : '';hide(); return false}, attrClass: 'btn-default', icon: ''}
    ]
  });
  $('#modal').on('hide.bs.modal', function(){
    deferred.reject();
  });
  return deferred;
};

var Alert = function(message, params) {
  var deferred = jQuery.Deferred();
  var hide = function(){
    $('#modal').modal('hide');
    return false;
  };
  makeModal({
    title           : typeof(params) == 'string' ? params : '',
    modalDialogClass: 'modal-sx modal-alert',
    messages        : [message],
    buttons         : [
      {text: 'ОК', handler: hide, attrClass: 'btn-success', icon: ''}
    ]
  });
  $('#modal').on('hide.bs.modal', function(){
    deferred.resolve();
  });
  return deferred;
};

var Prompt = function(params) {
  var deferred = jQuery.Deferred();
  var hide = function() {
    $('#modal').modal('hide');
  };
  var ok = function() {
    var str = $('#modal #prompt').val();
    hide();
    deferred.resolve(str);
    return false;
  };
  var cancel = function() {
    hide();
    deferred.reject();
    return false;
  };

  makeModal({
    title           : params.title,
    modalDialogClass: 'modal-sx modal-alert',
    messages        : ['<p>' + params.message + '</p><p><input type="input" class="form-control" id="prompt"></p>'],
    buttons         : [
      {text: 'ОК',     handler: ok,     attrClass: 'btn-success', icon: ''},
      {text: 'Отмена', handler: cancel, attrClass: 'btn-default', icon: ''},
    ]
  });

  return deferred;
};

/**
 * Выполнить последний из вызванных callback в течение time микросекунд
 * например полезно использовать при обработке нажатий клавиш для input'ов
 * @author Yury Korobeynikov
 * @param callback
 * @param time
 * @param thread
 */
function callLastInTime(callback, time, thread, n) {
  this.count || (this.count = {});
  time || (time = 1000);
  thread || (thread = '1');
  if (n && this.count[thread] == n) {
    callback();return;
  }
  this.count[thread] || (this.count[thread] = 0);
  if ( ! n) {
    this.count[thread]++;
    var thisCall = this.count[thread];
    setTimeout(function(){
      callLastInTime(callback, time, thread, thisCall);
    }, time);
  }
}

var originalStateForm = [];
var changedStateForm = [];

$('.form-leave-notice').find('input, select, textarea').each(function() {;

  if($(this).attr('type') != 'checkbox') {
      originalStateForm[$(this).attr('name')] = this.value;
  }
  else {
      originalStateForm[$(this).attr('name')] = this.checked;
  }
});

/**
 * Для форм с классом form-leave-notice показывать уведомление, если форму редактировали и решили уйти со страницы
 * 
 */
function handleFormChangeLeaveNotification() {

  $(document).on('change', '.form-leave-notice input, .form-leave-notice textarea', function(){
    $(this).closest('.form-leave-notice').attr('data-form_changed', 'true');
  });

  $(document).on('change', '.form-leave-notice select', function(){
    var buttonSelect = $(this).next('span.ui-selectmenu-button') || null;

    if(buttonSelect.attr('aria-labelledby') != null) {
      $(this).closest('.form-leave-notice').attr('data-form_changed', 'true');
    }
  });

  window.onbeforeunload = function() {
    var myMessage = 'Измененные данные не были сохранены';

    return ($('.form-leave-notice').data('form_changed') ? myMessage : null);
  };
}

/**
 * Функция выполняет сортировку таблицы по умолчанию
 * Используется класс th.[order-asc|order-desc]
 */
$(document).on('mousemove', '#wrapper .container_content', function() {
  var table = $(this).find('form table.sortable-table');
  var sort  = $(table).find('thead th');
  var order = 0;
  var index = 0;

  if($(sort).hasClass('order-asc')) {
    order = 1;
  } else if($(sort).hasClass('order-desc')) {
    order = -1;
  }

  index = $('th').index($(table).find('th.order-asc, th.order-desc'));

  var rows = $(table).find('tbody tr').sort(function(a, b){
    var aName = $(a).find('td:eq(' + index + ')').text();
    var bName = $(b).find('td:eq(' + index + ')').text();
    var aNum = parseFloat(aName);
    var bNum = parseFloat(bName);
    if (isNaN(aNum) || isNaN(bName)) {
      return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0)) * order;
    }
    return ((aNum < bNum) ? -1 : ((aNum > bNum) ? 1 : 0)) * order;
  }).detach();

  rows.each(function(){
    $(table).find('tbody').append($(this));
  });
});

/** Получение данных всех полей формы */
function getDataForm(target) {
  var result = {};

  $(target).find ('input, textarea, select').each(function() {
    result[this.name] = $(this).val();
  });

  return result;
}

/**
 * Функция отправляет данные формы.
 * @author Sedov Stas
 */
$(document).on('submit', 'form.submitAjax, .form.submitAjax', function(event) {
  event.preventDefault ? event.preventDefault() : event.returnValue = false;

  var data = $(this).serializeArray();

  var action = $(this).attr('action') || $(this).data('action');
  var type   = $(this).data('type') || 'json';
  var method = $(this).attr('method') || $(this).data('method')  || 'post';
  var target = $(this).data('target') || (action[0] == '/') ? action.split('/')[1] : action.split('/')[0];

  // Заполняем форму
  $.ajax({
    type      : method,
    dataType  : type,
    url       : action,
    data      : data,
    success   : function (response) {
      if (response.result === 'success') {
        makeModal({
          messages: 'Данные сохранены',
          buttons : [{text: 'OK', handler: function() {
            $('#modal').modal('hide');
            location.assign('/' + target);
          }}]
        });
      } 
    },
    error     : function (response) {
      console.warn('Произошла ошибка.');
    }
  });

  return false;
});


/**
 * Сортировка для таблиц
 */
function handleTableSort() {
  $('.sortable-table thead th').click(function(){
    var order = 'asc';
    if ($(this).hasClass('order-asc') || $(this).hasClass('order-desc')) {
      order = $(this).hasClass('order-asc') ? 'desc' : 'asc';
    }
    $(this).closest('tr').find('th.order-asc, th.order-desc').removeClass('order-asc order-desc');
    $(this).addClass('order-' + order);
    order = order == 'asc' ? 1 : -1;
    var index = $(this).index();
    var $tbody = $(this).closest('table').find('tbody');
    var $rows = $tbody.find('tr').sort(function(a, b){
      var aName = $(a).find('td:eq(' + index + ')').text();
      var bName = $(b).find('td:eq(' + index + ')').text();
      var aNum = parseFloat(aName);
      var bNum = parseFloat(bName);
      if (isNaN(aNum) || isNaN(bName)) {
        return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0)) * order;
      }
      return ((aNum < bNum) ? -1 : ((aNum > bNum) ? 1 : 0)) * order;
    }).detach();
    $rows.each(function(){
      $tbody.append($(this));
    });
  });
}

/**
 * На весь экран
 */
function fullScreen() {
  var element = document.documentElement
  if(element.requestFullScreen) {
    element.requestFullScreen();
  } else if(element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if(element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  }
}

/**
 * Выход из полного экрана
 */
function cancelFullScreen() {
  var element = document.documentElement;
  if (document.cancelFullScreen) {
    document.cancelFullScreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  }
}

var isMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  Mozilla: function() {
    return navigator.userAgent.match(/Firefox/i);
  },
  any: function() {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

