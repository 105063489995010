function statGetStatistic(obj) {

  var directions = [];
  var statuses   = [];
  $('.checked > input[name^=statistic_directions]').each(function() {  directions.push($(this).val());  });
  $('.checked > input[name^=statistic_statuses]'  ).each(function() {  statuses.push(  $(this).val());  });

  if (obj.loading('warning')) {
    return false;
  }

  $.send('/statistic2/get', {
  period_from : $('#statistic_period_from').val(),
  period_to   : $('#statistic_period_to').val(),
  phone       : $('#statistic_phone option[selected=selected]').attr('value'),
  employers   : $('select[name^=statistic_employers]').val(),
  groups      : $('select[name^=statistic_groups]').val(),
  grouping    : $('#statistic_grouping').val(),
  comparing   : $('#statistic_comparing option[selected=selected]').attr('value'),
  statuses    : statuses,
  directions  : directions
}, function (result) {
    // Средние показатели
    var html = [];
    $.each(result.avg, function() {
      var obj = $(this)[0];
      var row = [
        '<b>' + (obj.name !== undefined          ? obj.name          : 'Всего') + '</b>',
        (obj.cnt           ? obj.cnt           :       0),
        (obj.avg_time_talk ? obj.avg_time_talk :       0),
        (obj.avg_time_wait ? obj.avg_time_wait :       0)
      ];
      html.push('<td>' + row.join('</td><td>') + '<td>');
    });

    $('.statistic-avg').html('<tr>' + html.join('</tr><tr>') + '</tr>');
    $('.chart_statistic_grouping').text($('#statistic_grouping > option:selected').text());
    if ($('#statistic_comparing').val()) {
      $('.chart_statistic_comparing').text($('#statistic_comparing > option:selected').text());
      $('.chart_statistic_comparing_text').removeClass('hide');
    } else {
      $('.chart_statistic_comparing').text('');
      $('.chart_statistic_comparing_text').addClass('hide');
    }

    $('.statistic_chart').removeClass('hide');

    function randomHsl() {
      return 'hsla(' + (Math.random() * 360) + ', 100%, 50%, 1)';
    }

    var colours = [];

    var i;

    for(i = 0; i < 100; i++) {
      colours[i] = [randomHsl(), randomHsl(), randomHsl(), randomHsl(), randomHsl()];
    }

    // Непосредственно график
    var labels = [];
    var data   = {};
    var sort   = {};
    var type   = 'bar';
    var legend = false;
    $.each(result.result, function() {
      var obj = $(this)[0];
      if (!(obj.grouping in sort)) {
        sort[obj.grouping] = labels.length;
        if (!isNaN(obj.group) && obj.group.length === 3) {
          obj.group = obj.grouping;
        }

        labels.push(obj.group);
      }
      if (!obj.compare) {
        if (!data[0]) {
          data[0] = {};
        }
        data[0][sort[obj.grouping]] = {cnt: obj.cnt};
      } else {
        legend = true;
        if (!data[obj.comparing]) {
          data[obj.comparing] = {};
        }
        data[obj.comparing][sort[obj.grouping]] = {label: obj.compare, cnt: obj.cnt};
      }
    });

    // Замена необходима, иначе происходит наложение
    $('canvas#statistic-chart').replaceWith('<canvas id="statistic-chart" height="400" width="799"></canvas>');
    $('#legendCanvas').html('');

    var chart = $('canvas#statistic-chart');
    if (!chart) {
      return;
    }
    var ctx = chart.get(0).getContext('2d');

    var chartData;
    // Если данные лишь сгруппированы
    if (type == 'doughnut') {
      chartData = [];
      var i = 0;
      $.each(labels, function (index, value) {
        chartData.push({
          label     : value,
          value     : data[index],
          color     : ChartColors.defaults[i],
          highlight : ChartColors.hover[i]
        });
        i = (i == ChartColors.defaults.length - 1 ? 0 : i + 1);
      });

      $('#legendCanvas').html(new Chart(ctx).Doughnut(chartData, {
        responsive            : true,
        tooltipFontColor      : 'rgb(59, 59, 59)',
        tooltipTitleFontColor : 'rgb(59, 59, 59)',
        tooltipFillColor      : 'rgb(253, 206, 88)',
        tooltipFontFamily     : "'BlissPro-Extrabold', 'Roboto', Arial, sans-serif",
        multiTooltipTemplate  : "<%=datasetLabel %>: <%= value %>",
        legendTemplate        : "<ul class=\"<%=name.toLowerCase()%>-legend legend list-inline\">"+
        "<% for (var i=0; i<segments.length; i++){%>"+
        "<li>"+
        "<div class=\"legend-color\" style=\"background-color:<%=segments[i].fillColor%>\"></div>"+
        "<div class=\"legend-title\"></div><%if(segments[i].label){%><%=segments[i].label%><%}%>"+
        "</li>"+
        "<%}%>"+
        "</ul>"
      }).generateLegend());

      // Для данных с детализацией
    } else {
      chartData = {labels: $.map(labels, function (value) {
          return [value];
        }), datasets: []};
      var i = 0;
      $.each(data, function (index, value) {
        $.each(labels, function (key) {
          if (!value[key]) {
            value[key] = null;
          }
        });
        var label;
        for (var k in value) {
          if (value[k] != undefined  && /^\d*$/.test(value[k].label) && value[k].label.length < 4) {
            if (value[k].label >= 1 && value[k].label < 24) {
              label = value[k].label + ':00';
            } else {
              label = index;
            }
          } else {
            if (value[k] != null) {
              if (value[k].label === undefined) {
                label = '00:00';
              } else if(value[k].label >= 1 || value[k].label < 24) {
                label = value[k].label.pad() + ':00';
              } else {
                label = value[k].label;
              }
            }
          }
        }
        chartData.datasets.push({
          label           : label,
          fillColor       : Chart.defaults.global.colours[i].fillColor,
          strokeColor     : Chart.defaults.global.colours[i].strokeColor,
          highlightFill   : Chart.defaults.global.colours[i].highlightFill,
          highlightStroke : Chart.defaults.global.colours[i].highlightStroke,
          data            : $.map(value, function (value) {
            return [value && value.cnt ? value.cnt : null];
          })
        });

        i = (i == Chart.defaults.global.colours.length - 1 ? 0 : i + 1);
      });

      var html = new Chart(ctx).Bar(chartData, {
        responsive            : true,
        scaleBeginAtZero      : true,
        barStrokeWidth        : 1,
        barShowStroke         : true,
        barValueSpacing       : 1,
        barDatasetSpacing     : 1,
        showScale             : true,
        scaleShowLabels       : true,
        scaleShowGridLines    : true,
        scaleGridLineColor    : "rgba(0,0,0,.07)",
        scaleGridLineWidth    : 1,
        scaleLineWidth        : 1,
        scaleSteps            : 10,
        scaleStartValue       :0,
        scaleStepWidth        :10,
        scaleLabel            : "<%=value%>",
        showTooltips          : true,
        tooltipFontColor      : 'rgb(59, 59, 59)',
        tooltipTitleFontColor : 'rgb(59, 59, 59)',
        tooltipFillColor      : 'rgb(253, 206, 88)',
        tooltipFontFamily     : "'BlissPro-Extrabold', 'Roboto', Arial, sans-serif",
        multiTooltipTemplate  : "<%=datasetLabel %>: <%= value %>",
        tooltipTemplate       : "<%if (label){%><%=label%>: <%}%><%= value %>",
        maintainAspectRatio   : true,
        onAnimationComplete   : function () {
          var sourceCanvas = this.chart.ctx.canvas;
          // the -5 is so that we don't copy the edges of the line
          var copyWidth = this.scale.xScalePaddingLeft - 5;
          // the +5 is so that the bottommost y axis label is not clipped off
          // we could factor this in using measureText if we wanted to be generic
          var copyHeight = this.scale.yScalePaddingLeft - 5;
          var targetCtx = document.getElementById("statistic-chartAxis").getContext("2d");
          targetCtx.canvas.width = copyWidth;
          targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight);
        },
        legendTemplate        : "<ul class=\"<%=name.toLowerCase()%>-legend legend list-inline\">"+
        "<% for (var i=0; i<datasets.length; i++){%>"+
        "<li>"+
        "<div class=\"legend-color\" style=\"background-color:<%=datasets[i].fillColor%>\"></div>"+
        "<div class=\"legend-title\"></div><%if(datasets[i].label){%><%=datasets[i].label%><%}%>"+
        "</li>"+
        "<%}%>"+
        "</ul>"
      });

      if (legend) {
        html = html.generateLegend();
      }

      $('#legendCanvas').html(html);
    }
});
}

function statistic2DetailedGet(obj, type) {
  
  $(obj).loading();

  if (obj.hasClass('black') || $('.statistic_detailed_send').loading('warning')) {
    return false;
  }

  if (!($('#statistic_detailed_duration').val().match(/^\d+$/)) && $('#statistic_detailed_duration').val() !== '' ){
    $('#statistic_detailed_duration').addClass('alert-error');
    return false;
  } else if ($('#statistic_detailed_duration').hasClass('alert-error')){
    $('#statistic_detailed_duration').removeClass('alert-error')
  }

  var directions = [];
  var statuses   = [];
  $('.checked > input[name^=statistic_detailed_directions]').each(function() {  directions.push($(this).val());  });
  $('.checked > input[name^=statistic_detailed_statuses]'  ).each(function() {  statuses.push(  $(this).val());  });

  // Установка активности для выбранной ссылки в блоке "Показывать по"
  if (obj.parent().parent().hasClass('statistic-detailed-limit')) {
    $('ul.statistic-detailed-limit > li > a').removeClass('black').addClass('link_dotted');
    obj.removeClass('link_dotted').addClass('black');
  }
  // Установка активности для выбранной ссылки в блоках "Страницы"
  if (obj.parent().parent().hasClass('statistic-detailed-page')) {
    $('ul.statistic-detailed-page > li > a').removeClass('black').addClass('link_dotted');
    obj.removeClass('link_dotted').addClass('black');
  }

  // README: после объединения детализации и записей разговоров - убрать
  var only_records = $('.container_content').data('records');

  var url = '/statistic2/' + (only_records ? 'records' : 'detailed') + '/get';

  var params = {
    type                 : type,
    period_from          : $('#statistic_detailed_period_from').val(),
    period_to            : $('#statistic_detailed_period_to').val(),
    duration_compare     : $('#statistic_detailed_duration_compare').val(),
    duration             : parseInt($('#statistic_detailed_duration').val()),
    statuses             : statuses,
    directions           : directions,
    phones               : $('select[name^=statistic_detailed_phones]').val(),
    phones_emp           : $('select[name^=statistic_detailed_phones_emp]').val(),
    page                 : $('ul.statistic-detailed-page > li > a.black').data('page'),
    limit                : $('ul.statistic-detailed-limit > li > a.black').data('limit')
  };

  if (type == 'excel') {
    window.open(url + '?' + $.param(params));
    return false;
  }

  $.send(url, params, function (data) {
    // Очередная установка активности для выбранных ссылок в блоках "Показывать по" и "Страницы"
    $('ul.statistic-detailed-limit > li > a').removeClass('black').addClass('link_dotted');
    $('ul.statistic-detailed-limit > li > a[data-limit=' + data.limit + ']').removeClass('link_dotted').addClass('black');

    // Очистка и последующая генерация постраничной навигации
    $('ul.statistic-detailed-page').each(function() {
      $(this).find('li:gt(0)').remove();
    });
    $('ul.statistic-detailed-page').append(statistic2DetailedGeneratePagination(data));

    var html = [], row, obj;

    // Данные для детализации звонков
    if (!only_records) {
      statisticDetailedCallsData = {};
      $.each(data.detailed, function() {
        obj = $(this)[0];
        if (!statisticDetailedCallsData[obj.group_id]) {
          statisticDetailedCallsData[obj.group_id] = [];
        }

        statisticDetailedCallsData[obj.group_id].push(obj);
      });
    }

    var directions = $('.statistic-detailed-table tbody').data('directions');
    var popover = [
      'data-toggle="popover"',
      'data-trigger="hover"',
      'data-placement="left"',
      'data-content="Тест"'
    ];
    // Генерация HTML для результатов
    $.each(data.result, function() {
      obj = $(this)[0];
      row = [
        statisticDetailedFormatDatetime(obj.dt_begin), 
        '<i class="fa ' + ((obj.answered != 'false') ? 'fa-microphone' : 'fa-microphone-slash') + ' m-r-lg" title="' + ((obj.answered != 'false') ? 'Отвечен': 'Неотвечен') + '"></i><span class="icon icon-' + directions[obj.direction_id]['icon'] + '" title="' + directions[obj.direction_id]['title'] + '"></span>',
        obj.srcnum,
        obj.dstnum,
        obj.extnum,
        obj.time_wait,
        obj.time_talk
      ];

      
      if (typeof obj.have_record !== 'undefined' && obj.have_record === 't') {
          row.unshift('<input type="checkbox" class="statistic_detailed_file" value="' + obj.record_file + '">');

          var iconUpload = '<a href="javascript:statistic2DetailedDownloadFile(\'' + obj.record_file + '\');"          class="icon icon-upload m-r-lg tr_download_audio_button" title="Скачать запись"></a>';

          var iconPlay = '<a data-file="' + obj.record_file + '" onclick="statistic2DetailedPlayFile(\'' + obj.record_file + '\', $(this));" class="icon icon-play m-r-lg tr_play_audio_button"         style="cursor:pointer;" title="Воспроизвести"></a>';

          var iconRemove = '<a href="javascript:statistic2DetailedDeleteFile(\'' + obj.record_file + '\');" class="icon icon-remove-circle-black tr_remove_audio_button" title="Удалить"></a>';


          row.push(
            iconUpload + iconPlay + iconRemove
          );
      } else {
        row.unshift('');

        row.push(
          '<div class="col-sm-1">&nbsp;</div>'
        );
      }
     
      html.push('<tr><td>' + row.join('</td><td onclick="statistic2DetailedCalls(' + obj.group_id + ')" style="cursor: pointer;">') + '</td></tr>');
    });

    $('.statistic-detailed-result tbody').html(html.join(''));
    $('.statistic-detailed-result tbody tr').each(function() {
      $(this).find('td:gt(' + (only_records ? '5' : '5') + '):lt(7)').addClass('text-right');
      if ($(this).find('td:first-child input[type=checkbox]').length) {
        $(this).find('td:gt(6):lt(7)').addClass('container-nohover').css('width', (only_records ? 153 : 'auto'));
        $(this).find('td:last-child').addClass('container-hover').attr('colspan', 2).removeAttr('onclick');
      }
      if (!only_records) {
        $(this).popover({
          content   : 'Нажмите для детализации',
          delay     : {show: 600, hide: 50},
          placement : 'top',
          trigger   : 'hover'
        });
      }
    });

    /**
     * README
     * Необходима для динамической генерации элементов
     */
    inputRadioCheckboxStyled();

    $('.statistic-detailed-result').removeClass('hide');
  });
}

/**
 * Детализация - Скачивание файла записи разговора
 */
function statistic2DetailedDownloadFile(file) {
  window.open('/statistic2/records/download?file=' + file);
}

/**
 * Детализация - Генерация постраничной навигации
 */
function statistic2DetailedGeneratePagination(data) {
  var pages_count = Math.ceil(parseInt(data.count) / parseInt(data.limit));
  var pages = [];
  var page  = parseInt(data.page);
  if (page > 1) {
    pages.push('<a onclick="statistic2DetailedGet($(this))" style="cursor: pointer;" class="link_dotted" data-page="' + (page - 1) + '">«</a>');
  }
  pages.push('<a onclick="statistic2DetailedGet($(this))" style="cursor: pointer;" class="' + (page == 1 ? 'black' : 'link_dotted') + '" data-page="1">1</a>');
  if (page > 3) {
    pages.push('<span>...</span>');
  }
  if (pages_count > 1) {
    var page_from = (page < 3 ? 2 : page - 1);
    var page_to   = (page < pages_count ? page + 1 : pages_count);
    for (var i = page_from; i <= page_to; i++) {
      pages.push('<a onclick="statistic2DetailedGet($(this))" style="cursor: pointer;" class="' + (page == i ? 'black' : 'link_dotted') + '" data-page="' + i + '">' + i + '</a>');
    }
  }
  if (page < pages_count - 2) {
    pages.push('<span>...</span>');
  }
  if (page < pages_count - 1) {
    pages.push('<a onclick="statistic2DetailedGet($(this))" style="cursor: pointer;" class="link_dotted" data-page="' + pages_count + '">' + pages_count + '</a>');
  }
  if (page < pages_count) {
    pages.push('<a onclick="statistic2DetailedGet($(this))" style="cursor: pointer;" class="link_dotted" data-page="' + (page + 1) + '">»</a>');
  }
  pages = '<li>' + pages.join('</li><li>') + '</li>';

  return pages;
}

/**
 * Формирует и открывает окно с детализацией звонка
 */
function statistic2DetailedCalls(group_id) {
  if (statisticDetailedCallsData[group_id]) {
    var directions = $('.statistic-detailed-table tbody').data('directions');

    var html = [], obj, row;
    $.each(statisticDetailedCallsData[group_id], function() {
      obj = $(this)[0];
      row = [
        statisticDetailedFormatDatetime(obj.dt_begin),
        '<i class="fa ' + ((obj.answered != 'false') ? 'fa-microphone' : 'fa-microphone-slash') + ' m-r-lg" title="' + ((obj.answered != 'false') ? 'Отвечен': 'Неотвечен') + '"></i><span class="icon icon-' + directions[obj.direction_id]['icon'] + '" title="' + directions[obj.direction_id]['title'] + '"></span>',
        obj.srcnum,
        obj.dstnum,
        obj.event_id,
        obj.event_value,
        obj.duration
      ];

      html.push('<td>' + row.join('</td><td>') + '</td>');
    });

    var modalBody = '<table class="table table-no-bordered m-b-lg">'+
      '<thead><tr>'+
      '<th>Дата звонка<br/><small class="text-muted">Время / Дата</small></th>'+
      '<th>Звонок<br><small class="text-muted">Отвечен / Неотвечен</small></th>' +
      '<th>Вызывающий<br/><small class="text-muted">Внешний / Внутренний</small></th>'+
      '<th>Вызываемый<br/><small class="text-muted">Внешний / Внутренний</small></th>'+
      '<th>Событие<br/><small class="text-muted">блок</small></th>' +
      '<th>Значение<br/><small class="text-muted">что то</small></th>' +
      '<th>Длительность<br/><small class="text-muted">в секундах</small></th>' +
      '</tr></thead>'+
      '<tr>' + html.join('</tr><tr>') + '</tr>' +
      '</table>';
  } else {
    var modalBody = 'Данные детализации отсутствуют';
  }

  makeModal({
    id               : '#jquery_detailed_modal',
    title            : '',
    modalDialogClass : 'w-1000',
    messages         : modalBody,
    buttons          : false
  });
}

// Добавляет ведущий 0 перед целым числом
Number.prototype.pad = function(size) {
  var str = String(this);

  while (str.length < (size || 2)) {
    str = '0' + str;
  }

  return str;
};

/**
 * Детализация - удаление файла записи разговоров
 */
function statistic2DetailedDeleteFile(file, obj) {
  Confirm('Удалить файл?', function () {
    $.send('/statistic2/records/delete', {
      file: file
    }, function () {
      Alert('Файл удален!');
      $('.statistic_detailed_send').click();
    });
  });
}

function statistic2DetailedPlayFile(file, obj) {
  var tr_obj = obj.closest('tr');
  obj = obj.parent().parent();

  $('#jquery_jplayer_tpl').data('file', file);
  var myPlayer = $("#jquery_jplayer");
  myPlayer.on($.jPlayer.event.ended, function () {
    var tr_obj_next = tr_obj.next('tr');
    var tr_play_audio_button = tr_obj_next.find('.tr_play_audio_button');
    if (tr_obj_next.length) {
      var table_tr2 = $("#table_madol_info_audio").find('tr').eq(1);
      table_tr2.find('td').eq(0).html(tr_play_audio_button.parent().parent().find('td:eq(1)').html());
      table_tr2.find('td').eq(1).html(tr_play_audio_button.parent().parent().find('td:eq(3)').html());
      table_tr2.find('td').eq(2).html(tr_play_audio_button.parent().parent().find('td:eq(4)').html());
      $('#jquery_jplayer_tpl').data('file', tr_play_audio_button.data('file'));
      myPlayer.jPlayer("setMedia", {
        // mp3: 'https://vats-records.profintel.ru/cl_service_id/1561544064.308384.mp3'
        mp3: tr_play_audio_button.data('file')
      }).jPlayer("pause", 0);
      tr_obj = tr_obj_next;
    }
  });

  statistic2DetailedShowRecordFormModal({
    params: [obj.find('td:eq(1)').html(), obj.find('td:eq(3)').html(), obj.find('td:eq(4)').html()],
    audio: file
    // audio  : 'https://vats-records.profintel.ru/cl_service_id/1561544064.308384.mp3'
  });
}

/**
 * Детализация - Скачивание архива нескольких записей разговоров
 */
function statistic2DetailedDownloadSeveralFiles() {
  var files = [];
  var numberTo = [];
  var numberFrom = [];

  $('.checked > .statistic_detailed_file').each(function (key, val) {
    files.push($(this).val());
    numberTo.push($(val).parents('tr').find('td:nth-of-type(4)').text());
    numberFrom.push($(val).parents('tr').find('td:nth-of-type(5)').text());
  });

  if (!files.length) {
    Alert('Не выбраны файлы для скачивания');
    return false;
  }

  if (files.length) {
    window.open('/statistic2/records/download/several?files=' + files.join(',') + '&number_to=' + numberTo + '&number_from=' + numberFrom);
  }
}

/**
 * Формирует и открывает модальное окно для прослушивания записи разговора
 * @params: options{     - массив с настройками
*            params:[]  - параметры таблицы с данными записи
*            audio:''   - путь к записи разговора
*          }
 */
function statistic2DetailedShowRecordFormModal(options) {
  var modalBody = '<table class="table table-no-bordered m-b-lg" id="table_madol_info_audio">' +
    '<thead><tr>' +
    '<th>Дата звонка<br/><small class="text-muted">Время / Дата</small></th>' +
    '<th>Вызывающий<br/><small class="text-muted">Внешний / Внутренний</small></th>' +
    '<th>Вызываемый<br/><small class="text-muted">Внешний / Внутренний</small></th>' +
    '</tr></thead>' +
    '<tr>' +
    '<td>' + options.params[0] + '</td>' +
    '<td>' + options.params[1] + '</td>' +
    '<td>' + options.params[2] + '</td>' +
    '</tr>' +
    '</table>' +
    '<div id="jquery_jplayer_modal_body"></div>';
  makeModal({
    id: "#jquery_jplayer_modal",
    title: "",
    modalDialogClass: 'w-600',
    messages: modalBody,
    buttons: false,
    events: [
      {
        name: "show.bs.modal", func: function () {
          $("#jquery_jplayer_tpl > div").appendTo("#jquery_jplayer_modal_body");
        }
      },
      {
        name: "shown.bs.modal", func: function () {
          var myPlayer = $("#jquery_jplayer");
          myPlayer.jPlayer("setMedia", {mp3: options.audio}).jPlayer("play");

          var Progress = $('#jp_container .jp-progress-slider');
          Progress.slider({
            animate: "fast",
            max: 100,
            range: "min",
            step: 0.01,
            value: 0,
            slide: function (event, ui) {
              if (myPlayer.data('jPlayer').status.currentTime == 0) {
                return false;
              }
              myPlayer.jPlayer("playHead", ui.value);
            }
          });
        }
      },
      {
        name: "hide.bs.modal", func: function () {
          var myPlayer = $("#jquery_jplayer");
          myPlayer.unbind($.jPlayer.event.ended);
          $('#jquery_jplayer_modal').unbind('hide.bs.modal');
          $("#jquery_jplayer_modal_body > div").appendTo("#jquery_jplayer_tpl");
          $("#jquery_jplayer").jPlayer("clearMedia");
        }
      }
    ],
  });
}