var errors = 0;

/*
* @Author: Sedov Stas
* @Date:   2018-08-01 10:31:05
* @Last Modified by:   Sedov Stas
*/

/**
 * Функция осуществляет валидацию поля формы модального окна при потери фокуса
 * самого поля или изменении элемента списка.
 *
 * @see validateFields()
 *
 * @author  Sedov Stas
 */
$(document).on('blur change keyup', '.modal-body input, .modal-body select, .modal-body textarea, form input, form select, form textarea, .form input, .form select, .form textarea', function(event) {

  var errors   = validateFields(this);
  var hasError = $(this).parents('.row').find('.form-group').hasClass('has-error');

  // при удалении всех символов скрыть сообщения об ошибках
  if((event.which === 8 || event.which === 46) && $(this).val().length === 0 || errors === 0 || !hasError) {
    removeMessageError(this);
  }
});

/**
 * Функция осуществляет валидацию поля формы модального окна при нажатии
 * на кнопку "Сохранить".
 *
 * @see isModalFormValid()
 *
 * @author  Sedov Stas
 */
$(document).on('mouseup', '.modal-footer div.btn', function(event) {
    var parent = event.currentTarget.offsetParent || '';

    $(parent).find('.form-group:visible').each(function(key, val) {
      if(!$(parent).find('.modal-body .input_file .brand-color-danger')) {
        isModalFormValid($(val).find('input, select, textarea'));
      }
    });
});

/**
 * Функция проверят корректно ли заполнено поле.
 *
 * Для валидации полей формы и модальных окон можно используются следующие классы:
 *
 * - .valid-required (проверка на обязательные поля)
 * - .valid-phone    (проверка соответствия номера телефона формату)
 * - .valid-email    (проверка эл. адреса на наличие символа '@' и '.')
 * - .valid-domain   (проварка соответствия доменному имени или IP-адресу)
 * - .valid-alpha    (проверка на наличие в строке букв, символов '-' и пробела)
 * - .valid-alnum    (проверка на наличие символов класса valid-alpha и цифр)
 * - .valid-digit    (проверка на наличе в строке только цифр)
 * - .valid-word     (проверка на наличие только лат. букв)
 * - .valid-letnum   (проверка на наличие символов класса valid-word и цифр)
 *
 * - data-length="min[,max]" (проверка соответствия кол-ва символов значения диапазону)
 * - data-min="val" (проверка минимального значения)
 * - data-range="min[,max]"  (проверка на минимальное и максимальное значение)
 *
 * @author  Sedov Stas
 * @param  object  field Объект поля
 *
 * @return boolean
 */
function validateFields(field) {
  var methods = [
    checkEmptyFields(field),
    checkEmailFormat(field),
    checkPhoneFormat(field),
    checkDomainFormat(field),
    checkAlphaChars(field),
    checkAlnumChars(field),
    checkDigitChars(field),
    checkWordChars(field),
    checkLengthCount(field),
    checkLetnumChars(field),
    checkMinChars(field),
    checkRangeChars(field),
    checkSafe(field)
  ];

  return methods.reduce(function(prev, curr) {
    return prev + curr;
  });
}

/**
 * Функция проверяет корректно ли заполнена форма модального окна.
 *
 * @author Sedov Stas
 * @param  object  [fields] Объект полей
 *
 * @return boolean
 */
function isModalFormValid(fields) {
  var modal  = null;

  if(fields === undefined) {
    modal = $('.modal-dialog .modal-body');

    $(modal).find('.form-group:first-of-type:not(".hide"), .form-group.show').each(function(key, val) {
      fields = $(val).find('input, select, textarea');

      // Проверяем обязательные поля
      errors = validateFields(fields);
    });
  }
  else {
    errors = validateFields(fields);
  }

  return (errors >= 1) ? false : true;
}

/**
 * Функция проверяет корректно ли заполнена форма.
 * @author Sedov Stas
 *
 * @return boolean
 */
function isFormValid() {
  var form,
      fields = null;

  var errors = 0;

  form = $('.form');
  fields = form.find('input, select');

  // Проверяем обязательные поля
  errors = validateFields($(fields));

  return (errors >= 1) ? false : true;
}

/**
 * Функция проверят поля формы на отсутствие значения.
 * @author Sedov Stas
 * @param  object fields Объект полей формы
 *
 * @return int    Кол-во ошибок
 */
function checkEmptyFields(fields) {
  var errors  = 0;
  var message = 'Поле не должно быть пустым';

  fields = $(fields).filter('.valid-required');

  if($(fields).length >= 1) {
    $(fields).each(function(index, value) {
      if(!$(value).val()) {
        addMessageError($(value), message);
        ++errors;
      }
    });
  }

  return errors;
}

/**
 * Функция проверяет соответствие значения поля буквенным символам, пробелу и '-'
 * @author Sedov Stas
 * @param  object field Объект поля
 *
 * @return int    Кол-во ошибок
 */
function checkAlphaChars(field) {
  var value   = '';
  var message = 'Недопустимые символы в строке';
  var errors  = 0;
  var regexp = /^([\A-Za-zА-ЯёЁа-я\-\s\_]+)$/;

  if($(field).hasClass('valid-alpha') && $(field).val().length > 0) {
    value = $(field).val();

    if(regexp.test(value) === false && value !== undefined) {
      addMessageError($(field), message);
      ++errors;
    }
  }

  return errors;
}

/**
 * Функция проверяет соответствие значения поля буквенно-цифровым символам.
 * @author Sedov Stas
 * @param  object field Объект поля
 *
 * @return int    Кол-во ошибок
 */
function checkAlnumChars(field) {
  var value   = '';
  var message = 'Недопустимые символы в строке';
  var errors  = 0;
  var regexp = /^([\A-Za-zА-ЯёЁа-я0-9\-\s\_]+)$/;

  if($(field).hasClass('valid-alnum') && $(field).val().length > 0) {
    value = $(field).val();

    if(regexp.test(value) === false && value !== undefined) {
      addMessageError($(field), message);
      ++errors;
    }
  }

  return errors;
}

/**
 * Функция проверяет соответствие значения поля "эл. адрес" формату RFC.
 * @author Sedov Stas
 * @param  object field Объект поля "эл. адрес"
 *
 * @return int    Кол-во ошибок
 */
function checkEmailFormat(field) {
  var value   = '';
  var message = 'Эл. почта указана некорректно';
  var errors  = 0;
  var regexp = /\S+@\S+\.\S+/;

  if($(field).hasClass('valid-email') && $(field).val().length > 0) {
    value = $(field).val();

    if(regexp.test(value) === false && value !== undefined) {
      addMessageError($(field), message);
      ++errors;
    }
  }

  return errors;
}

/**
 * Функция проверяет соответствие значения поля цифровым символам.
 * @author Sedov Stas
 * @param  object field Объект поля
 *
 * @return int    Кол-во ошибок
 */
function checkDigitChars(field) {
  var value   = '';
  var message = 'Недопустимые символы в строке';
  var errors  = 0;
  var regexp = /^([0-9]+)$/;

  if($(field).hasClass('valid-digit') && $(field).val().length > 0) {
    value = $(field).val();

    if(regexp.test(value) === false && value !== undefined) {
      addMessageError($(field), message);
      ++errors;
    }
  }

  return errors;
}

/**
 * Функция проверяет соответствие значения поля "номер телефона" формату.
 * @author Sedov Stas
 * @param  object field Объект поля "номер телефона"
 *
 * @return int    Кол-во ошибок
 */
function checkPhoneFormat(field) {
  var value   = '';
  var message = 'Номер указан некорректно';
  var errors  = 0;
  var regexp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

  if($(field).hasClass('valid-phone') && $(field).val().length > 0) {
    value = $(field).val();

    if(regexp.test(value) === false && value !== undefined) {
      addMessageError($(field), message);
      ++errors;
    }
  }

  return errors;
}

/**
 * Функция проверят соответствие значения поля формату DNS(лат./кир.) или IP-адресу
 * @author Sedov Stas
 * @param  object field Объект поля "домен"
 *
 * @return int    Кол-во ошибок
 */
function checkDomainFormat(field) {
  var value   = '';
  var message = 'Домен указан некорректно';
  var errors   = 0;
  var regexp = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|(https?:\/\/)?([\dа-яёa-z-]+)\.([a-zа-яё]{2,6})([\/\w а-яё-]*)*\/?$/i;

  if($(field).hasClass('valid-domain') && $(field).val().length > 0) {
    value = $(field).val();

    if(regexp.test(value) === false && value !== undefined) {
      addMessageError($(field), message);
      ++errors;
    }
  }

  return errors;
}

/**
 * Функция проверят соответствие количества символов поля диапазону
 * @author Sedov Stas
 * @param  object field Объект поля
 *
 * @return int    Кол-во ошибок
 */
function checkLengthCount(field) {
  var value   = '';
  var message = '';
  var errors  = 0;
  var regexpRange  = /^(\d*)\,(\d*)/;
  var numChar = 0;
  var length  = null;

  if($(field).data('length') !== undefined && $(field).val().length > 0) {
    length   = $(field).data('length');
    numChar = $(field).val().length;

    if(regexpRange.test(length) === true) {
      var range = length.match(regexpRange) || null;
      var min = parseInt(range[1]);
      var max = parseInt(range[2]);

      if(numChar < min || numChar > max) {
        addMessageError($(field), 'Кол-во символов должно быть от ' + min + ' до ' + max);
        ++errors;
      }
    }
    else {
      if(numChar < length) {
        addMessageError($(field), 'Кол-во символов не должно быть менее ' + length);
        ++errors;
      }
    }

  }

  return errors;
}

/**
 * Функция проверяет соответствие значения поля только лат. буквенным символам.
 * @author Sedov Stas
 * @param  object field Объект поля
 *
 * @return int    Кол-во ошибок
 */
function checkWordChars(field) {
  var value   = '';
  var message = 'Недопустимые символы в строке';
  var errors  = 0;
  var regexp = /^([\A-Za-z]+)$/;

  if($(field).hasClass('valid-word') && $(field).val().length > 0) {
    value = $(field).val();

    if(regexp.test(value) === false && value !== undefined) {
      addMessageError($(field), message);
      ++errors;
    }
  }

  return errors;
}

/**
 * Функция проверяет соответствие значения поля только буквенно-цифровым символам.
 * @author Sedov Stas
 * @param  object field Объект поля
 *
 * @return int    Кол-во ошибок
 */
function checkLetnumChars(field) {
  var value   = '';
  var message = 'Недопустимые символы в строке';
  var errors  = 0;
  var regexp = /^([\A-Za-z0-9\-\_]+)$/;

  if($(field).hasClass('valid-letnum') && $(field).val().length > 0) {
    value = $(field).val();

    if(regexp.test(value) === false && value !== undefined) {
      addMessageError($(field), message);
      ++errors;
    }
  }

  return errors;
}

/**
 * Функция проверят значение поля минимально допустимому.
 * @author Sedov Stas
 * @param  object field Объект поля
 *
 * @return int    Кол-во ошибок
 */
function checkMinChars(field) {
  var value   = '';
  var message = 'Недопустимые символы в строке';
  var errors  = 0;
  var min     = null;

  if($(field).data('min') !== undefined && $(field).val().length > 0) {
    min   = $(field).data('min');
    value = $(field).val();

    if(value < min) {
      addMessageError($(field), 'Значение не должно быть меньше ' + min);
      ++errors;
    }
  }

  return errors;
}

/**
 * Функция проверят диапазон на минимальное/максимальное значение.
 * @author Sedov Stas
 * @param  object field Объект поля
 *
 * @return int    Кол-во ошибок
 */
function checkRangeChars(field) {
  var value       = '';
  var message     = '';
  var errors      = 0;
  var regexpRange = /^(\d*)\,(\d*)/;
  var numChar     = 0;
  var range       = null;

  if($(field).data('range') !== undefined && $(field).val() > 0) {
    range   = $(field).data('range');
    numChar = parseInt($(field).val());

    if(regexpRange.test(range) === true) {
      var rangeOf = range.match(regexpRange) || null;
      var min     = parseInt(rangeOf[1]);
      var max     = parseInt(rangeOf[2]);

      if((isNaN(max) && !isNaN(min)) && numChar < min) {
        addMessageError($(field), 'Значение не может быть меньше ' + min);
        ++errors;
      }

      if((isNaN(min) && !isNaN(max)) && numChar > max) {
        addMessageError($(field), 'Значение не может быть больше ' + max);
        ++errors;
      }

      if((!isNaN(max) && !isNaN(min)) && numChar < min || numChar > max) {
        addMessageError($(field), 'Значение должно быть от ' + min + ' до ' + max);
        ++errors;
      }
    }

  }

  return errors;
}

function checkSafe(field) {
  var value   = '';
  var message = 'Недопустимые символы в строке';
  var errors  = 0;
  var regexp = /([~^'`]+)|(!=)+|(--)+/;

  if($(field).hasClass('valid-safe') && $(field).val().length > 0) {
    value = $(field).val();

    if(regexp.test(value) === true && value !== undefined) {
      addMessageError($(field), message);
      ++errors;
    }
  }

  return errors;
}

/**
 * Функция выводит сообщение об ошибке.
 * @author Sedov Stas
 * @param object field Объект поля, в котором возникла ошибка
 * @param string type  Тип ошибки {error|warning}
 * @param string text  Текст ошибки
 */
function addMessageError(field, text, type) {
  if(type == undefined) {type = 'error';}
  $(field).parent().addClass('has-' + type).end().parent().find('.help-block').
    html(text);
}

/**
 * Функция удаляет сообщение об ошибке.
 * @author Sedov Stas
 * @param object field Объект поля, в котором была ошибка
 */
function removeMessageError(field) {
  $(field).parent().removeClass('has-error has-warning').parent().removeClass('has-error has-warning').
    end().find('.help-block').html('');
}