function storage() {
  this.clean = function(types){
    Confirm('Вы уверены?', function() {
      $.send('/storage/clean', {types: types}, {done: function(){
        document.location.reload(true);
      }});
    });
    return false;
  }
}
