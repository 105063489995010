var getMasksGlobalPhone = function() {
    return {
      ru: {
        name: 'Россия',
        pattern: /^\+7/g,
        mask: '+0 (000) 000-00-00'
      },
      us: {
        name: 'США',
        pattern: /^\+1/g,
        mask: '+0 (000) 000-0000'
      },
      gb: {
        name: 'Великобритания',
        pattern: /^\+44/g,
        mask: '+00-00-0000-0000'
      },
      ca: {
        name: 'Канада',
        pattern: /^\+1/g,
        mask: '+0 (000) 000-0000'
      },
      by: {
        name: 'Беларусь',
        pattern: /^\+375/g,
        mask: '+000 (00) 000-00-00'
      },
      ua: {
        name: 'Украина',
        pattern: /^\+380/g,
        mask: '+000 (00) 000-00-00'
      },
      me: {
        name: 'Черногория',
        pattern: /^\+382/g,
        mask: '+000-00-000-000'
      },
      ba: {
        name: 'Босния и Герцеговина',
        pattern: /^\+387/g,
        mask: '+000-00-00009'
      },
      md: {
        name: 'Молдова',
        pattern: /^\+373/g,
        mask: '+000-0000-0000'
      },
      ro: {
        name: 'Румыния',
        pattern: /^\+40/g,
        mask: '+00-00-000-0000'
      },
      ge: {
        name: 'Грузия',
        pattern: /^\+995/g,
        mask: '+000 (000) 000-000'
      },
      am: {
        name: 'Армения',
        pattern: /^\+374/g,
        mask: '+000-00-000-000'
      },
      az: {
        name: 'Азербайджан',
        pattern: /^\+994/g,
        mask: '+000-00-000-00-00'
      },
      kg: {
        name: 'Киргизия',
        pattern: /^\+996/g,
        mask: '+000 (000)-000'
      },
      tj: {
        name: 'Таджикистан',
        pattern: /^\+992/g,
        mask: '+000-00-000-0000'
      },
      tm: {
        name: 'Туркменистан',
        pattern: /^\+993/g,
        mask: '+000-0-000-0000'
      },
      uz: {
        name: 'Узбекистан',
        pattern: /^\+398/g,
        mask: '+000-00-000-0000'
      },
      pl: {
        name: 'Польша',
        pattern: /^\+48/g,
        mask: '+00 (000) 000-000'
      },
      lt: {
        name: 'Литва',
        pattern: /^\+370/g,
        mask: '+000 (00) 00-000'
      },
      lv: {
        name: 'Латвия',
        pattern: /^\+371/g,
        mask: '+000-00-000-000'
      },
      ee: {
        name: 'Эстония',
        pattern: /^\+372/g,
        mask: '+000-0009-0000'
      },
      bg: {
        name: 'Болгария',
        pattern: /^\+359/g,
        mask: '+000 (000) 000-000'
      },
      rs: {
        name: 'Сербия',
        pattern: /^\+381/g,
        mask: '+000-00-000-0000'
      },
      al: {
        name: 'Албания',
        pattern: /^\+355/g,
        mask: '+000 (000) 000-000'
      },
      mk: {
        name: 'Македония',
        pattern: /^\+389/g,
        mask: '+000-00-000-000'
      },
      hr: {
        name: 'Хорватия',
        pattern: /^\+385/g,
        mask: '+000-00-000-000'
      },
      si: {
        name: 'Словения',
        pattern: /^\+386/g,
        mask: '+000-00-000-000'
      },
      gr: {
        name: 'Греция',
        pattern: /^\+30/g,
        mask: '+000 (000) 000-0000'
      },
      sk: {
        name: 'Словакия',
        pattern: /^\+421/g,
        mask: '+000 (000) 000-000'
      },
      cz: {
        name: 'Чехия',
        pattern: /^\+420/g,
        mask: '+000 (000) 000-000'
      },
      de: {
        name: 'Германия',
        pattern: /^\+49/g,
        mask: '+00 (0009) 009-0009'
      },
      dk: {
        name: 'Дания',
        pattern: /^\+45/g,
        mask: '+00-00-00-00-00'
      },
      no: {
        name: 'Норвегия',
        pattern: /^\+47/g,
        mask: '+00 (000) 00-000'
      },
      se: {
        name: '',
        pattern: /^\+46/g,
        mask: '+00-00-000-0000'
      },
      fi: {
        name: 'Швеция',
        pattern: /^\+358/g,
        mask: '+000 (000) 00-00'
      },
      hu: {
        name: 'Венгрия',
        pattern: /^\+36/g,
        mask: '+00 (000) 000-000'
      },
      at: {
        name: 'Австрия',
        pattern: /^\+43/g,
        mask: '+00 (000) 000-000'
      },
      nl: {
        name: 'Нидерланды',
        pattern: /^\+31/g,
        mask: '+00-00-000-0000'
      },
      be: {
        name: 'Бельгия',
        pattern: /^\+32/g,
        mask: '+00 (000) 000-000'
      },
      lu: {
        name: 'Люксембург',
        pattern: /^\+352/g,
        mask: '+000 (000) 000-000'
      },
      fr: {
        name: 'Франция',
        pattern: /^\+32/g,
        mask: '+00 (000) 000-000'
      },
      ie: {
        name: 'Ирландия',
        pattern: /^\+353/g,
        mask: '+000 (000) 000-000'
      },
      ch: {
        name: 'Швейцария',
        pattern: /^\+41/g,
        mask: '+00-00-000-0000'
      },
      li: {
        name: 'Лихтенштейн',
        pattern: /^\+423/g,
        mask: '+000 (000) 000-0000'
      },
      it: {
        name: 'Италия',
        pattern: /^\+39/g,
        mask: '+00 (000) 000-000'
      },
      sm: {
        name: 'Сан-Марино',
        pattern: /^\+378/g,
        mask: '+000-0000-000000'
      },
      va: {
        name: 'Ватикан',
        pattern: /^\+3906698/g,
        mask: '+0000000-00000'
      },
      mc: {
        name: 'Монако',
        pattern: /^\+377/g,
        mask: '+000 (000) 000-000'
      },
      ad: {
        name: 'Андорра',
        pattern: /^\+376/g,
        mask: '+000-000-000'
      },
      es: {
        name: 'Испания',
        pattern: /^\+34/g,
        mask: '+00 (000) 000-000'
      },
      pt: {
        name: 'Португалия',
        pattern: /^\+351/g,
        mask: '+000-00-000-0000'
      },
      gi: {
        name: 'Гибралтар',
        pattern: /^\+350/g,
        mask: '+000-000-00000'
      },
      tr: {
        name: 'Турция',
        pattern: /^\+90/g,
        mask: '+00 (000) 000-0000'
      },
      cy: {
        name: 'Кипр',
        pattern: /^\+357/g,
        mask: '+000-00-000-000'
      },
      lb: {
        name: 'Ливан',
        pattern: /^\+961/g,
        mask: '+000-09-000-000'
      },
      jo: {
        name: 'Иордания',
        pattern: /^\+962/g,
        mask: '+000-0-0000-0000'
      },
      ps: {
        name: 'Палестина',
        pattern: /^\+970/g,
        mask: '+000-00-000-0000'
      },
      il: {
        name: 'Израиль',
        pattern: /^\+972/g,
        mask: '+000-00-000-0000'
      },
      sy: {
        name: 'Сирия',
        pattern: /^\+963/g,
        mask: '+000-00-0000-000'
      },
      iq: {
        name: 'Ирак',
        pattern: /^\+964/g,
        mask: '+000 (000) 000-0000'
      },
      kw: {
        name: 'Кувейт',
        pattern: /^\+968/g,
        mask: '+000-0000-0000'
      },
      sa: {
        name: 'Саудовская Аравия',
        pattern: /^\+965/g,
        mask: '+000-0-0009-0009'
      },
      bh: {
        name: 'Бахрейн',
        pattern: /^\+973/g,
        mask: '+000-0000-0000'
      },
      qa: {
        name: 'Катар',
        pattern: /^\+974/g,
        mask: '+000-0000-0000'
      },
      om: {
        name: 'Оман',
        pattern: /^\+968/g,
        mask: '+000-00-000-000'
      },
      ye: {
        name: 'Йемен',
        pattern: /^\+967/g,
        mask: '+000-009-000-000'
      },
      ir: {
        name: 'Иран',
        pattern: /^\+98/g,
        mask: '+00 (000) 000-0000'
      },
      af: {
        name: 'Афганистан',
        pattern: /^\+93/g,
        mask: '+00-00-000-0000'
      },
      pk: {
        name: 'Пакистан',
        pattern: /^\+92/g,
        mask: '+00 (000) 000-0000'
      },
      in: {
        name: 'Индия',
        pattern: /^\+91/g,
        mask: '+00 (0000) 000-000'
      },
      lk: {
        name: 'Шри-Ланка',
        pattern: /^\+94/g,
        mask: '+00-00-000-0000'
      },
      mv: {
        name: 'Мальдивские острова',
        pattern: /^\+960/g,
        mask: '+000-000-0000'
      },
      bd: {
        name: 'Бангладеш',
        pattern: /^\+880/g,
        mask: '+000-00-000-000'
      },
      bt: {
        name: 'Бутан',
        pattern: /^\+975/g,
        mask: '+00-09-000-000'
      },
      mm: {
        name: 'Бирма (Мьянма)',
        pattern: /^\+95/g,
        mask: '+00-009-000-000'
      },
      th: {
        name: 'Таиланд',
        pattern: /^\+66/g,
        mask: '+00-00-000-0009'
      },
      kh: {
        name: 'Камбоджа',
        pattern: /^\+855/g,
        mask: '+000-00-000-000'
      },
      sg: {
        name: 'Сингапур',
        pattern: /^\+65/g,
        mask: '+00-0000-0000'
      },
      la: {
        name: 'Лаос',
        pattern: /^\+856/g,
        mask: '+000-0099-000-000'
      },
      vn: {
        name: 'Вьетнам',
        pattern: /^\+84/g,
        mask: '+00-(000)-0000-000'
      },
      mo: {
        name: 'Макао',
        pattern: /^\+853/g,
        mask: '+000-0000-0000'
      },
      hk: {
        name: 'Гонконг',
        pattern: /^\+852/g,
        mask: '+000-0000-0000'
      },
      tw: {
        name: 'Тайвань',
        pattern: /^\+886/g,
        mask: '+000-0-0000-0000'
      },
      cn: {
        name: 'Китай',
        pattern: /^\+86/g,
        mask: '+00-00-00000-00000'
      },
      mn: {
        name: 'Монголия',
        pattern: /^\+976/g,
        mask: '+000-00-00-0000'
      },
      kp: {
        name: 'КНДР',
        pattern: /^\+850/g,
        mask: '+000-0000-0000000000000'
      },
      kr: {
        name: 'Южная Корея',
        pattern: /^\+82/g,
        mask: '+00-00-000-0000'
      },
      jp: {
        name: 'Япония',
        pattern: /^\+81/g,
        mask: '+00-00-0000-0000'
      },
      ph: {
        name: 'Филиппины',
        pattern: /^\+63/g,
        mask: '+00 (000)-000-0000'
      },
      pw: {
        name: 'Палау',
        pattern: /^\+680/g,
        mask: '+000-000-0000'
      },
      my: {
        name: 'Малайзия',
        pattern: /^\+60/g,
        mask: '+00 (000) 000-000'
      },
      bn: {
        name: 'Бруней-Даруссалам',
        pattern: /^\+673/g,
        mask: '+000-000-0000'
      },
      id: {
        name: 'Индонезия',
        pattern: /^\+62/g,
        mask: '+00 (000) 000-00-000'
      },
      tl: {
        name: 'Восточный Тимор',
        pattern: /^\+670/g,
        mask: '+000-000-00000'
      },
      pg: {
        name: 'Папуа-Новая Гвинея',
        pattern: /^\+675/g,
        mask: '+000 (000) 00-000'
      },
      au: {
        name: 'Австралия',
        pattern: /^\+61/g,
        mask: '+00-0-0000-0000'
      },
      nz: {
        name: 'Новая Зеландия',
        pattern: /^\+64/g,
        mask: '+00 (000) 000-0000'
      },
      mx: {
        name: 'Мексика',
        pattern: /^\+52/g,
        mask: '+00 (000) 000-0000'
      },
      pm: {
        name: 'Сен-Пьер и Микелон',
        pattern: /^\+508/g,
        mask: '+000-00-0000'
      },
      mp: {
        name: 'Сайпан',
        pattern: /^\+1670/g,
        mask: '+0 (000) 000-0000'
      },
      as: {
        name: 'Американское Самоа',
        pattern: /^\+1684/g,
        mask: '+0 (000) 000-0000'
      },
      bm: {
        name: 'Бермудские острова',
        pattern: /^\+1441/g,
        mask: '+0 (000) 000-0000'
      },
      bs: {
        name: 'Багамские Острова',
        pattern: /^\+1242/g,
        mask: '+0 (000) 000-0000'
      },
      cu: {
        name: 'Куба',
        pattern: /^\+53/g,
        mask: '+00-0-000-0000'
      },
      jm: {
        name: 'Ямайка',
        pattern: /^\+1876/g,
        mask: '+0 (000) 000-0000'
      },
      ky: {
        name: 'Каймановы острова',
        pattern: /^\+1345/g,
        mask: '+0 (000) 000-0000'
      },
      do: {
        name: 'Доминиканская Республика',
        pattern: /^\+18[024]9/g,
        mask: '+0 (000) 000-0000'
      },
      ht: {
        name: 'Гаити',
        pattern: /^\+509/g,
        mask: '+000-00-00-0000'
      },
      tc: {
        name: 'Тёркс и Кайкос',
        pattern: /^\+1649/g,
        mask: '+0 (000) 000-0000'
      },
      bz: {
        name: 'Белиз',
        pattern: /^\+501/g,
        mask: '+000-000-0000'
      },
      gt: {
        name: 'Гватемала',
        pattern: /^\+502/g,
        mask: '+000-0-000-0000'
      },
      sv: {
        name: 'Сальвадор',
        pattern: /^\+503/g,
        mask: '+000-00-00-0000'
      },
      hn: {
        name: 'Гондурас',
        pattern: /^\+504/g,
        mask: '+000-0000-0000'
      },
      ni: {
        name: 'Никарагуа',
        pattern: /^\+505/g,
        mask: '+000-0000-0000'
      },
      cr: {
        name: 'Коста-Рика',
        pattern: /^\+506/g,
        mask: '+000-0000-0000'
      },
      pa: {
        name: 'Панама',
        pattern: /^\+507/g,
        mask: '+000-000-0000'
      },
      co: {
        name: 'Колумбия',
        pattern: /^\+57/g,
        mask: '+00 (000) 000-0000'
      },
      ve: {
        name: 'Венесуэла',
        pattern: /^\+58/g,
        mask: '+00 (000) 000-0000'
      },
      vg: {
        name: 'Британские Виргинские острова',
        pattern: /^\+1284/g,
        mask: '+0 (000) 000-0000'
      },
      ai: {
        name: 'Ангилья',
        pattern: /^\+1264/g,
        mask: '+0 (000) 000-0000'
      },
      vi: {
        name: 'Американские Виргинские острова',
        pattern: /^\+1340/g,
        mask: '+0 (000) 000-0000'
      },
      sx: {
        name: 'Синт-Мартен',
        pattern: /^\+1721/g,
        mask: '+0 (000) 000-0000'
      },
      ag: {
        name: 'Антигуа и Барбуда',
        pattern: /^\+1268/g,
        mask: '+0 (000) 000-0000'
      },
      kn: {
        name: 'Сент-Китс и Невис',
        pattern: /^\+1869/g,
        mask: '+0 (000) 000-0000'
      },
      ms: {
        name: 'Монтсеррат',
        pattern: /^\+1664/g,
        mask: '+0 (000) 000-0000'
      },
      gp: {
        name: 'Гваделупа',
        pattern: /^\+590/g,
        mask: '+000 (000) 000-000'
      },
      dm: {
        name: 'Доминика',
        pattern: /^\+1767/g,
        mask: '+0 (000) 000-0000'
      },
      mq: {
        name: 'Мартиника',
        pattern: /^\+596/g,
        mask: '+000 (000) 00-00-00'
      },
      lc: {
        name: 'Сент-Люсия',
        pattern: /^\+1758/g,
        mask: '+0 (000) 000-0000'
      },
      vc: {
        name: 'Сент-Винсент и Гренадины',
        pattern: /^\+1784/g,
        mask: '+0 (000) 000-0000'
      },
      bb: {
        name: 'Барбадос',
        pattern: /^\+1246/g,
        mask: '+0 (000) 000-0000'
      },
      gd: {
        name: 'Гренада',
        pattern: /^\+1473/g,
        mask: '+0 (000) 000-0000'
      },
      tt: {
        name: 'Тринидад и Тобаго',
        pattern: /^\+1868/g,
        mask: '+0 (000) 000-0000'
      },
      an: {
        name: 'Нидерландские Антильские острова',
        pattern: /^\+599/g,
        mask: '+000-0009-0000'
      },
      aw: {
        name: 'Аруба',
        pattern: /^\+297/g,
        mask: '+000-000-0000'
      },
      pe: {
        name: 'Перу',
        pattern: /^\+51/g,
        mask: '+00 (000) 000-000'
      },
      ec: {
        name: 'Эквадор',
        pattern: /^\+593/g,
        mask: '+000-09-000-0000'
      },
      py: {
        name: 'Парагвай',
        pattern: /^\+595/g,
        mask: '+000-000-000'
      },
      uy: {
        name: 'Уругвай',
        pattern: /^\+598/g,
        mask: '+000-0-000-00-00'
      },
      ar: {
        name: 'Аргентина',
        pattern: /^\+54/g,
        mask: '+00 (000) 000-0000'
      },
      cl: {
        name: 'Чили',
        pattern: /^\+56/g,
        mask: '+00-0-0000-0000'
      },
      br: {
        name: 'Бразилия',
        pattern: /^\+55/g,
        mask: '+00-00-0000-0000'
      },
      fk: {
        name: 'Фолклендские острова',
        pattern: /^\+500/g,
        mask: '+000-00000'
      },
      gf: {
        name: 'Гвиана',
        pattern: /^\+594/g,
        mask: '+000-00000-0000'
      },
      sr: {
        name: 'Суринам',
        pattern: /^\+597/g,
        mask: '+000-000-0009'
      },
      gy: {
        name: 'Гайана',
        pattern: /^\+592/g,
        mask: '+000-000-0000'
      },
      fm: {
        name: 'Микронезия',
        pattern: /^\+691/g,
        mask: '+000-000-0000'
      },
      mh: {
        name: 'Маршалловы Острова',
        pattern: /^\+692/g,
        mask: '+000-000-0000'
      },
      nr: {
        name: 'Науру',
        pattern: /^\+674/g,
        mask: '+000-000-0000'
      },
      sb: {
        name: 'Соломоновы Острова',
        pattern: /^\+677/g,
        mask: '+000-000-0000'
      },
      tv: {
        name: 'Тувалу',
        pattern: /^\+688/g,
        mask: '+000-000009'
      },
      vu: {
        name: 'Вануату',
        pattern: /^\+678/g,
        mask: '+000-00-00009'
      },
      fj: {
        name: 'Фиджи',
        pattern: /^\+679/g,
        mask: '+000-00-00000'
      },
      nc: {
        name: 'Новая Каледония',
        pattern: /^\+687/g,
        mask: '+000-00-0000'
      },
      nf: {
        name: 'Норфолк (остров) ',
        pattern: /^\+672/g,
        mask: '+000-000-000'
      },
      io: {
        name: 'Диего-Гарсия',
        pattern: /^\+246/g,
        mask: '+000-000-0000'
      },
      sc: {
        name: 'Сейшелы',
        pattern: /^\+248/g,
        mask: '+000-0-000-000'
      },
      ln: {
        name: 'ОАЭ',
        pattern: /^\+971/g,
        mask: '+000-00-000-0000'
      },
      mu: {
        name: 'Маврикий',
        pattern: /^\+230/g,
        mask: '+000-000-0000'
      },
      re: {
        name: 'Реюньон',
        pattern: /^\+662/g,
        mask: '+000-00000-0000'
      },
      mg: {
        name: 'Мадагаскар',
        pattern: /^\+661/g,
        mask: '+000-00-00-00000'
      },
      km: {
        name: 'Коморы',
        pattern: /^\+269/g,
        mask: '+000-00-00000'
      },
      mt: {
        name: 'Мальта',
        pattern: /^\+356/g,
        mask: '+000-0000-0000'
      },
      tn: {
        name: 'Тунис',
        pattern: /^\+216/g,
        mask: '+000-00-000-000'
      },
      dz: {
        name: 'Алжир',
        pattern: /^\+213/g,
        mask: '+000-00-000-0000'
      },
      ma: {
        name: 'Марокко',
        pattern: /^\+212/g,
        mask: '+000-00-0000-000'
      },
      ly: {
        name: 'Ливия',
        pattern: /^\+218/g,
        mask: '+000-00-000-0009'
      },
      eg: {
        name: 'Египет',
        pattern: /^\+20/g,
        mask: '+00 (000) 000-0000'
      },
      mr: {
        name: 'Мавритания',
        pattern: /^\+222/g,
        mask: '+000-00-00-0000'
      },
      ml: {
        name: 'Мали',
        pattern: /^\+223/g,
        mask: '+000-00-00-0000'
      },
      sn: {
        name: 'Сенегал',
        pattern: /^\+221/g,
        mask: '+000-00-000-0000'
      },
      gm: {
        name: 'Гамбия',
        pattern: /^\+220/g,
        mask: '+000 (000) 00-00'
      },
      gw: {
        name: 'Гвинея-Бисау',
        pattern: /^\+245/g,
        mask: '+000-0-000000'
      },
      gn: {
        name: 'Гвинея',
        pattern: /^\+224/g,
        mask: '+000-00-000-000'
      },
      sl: {
        name: 'Сьерра-Леоне',
        pattern: /^\+232/g,
        mask: '+000-00-000000'
      },
      lr: {
        name: 'Либерия',
        pattern: /^\+231/g,
        mask: '+000-00-000-00'
      },
      ci: {
        name: 'Кот-д’Ивуар',
        pattern: /^\+225/g,
        mask: '+000-00-000-000'
      },
      gh: {
        name: 'Гана',
        pattern: /^\+233/g,
        mask: '+000 (000) 000-000'
      },
      tg: {
        name: 'Того',
        pattern: /^\+228/g,
        mask: '+000-00-000-000'
      },
      bj: {
        name: 'Бенин',
        pattern: /^\+229/g,
        mask: '+000-00-00-0000'
      },
      bf: {
        name: 'Буркина Фасо',
        pattern: /^\+226/g,
        mask: '+000-00-00-0000'
      },
      ng: {
        name: 'Нигерия',
        pattern: /^\+234/g,
        mask: '+000 (000) 000-0000'
      },
      cm: {
        name: 'Камерун',
        pattern: /^\+237/g,
        mask: '+000-0000-0000'
      },
      td: {
        name: 'Чад',
        pattern: /^\+235/g,
        mask: '+000-00-00-00-00'
      },
      cf: {
        name: 'Центральноафриканская Республика',
        pattern: /^\+236/g,
        mask: '+000-00-00-0000'
      },
      sd: {
        name: 'Судан',
        pattern: /^\+250/g,
        mask: '+000-00-000-0000'
      },
      ss: {
        name: 'Южный Судан',
        pattern: /^\+211/g,
        mask: '+000-00-000-0000'
      },
      et: {
        name: 'Эфиопия',
        pattern: /^\+251/g,
        mask: '+000-00-000-000'
      },
      dj: {
        name: 'Джибути',
        pattern: /^\+253/g,
        mask: '+000-00-00-00-00'
      },
      ke: {
        name: 'Кения',
        pattern: /^\+254/g,
        mask: '+000-000-000000'
      },
      ug: {
        name: 'Уганда',
        pattern: /^\+256/g,
        mask: '+000 (000) 000-000'
      },
      rw: {
        name: 'Руанда',
        pattern: /^\+250/g,
        mask: '+000 (000) 000-000'
      },
      bi: {
        name: 'Бурунди',
        pattern: /^\+257/g,
        mask: '+000-00-00-0000'
      },
      tz: {
        name: 'Танзания',
        pattern: /^\+255/g,
        mask: '+000-00-000-0000'
      },
      cd: {
        name: 'Конго (Киншаса)',
        pattern: /^\+243/g,
        mask: '+000 (000) 000-000'
      },
      ga: {
        name: 'Габон',
        pattern: /^\+241/g,
        mask: '+000-0-00-00-00'
      },
      gq: {
        name: 'Экваториальная Гвинея',
        pattern: /^\+240/g,
        mask: '+000-00-000-0000'
      },
      st: {
        name: 'Сан-Томе и Принсипи',
        pattern: /^\+239/g,
        mask: '+000-00-00000'
      },
      cv: {
        name: 'Кабо-Верде',
        pattern: /^\+238/g,
        mask: '+000 (00) 00-00'
      },
      ao: {
        name: 'Ангола',
        pattern: /^\+244/g,
        mask: '+000 (000) 000-000'
      },
      zm: {
        name: 'Замбия',
        pattern: /^\+260/g,
        mask: '+000-00-000-0000'
      },
      mw: {
        name: 'Малави',
        pattern: /^\+265/g,
        mask: '+000-0-0009-0009'
      },
      mz: {
        name: 'Мозамбик',
        pattern: /^\+258/g,
        mask: '+000-00-000-000'
      },
      zw: {
        name: 'Зимбабве',
        pattern: /^\+263/g,
        mask: '+000-0-000000'
      },
      bw: {
        name: 'Ботсвана',
        pattern: /^\+267/g,
        mask: '+000-00-000-000'
      },
      na: {
        name: 'Намибия',
        pattern: /^\+264/g,
        mask: '+000-00-000-0000'
      },
      za: {
        name: 'ЮАР',
        pattern: /^\+27/g,
        mask: '+00-00-000-0000'
      },
      sz: {
        name: 'Свазиленд',
        pattern: /^\+268/g,
        mask: '+000-00-00-0000'
      },
      ls: {
        name: 'Лесото',
        pattern: /^\+266/g,
        mask: '+000-0-000-0000'
      },
      so: {
        name: 'Сомали',
        pattern: /^\+252/g,
        mask: '+000-00-000-000'
      },
      ac: {
        name: 'Остров Вознесения',
        pattern: /^\+247/g,
        mask: '+000-0000'
      },
      sh: {
        name: 'Остров Святой Елены, Тристан-да-Кунья',
        pattern: /^\+290/g,
        mask: '+000-0000'
      },
      aq: {
        name: 'Австралийская антарктическая база',
        pattern: /^\+672/g,
        mask: '+000-000-000'
      },
      fo: {
        name: 'Фарерские острова',
        pattern: /^\+298/g,
        mask: '+000-000-000'
      },
      is: {
        name: 'Исландия',
        pattern: /^\+354/g,
        mask: '+000-000-0000'
      }
    };
  };