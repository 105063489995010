var
  cookieHelp = getCookie('HELP_TIPS') || 'off',
  ChartColors = {
    defaults : ['rgba(38, 120, 176, 1)', 'rgba(24, 186, 154, 1)', 'rgba(252, 206, 88, 1)', 'rgba( 2, 170, 214, 1)', 'rgba(236, 68, 76, 1)'],
    hover    : ['rgba(48, 130, 186, 1)', 'rgba(34, 196, 164, 1)', 'rgba(255, 216, 98, 1)', 'rgba(12, 180, 224, 1)', 'rgba(246, 78, 86, 1)']
  },
  audioFilesBlock       = new audioFiles(),
  changeHistoryBlock    = new changeHistory(),
  employersBlock        = new employers(),
  faxBlock              = new fax(),
  scenariosBlock        = new scenarios(),
  voiceSynthesizerBlock = new voiceSynthesizer(),
  storageBlock          = new storage(),
  integrationsBlock     = new integrations();

$(document).ready(function() {
  toggleSidebar();
  toggleBoxEasing();
  handleSwitchForwardings();
  handleChangeNumber();
  handleGeneratePass();
  handleScenario();
  handleFileUploadSelect();
  handleGroups();
  blackListHandler();
  generateApiKey();
  handleEmployersSection();
  handleRememberedAlerts();
  handlePasswordFieldsEvents();
  handleAddEmployerChangeName();
  voiceSynthesizerBlock.handler();
  faxBlock.handler();
  audioFilesBlock.handler();

  if (cookieHelp === 'on') {
    $('.box_easing .box').trigger('click');
  }
  $('.popupover').popover({
    'placement': function(){
      return 'auto';
    }
  });
  checkStatus();
});

/**
* Анимация основного меню
*/
function toggleSidebar(){
  var wrapper = $('#wrapper'),
      menu = $('#menu'),
      submenu = $('#submenu'),
      sidebar = $('#sidebar'),
      menuItems = menu.find('.menu_item').parent(),
      iconMenuRight = menu.find('.icon-menu-right'),
      activeMenuItem = menuItems.filter('.menu_item_active'),
      submenuContents = submenu.find('.submenu_content'),
      activeSubmenuItem = submenu.find('.submenu_item_active'),
      //ширина свернутого меню
      menuSmWidth = '63px',
      //ширина развернутого меню
      menuLgWidth = '295px',
      submenuOpenTimer;
  //разворачиваем основное меню
  var switchMenu = function(name, width){
    //timer для отображения, после задержки мышки в элементе
    submenuOpenTimer = setTimeout(function() {
      //меняем ширину меню
      menu.animate({width:width},200);
      $('.popover_help').css({'z-index': 4});
      switchSubmenu(name,width);
    }, 50);
  };
  //отображает подменю раздела name
  var switchSubmenu = function(name, width){
    var menuItem = menuItems.find('a.menu_item').filter('[data-name=' + name + ']'),
        submenuItem = submenuContents.filter('.submenu_content_' + name);
    //если ширина свернутого меню sidebar добавляем класс свернутого меню
    if(width==menuSmWidth){
      sidebar.addClass('sidebar_submenu_active').show();
    } else {
      sidebar.removeClass('sidebar_submenu_active').hide();
    }
    submenuItem.addClass('submenu_content_active');
    submenuContents.not(submenuItem).removeClass('submenu_content_active');
    menuItem.addClass('menu_item_hover');
    menuItems.find('a.menu_item').not(menuItem).removeClass('menu_item_hover');
    if(submenuItem.length){
      submenu.animate({left:width,opacity:1},200);
    } else {
      //submenu.animate({left:0},200);
    }
  };
  // при наведении на стрелку или кнопку выхода
  $('#menu .icon-menu-right, #menu .btn-logout').on('click', function(){
    //разворачиваем меню и подменю активного раздела
    switchMenu(activeMenuItem.data('name'),menuLgWidth);
  });
  //при наведении на пункт меню
  menuItems.on('click', function(){
    //разворачиваем меню и подменю текщего раздела
    switchMenu($(this).find('a.menu_item').data('name'),menuLgWidth);
  });
  menuItems.on('mouseleave', function(){
    clearTimeout(submenuOpenTimer);
  });
  //при покидании элемента sidebar возвращаем все на место
  sidebar.on('mouseleave', function(){
    menuItems.find('a.menu_item').removeClass('menu_item_hover');
    submenu.animate({left:0},300);
    $('.popover_help').css({'z-index': 1040});

    /*if(activeSubmenuItem.length){
      switchMenu(activeMenuItem.data('name'),menuSmWidth);
    } else{
      menuItems.find('a.menu_item').removeClass('menu_item_hover');
      submenu.animate({left:0,opacity:0},200);
    }*/
  });
}

/**
* Анимация блока вкл/выкл
*/
function toggleBoxEasing(){
  //анимация включения настройки
  var onBoxEasing = function(boxItem, duration){
    var box = boxItem.find('.box'),
        label = boxItem.find('.label'),
        //функция funcSuccess выполняется после анимации
        funcSuccess = window[boxItem.data('func')];
    label.animate({
      //максимальная позиция ползунка = ширина boxItem - ширина label
      left: boxItem.outerWidth(true) - label.outerWidth(true)
    }, ($.isNumeric(duration) ? duration : 300),'easeInCirc', function(){
      box.text('вкл');
      boxItem.removeClass('off').addClass('on');
      //запускаем функцию, указанную в параметре data-func
      if ($.isFunction(funcSuccess)){
        return funcSuccess('on');
      }
    });
  };
  //анимация выключения настройки
  var offBoxEasing = function(boxItem, duration){
    var box = boxItem.find('.box'),
        label = boxItem.find('.label'),
        funcSuccess = window[boxItem.data('func')];
    label.animate({
      left: 0
    }, ($.isNumeric(duration) ? duration : 300),'easeInCirc', function(){
      box.text('выкл');
      boxItem.removeClass('on').addClass('off');
      //запускаем функцию, указанную в параметре data-func
      if ($.isFunction(funcSuccess)){
        return funcSuccess('off');
      }
    });
  };
  //анимация при клике на блок box_easing
  $(document).on('click', '.box_easing .box', function(e){
    var boxItem = $(this).parent();
    if(boxItem.hasClass('on')){
      offBoxEasing(boxItem);
    } else {
      onBoxEasing(boxItem);
    }
  });
  $(document).on('click', 'a.showHelp', function(e){
    $('.box_easing .box').trigger('click');
  });
  $(document).on('click', '.box_easing > div.label', function(e){
    $('.box_easing .box').trigger('click');
  });
  //анимация при нажатии на box_easing в фокусе
  $(document).on('focus', '.box_easing', function(){
    var boxItem = $(this);
     boxItem.on('keyup',function(event){
      //если enter, то переключаем
      if(event.keyCode == 13 && boxItem.hasClass('on')){
        offBoxEasing(boxItem);
      }
      if(event.keyCode == 13 && boxItem.hasClass('off')){
        onBoxEasing(boxItem);
      }
      //если стрелка вправо то включаем
      if(event.keyCode == 39){
        onBoxEasing(boxItem);
      }
      //если стрелка влево то выключаем
      if(event.keyCode == 37){
        offBoxEasing(boxItem);
      }
    });
  });
  //анимация при перетаскивании .label в блоке box_easing
  $(".box_easing .label").draggable({
    containment:'parent',
    stop: function(event, ui){
      var boxItem = $(this).parent(),
          label = boxItem.find('.label'),
          //максимальная позиция ползунка = ширина boxItem - ширина label
          posMax = boxItem.outerWidth(true) - label.outerWidth(true);
      //перетаскиваем ползунок в начало или в конец в зависимости от его положения при остановке
      if(boxItem.hasClass('on') && ui.position.left == 0){
        offBoxEasing(boxItem,0);
      } else if(boxItem.hasClass('on') && ui.position.left < posMax){
        offBoxEasing(boxItem);
      }
      if(boxItem.hasClass('off') && ui.position.left == posMax){
        onBoxEasing(boxItem,0);
      } else if(boxItem.hasClass('off') && ui.position.left > 0){
        onBoxEasing(boxItem);
      }
    }
  });
}

/**
 * Переключатель подсказок
 */
function toggleHelp(state) {
  var cookieHelp = $.cookie('helpState');
  if ( ! state) {
    state = cookieHelp || 'on';
    $('.box_easing').removeClass('on off');
    $('.box_easing').addClass(state);
    $('.box_easing .box').text(state == 'on' ? 'вкл' : 'выкл');
  }
  if (state == 'off') {
    $('.popover_help').popover('destroy');
  }
  else {
    showHelpPopover();
  }
  $.cookie('helpState', state, {expires:30});
}

/**
* Отображает подсказки на странице
*/
function showHelpPopover(){
  var template = $(document.createElement('div'))
      .attr('role', 'tooltip')
      .addClass("popover popover_help")
      .append($(document.createElement('div')).addClass('arrow'))
      .append($(document.createElement('button'))
        .addClass('close')
        .attr('aria-label', 'Close')
        .append('<span class="glyphicon glyphicon-remove-circle small"></span>')
        .click(function(){
          $(this).parent().popover('hide');
        })
      )
      .append('<div class="popover-content"></div>');

  setTimeout(function(){
    $('.help-popupover[data-content!=""]').each(function(){
      var $tpl = template.clone(true);
      if ($(this).data('class')) {
        $tpl.addClass($(this).data('class'));
      }
      $(this).popover({
        'template'  : $tpl,
        'html'      : true,
        'placement' : ($(this).data('placement') || 'right')
      }).popover('show');
    });
  }, 100);

  $('.popupover').popover({
    'placement': function(){
      return 'auto';
    }
  });
}

/**
* Считает к-во и сумму выбранных тел. номеров
* в форме поиска при покупке
*/
function updateCheckPhones(obj){
  var parent = $(obj).parents('.search_phones_result'),
      phoneItems = parent.find('input[type="checkbox"]:checked'),
      countContainer = parent.find('.count'),
      sumContainer = parent.find('.sum'),
      count = 0,
      sum = 0;
  phoneItems.each(function(key,item){
    count += 1;
    sum = sum+($(item).data('cost')-0);
  });
  countContainer.text(count);
  sumContainer.text(sum);
}

/**
 * Обработать селект изменения переадресации
 */
function handleSwitchForwardings() {
  $(document).on('change', '.switch-forwardings', function(){
    $('.forwarding-no-answer-time')[$.inArray(parseInt($(this).val()), [3,5]) != -1 ? 'removeClass' : 'addClass']('hide');
    $('.switch-forwardings-properties')[$(this).val() == 1 ? 'addClass' : 'removeClass']('hide');
    // $('.type-second-line-signals')[+$(this).val() !== 6 ? 'addClass' : 'removeClass']('hide');

    switchForwardingAction($('.switch-forwardings-actions').get(0));

    // if($(this).find('option:selected').val() === '6') $('.switch-forwardings-properties').hide();
  });
  $(document).on('change', '.switch-forwardings-actions', function(){
    switchForwardingAction(this);
  });
  $('.switch-forwardings').trigger('change');
  $('.switch-forwardings-actions').trigger('change');
}

/**
 * Переключение действия по переадресации
 */
function switchForwardingAction(self) {
  var names = {
    3: 'Сотрудник',
    4: 'Группа вызова',
    5: 'Автосекретарь'
  };
  var val = $(self).val();
  if (names[val]) {
    $('.forwarding-option-input').addClass('hide');
    $('.forwarding-option')[$('#forwarding_id').val() == 1 ? 'addClass' : 'removeClass']('hide');
    $('.forwarding-option-'+val).removeClass('hide');
    $('.forwarding-option-name').text(names[val]);
  }
  else {
    $('.forwarding-option').addClass('hide');
  }
}

function handleChangeNumber() {
  $(document).on('keyup change', '.entity-number', function(event){
    if ($.inArray(event.keyCode, [16,35,36,37,38,39,40]) != -1) {
      return;
    }
    var self = this;
    var value = event.target.value.toString();
    setTimeout(function(){
      changeEntityNumber(self, value);
    }, 0);
  });

  changeEntityNumber();
  changeEntityQueue();
}

function handleGeneratePass() {
  $(document).on('click', '.gen-pass', function(){
    var target = $(this).attr('target');
    if (target) {
      var $target;
      var closestBlock = $(this).attr('closest-block');
      if (closestBlock) {
        $target = $(this).closest(closestBlock).find(target);
      }
      else {
        $target = $(target);
      }
      $target.val(getPass(8));
    }
    return false;
  });
}

/**
 * Обработка алертов для показа только один раз каждому пользователю
 */
function handleRememberedAlerts() {
  $('.alert-remembered').each(function(){
    var id = $(this).data('id');
    if ( ! id) {
      return;
    }
    if ($.cookie('alert_remembered_' + id)) {
      $(this).hide();
    }
    else {
      $(this).css('visibility', 'visible');
      $(this).find('.close').click(function(){
        $.cookie('alert_remembered_' + id, 'true', {expires:7});
      });
    }
  });
}

function changeEntityNumber(self, number) {
  self = self ? self : $('.entity-number').get(0);
  if(!self) return false;
  number = number ? number : $(self).val();
  var prefix;
  var numberText;

  // Меняем номер в очереди
  changeEntityQueue(number);

  var login = $(self).attr('data-login');
  var numIinput = $(self).find('input').val() || '';

  if(typeof number === "number" || number.length === 0) {
    prefix = $(self).attr('data-prefix');
  } else {
    prefix = $(self).find('select').val() || $(self).attr('data-prefix');
  }

  // Если select содержит префикс
  if($(self).find('select').val() !== undefined) {
    numberText = prefix + numIinput;
  } else {
    numberText = prefix + number.substr(1, 4);
  }

  $(self).val(numberText);
  var format = $('.requisits-table').attr('data-format');
  number = '000' + numberText;
  login = login + prefix + number.substring(number.length - 3);
  $('.sip-user-id').text(numberText);
  $('#collapse5 .sip-user-id').text(format == 'asterisk' ? login : numberText);
  $('.entity-terminal-login').val(login);
  var i = 1;
  $('.terminals-login:visible input').each(function(){
    $(this).val(login + i.toString());
    i++;
  });
}

/**
 * Функция изменяет номер в очереди.
 * @param  {string} number Внутренний номер группы
 */
function changeEntityQueue(number) {
  var target = $(document).find('.entity-queue');
  var parent = $(document).find('.entity-number') || null;
  var prefix = parseInt($(target).attr('data-prefix'));
  var numberText = null;

  if(number !== undefined) {
    numberText = (number.length > 1 ? number.substring(1) : number);
  }
  else if($(parent).length > 0) {
      numberText = $(parent).val().substring(1);
  }

  $(target).val(prefix + numberText);
}

/**
 * Показывать иконку показа пароля в читаемом виде для инпутов с классом switch-show-pass
 */
function handlePasswordFieldsEvents() {
  handlePasswordFields();
  $(document).on('click', '.show-pass-icon', function(){
    var input = $(this).data('input');
    $(input).attr('type', $(input).attr('type') == 'password' ? 'text' : 'password');
  });
}

function handlePasswordFields() {
  $('.switch-show-pass:visible').each(function(){
    var rect = $(this).pos();
    var pos = $(this).position();
    if ($(this).data('input')) {
      return;
    }
    var hover = document.createElement("div");
    hover.setAttribute('class', 'show-pass-icon glyphicon-eye-open');
    hover.setAttribute('style', 'left:' + (pos.left + rect.width - 25) + 'px;top:' + pos.top + 'px;line-height:' + rect.height + 'px');
    $(hover).data('input', this);
    $(this).after(hover);
  });
}

/**
 * Клик по кнопке "Нашли ошибку"
 */
$('#report-error-btn').click(function() {
  makeModal({
    id   : '#modal-error',
    title: 'Сообщение об ошибке',
    messages: '<form><div class="form-group m-b-0"><textarea name="text" id="error-text" placeholder="Текст сообщения" class="form-control valid-required"></textarea><span class="help-block" validate="text"></span></div></form>',
    buttons: [
      {
        'text'      : 'Отправить',
        'attrClass' : 'btn-success',
        'handler'   : function() {
          var text = $('textarea[name="text"]').val();

          if(!isModalFormValid()) return false;

          $.send('/report_bug', {text: text}, function() {
            Confirm('Спасибо! Информация отправлена.', function(){});
            $('#modal-error').modal('hide');
          });
        }
      },
      {
        'text'      : 'Отмена',
        'attrClass' : 'btn-danger',
        'handler'   : function() {
          $('#modal-error').modal('hide');
        }
      }
    ]
  });
});

/**
 * Проверка статуса услуги и уведомление, если отключена за неуплату
 * @author Yury Lugovoy
 */
function checkStatus() {
  if ( ! location.hostname.match(/^vo\./)) {
    return;
  }
  $.send('/service_status', {}, function(result) {
    if (result.status_id == '2') {
      if (!$('#no-pay-modal').length) {
        makeModal({
          id: '#no-pay-modal',
          messages: 'Услуга в статусе "Отключена за неуплату". Вам необходимо погасить задолженность по счетам.'
        });
        setInterval(function() {
          if (!$('.no-pay-background').length) {
            $('body').append('<div class="no-pay-background"></div>');
          }
        }, 100);
      }
    }
  });
}

$(document).on('click', '.ui-selectmenu-button', function(){
  var item = $(this).find('.ui-menu-item ');
  var target = $(this);
  var list = $(document).find('.ui-menu');

  $($(list).find('li')).each(function(key, val) {

   //  если в списке выбран пункт "Загрузка..."
   if(~$(val).text().indexOf('Загрузить')) {
      if($(val).find('i').length == 0) {
        $(val).prepend('<i class="icon icon-download"></i>');
      }

      // Скрывает input[':file']
      $(document).on('click', val, function() {
        $(target).parents('.form-group').find('input[type="file"]').css({
          position: 'absolute',
          left:'-999ppx'
        });
      });
   }
  });

});

/** Функция записывает состояние в cookie при клике на ссылку */
$(document).on('click', '.toggle-tips a.link_dotted', function(event){
  $(this).parents('.toggle-tips').find('.box_easing .box').trigger('click');
});

/** Функция записывает состояние в cookie */
$(document).on('click', '.toggle-tips', function(event) {
  var parent = $(this).find('.box_easing');
  if($(parent).hasClass('on')) {
    setCookie('HELP_TIPS', 'off', {
      expires: 60 * 60 * 24 * 30,
      path: '/',
      domain: 'profintel.ru',
      secure: true
    });
  } else if($(parent).hasClass('off')) {
    setCookie('HELP_TIPS', 'on', {
      expires: 60 * 60 * 24 * 30,
      domain: 'profintel.ru',
      path: '/',
      secure: true
    });
  }
});

(function() {
  var target = $(document).find('.toggle-tips .box_easing .box');

  if(getCookie('HELP_TIPS') === 'on') {
    //onBoxEasing(target);
  } else if(getCookie('HELP_TIPS') === 'off') {
    //offBoxEasing(target);
  }
}());

/**
 * Возвращает cookie с именем name, если есть, если нет, то все
 *
 * @author Sedov Stas
 * @param  {string} name Имя
 * @return {string}
 */
function getCookie(name) {
  var matches = '';

  if(name !== undefined) {
    matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
  }

  return matches ? decodeURIComponent(matches[1]) : document.cookie;
}

/**
 * Функция устанавливает cookie
 *
 * @author Sedov Stas
 * @param {string} name    Имя
 * @param {string} value   Значение
 * @param {object} options Дополнительные свойства для установки cookie
 */
function setCookie(name, value, options) {
  options = options || {};

  var expires = options.expires;

  if (typeof expires == "number" && expires) {
    var date = new Date();
    date.setTime(date.getTime() + expires * 1000);
    expires = options.expires = date;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  var updatedCookie = name + "=" + value;

  for (var propName in options) {
    updatedCookie += "; " + propName;
    var propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += "=" + propValue;
    }
  }

  document.cookie = updatedCookie;
}

/**
 * Функция удаляет cookie
 *
 * @author Sedov Stas
 * @param  {string} name Имя
 */
function deleteCookie(name) {
  setCookie(name, "", {
    expires: -1
  });
}

/** Функция вставляет название субъекта в место назначения data-target="*" */
$('.know-region').each(function(key, val) {
  var target = $(val).data('target') || '';
  var phone = $(val).text() || '';
  phone = phone.replace(/[^\+0-9]/g, '').replace('+8', '+7');

  $(getMasksGlobalPhone()).each(function(key, idx){
    $.each(idx, function(idx, elem) {
      if(phone.match(elem.pattern)) {
        var str = $(val).parent().find(target);

        $(str).prepend(elem.name + ', ');
        str.text(str.text().replace(/,\s$/g, ''));
      }
    });
  });
});

/**
 * Функция форматирует номер телефона
 *
 * @author Sedov Stas
 *
 * @param  {[string]} phone Номер телефона
 * @return {[string]}
 */
function formatPhone(phone) {
  var number = phone.replace(/[^\d]/g, '') || phone;
  var format = 'city';

  switch(number[0]) {
    case '7':
      number = number.replace(/^7/, '');
      format = 'city';
    break;
    case '8':
      number = number.replace(/^8/, '');
      format = 'mobile';
    break;
  }

  if (number[0] === '9' || number[0] === '8') {
    //format = 'mobile';
  }

  if (number.length == 10) {
    switch(format) {
      case 'city':
        number = number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "+7 ($1) $2-$3-$4");
      break;
      case 'mobile':
        //number = number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "8 $1 $2-$3-$4");
      break;
    }
  } else if(number.length >= 10) {
    number = number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "+7 ($1) $2-$3-$4");
  }

  return number;
}

// Функция показывает tooltip с информацией по клику на .info-block
$(document).tooltip({
  placement: 'right',
  selector: '.info-block',
  trigger: 'click',
  template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div><i class="fa fa-close"></i></div>'
});


// Функция скрывает tooltip с информацией по клику на кнопку закрыть
$(document).on('click', '.form-group .tooltip .fa-close', function() {
  $(this).parents('.tooltip').tooltip('destroy');
});

// Функция изменяет заголовок на текстовое поле
$(document).on('click', '.container_content .edit-value a.edit', function(event) {
  event.preventDefault();

  var refName = $(this).data('target');
  var target  = $(this).parent().find('#' + refName);

  editText(target);
});

// Функция устанавливает текст из текстового поля в заголовок
$(document).on('click', 'body .container_content .form.well, .edit-value a.ok', function(event) {
  event.preventDefault();

  var refName = $(this).parents('body').find('.edit-value a').data('target');
  var target  = $(this).parents('body').find('#' + refName);
  var name = $(target).find('input').val() || '';

  if(name == '') {
    addMessageError($(target).find('input'), 'Поле не должно быть пустым');
    return false;
  } else {
    removeMessageError($(target).find('input'));
  }

  setText(target, name);
});

function editText(target) {
  $(target).find('span:first-of-type').hide().end().find('input').removeClass('hide');
  $(target).parent().find('a i').removeClass('icon-pencil').addClass('icon-ok').parent().addClass('ok').removeClass('edit').find('span:first-of-type').text('Сохрн.');
}

function setText(target, text) {
  $(target).find('span:first-of-type').show().text(text).end().find('input').addClass('hide');
  $(target).parent().find('a i').removeClass('icon-ok').addClass('icon-pencil').parent().addClass('edit').removeClass('ok').find('span:first-of-type').text('Ред.');
}

/** Функция добавляет префик в input */
$(document).on('blur change keyup', 'input[data-prefix]', function(event) {
  var prefix = $(this).data('prefix');
  var value = $(this).val();

  if (value.length === 0) value = prefix;

  $(this).val(value);
});

/** Получение данных всех полей формы */
function getDataForm(form) {
  var result = {};

  form.find ('input, textarea, select').each(function() {
    result[this.name] = $(this).val();
  });

  return result;
}

/** ===============================================
 * Отправка данных формы через ajax
 ** ============================================= */

$(document).on('click', '.clickAjax', function(event) {
  event.preventDefault ? event.preventDefault() : event.returnValue = false;

  var form   = $(this);

  method = $(form).data('method') || 'get';
  source = $(form).attr('href')   || $(form).data('action');
  type   = $(form).data('type')   || 'json';
  data   = $(form).attr('data-value')  || 'ok';

  data = Object.assign({}, data.split(','));

  $.ajax({
    type      : method,
    dataType  : type,
    url       : source,
    data      : data,
    success   : function (response) {
      if (response.result === 'success') {

      }
    },
    error     : function (response) {
      console.warn('Произошла ошибка.');
    }
  });

  return false;
});


//Остановить или продолжить обзвон
function pause_continue_outcoming_call(id, status) {

  $.send('/outcoming/continue_pause/' + id + '/' + status, {}, function(res) {
    var result = res;
    if (typeof result.error != 'undefined') {
      $.setError(result.invalid);
    }
    else {
      Alert('Задание на обзвон успешно удалено!');
      location.reload();
    }
  });


}