$(document).on('change keyup', '.form #outcoming_time_start, .form #outcoming_time_end',function(event) {
  var dateBox   = $(this).parents('.form').find('#outcoming_date');

  var startTimeBox = $(this).parents('.box-interval').find('#outcoming_time_start');
  var endTimeBox   = $(this).parents('.box-interval').find('#outcoming_time_end');

  var parseDate      = $(dateBox).val().split('.');
  var parseStartTime = $(startTimeTextBox).val().split(':');
  var parseEndTime   = $(endTimeTextBox).val().split(':');

  var date = new Date(
      parseDate[2] + ',' +
      parseDate[1] + ',' +
      parseDate[0]
  );

  var startTime = date.setHours(parseStartTime[0], parseStartTime[1]);
  var endTime   = date.setHours(parseEndTime[0], parseEndTime[1]);

  var errorBox = $(this).parents('.box-interval').find('#outcoming_time');

  if(new Date(startTime).valueOf() < new Date().valueOf() ||
    new Date(startTime).valueOf() > new Date(endTime).valueOf() ||
    (parseStartTime[0] && parseStartTime[0].length > 1 && parseStartTime[0] > 23) ||
    (parseStartTime[1] && parseStartTime[1].length > 1 && parseStartTime[1] > 59) ||
    (parseEndTime[0] && parseEndTime[0].length > 1 && parseEndTime[0] > 23) ||
    (parseEndTime[1] && parseEndTime[1].length > 1 && parseEndTime[1] > 59)) {
     $(errorBox).parent().parent().addClass('has-error').end().end().text('Некорректный временной интервал');
    return false;
  } else {
    $(errorBox).parent().parent().removeClass('has-error').end().end().removeClass('has-error').text('');
  }
});

if ($("input").is("#outcoming_date")) {
    var params = {
        hourMin: 8,
        hourMax: 19,
        timeOnlyTitle: 'Выберите время',
        timeText: 'Время',
        hourText: 'Часы',
        minuteText: 'Минуты',
        currentText: 'Сейчас',
        closeText: 'Закрыть',
        showSecond: false,
        showMillisec: false,
        second_slider: false,
        showTimezone: false,
        //minDateTime: new Date(),
    };
    var startTimeTextBox = $('#outcoming_time_start');
    var endTimeTextBox = $('#outcoming_time_end');

    /*$.timepicker.timeRange(
        startTimeTextBox,
        endTimeTextBox,
        {
            minInterval: (1000 * 60 * 60), // 1hr
            timeFormat: 'HH:mm',
            start: params, // start picker options
            end: params // end picker options
        }
    );*/

    $('#outcoming_date').datepicker({
        minDate: new Date(),
        currentText: 'Сейчас',
        closeText: 'Закрыть'
    });

    var numbers = [];
}
function add_files_numbers(self) {
    makeModal({
        id       : '#numbers-file-add-modal',
        title    : "Добавление файла с номерами",
        messages : $(self).data("modal"),
        buttons  : [{text:"Сохранить", handler: function() {
            var files = $('#numbers-file-add-modal form input[name=select_file]').get(0).files,
                formData = new FormData();

            var wrapInputFile = $(this).parent().siblings('.modal-body').find('.input_file_wrap .input_file_value');

             if (files.length === 0) {
              $(wrapInputFile).html('&nbsp;&ndash; <span class="brand-color-danger">Файл не выбран</span>');
              return false;
            }

            if(files[0].type != 'audio/mpeg' && files[0].type != 'text/plain') {
              $(wrapInputFile).html('&nbsp;&ndash; <span class="brand-color-danger">Формат файла должен быть txt</span>');
              return false;
            }

            if(files[0].size >= 5242880) {
              $(wrapInputFile).html('&nbsp;&ndash; <span class="brand-color-danger">Размер файла не должен превышать 5 Мб</span>');
              return false;
            }

            if ( !files.length ) {
                $('#numbers-file-add-modal').modal('hide');
                return;
            }

            $('#numbers-file-add-modal a.btn').loading();
            formData.append('file', files[0], files[0].name);
            $.send('/outcoming/loadnumbers', formData, {
                processData: false,
                contentType: false,
                success: function (result) {
                    if (!result.ok) {
                        return;
                    }
                    numbers = JSON.parse(result.numbers);
                    var count = numbers.length;
                    if (count != 0) {
                        $('#outcoming_numbers_counter').html('Количество номеров для обзвона после проверки: <b>'+count+'</b>');
                    } else {
                        $('#outcoming_numbers_counter').html('');
                        Alert('Номера не определены');
                    }
                    $('#numbers-file-add-modal').modal('hide');
                }
            });
        }}]
    });
}

function create_outcoming_task() {

    var formData = {};
    formData.numbers = numbers;
    formData.date = $('#outcoming_date').val();
    formData.t0   = $('#outcoming_time_start').val();
    formData.t1   = $('#outcoming_time_end').val();
    formData.rc   = $('#outcoming_reply').val();
    formData.ph   = $('#outcoming_phone').val();
    formData.sc   = $('#scenario').val();
    formData.sn   = $('#outcoming_name').val();

    $.send('/outcoming/create', {
        data: JSON.stringify(formData)
    }, function(result) {
       makeModal({
        messages: 'Данные сохранены',
        buttons : [{text: 'OK', handler: function() {
          $('#modal').modal('hide');
        }}]
      });
    });
}

function show_details(self) {
    makeModal({
        id       : '#details-modal',
        title    : "Детальная информация",
        messages : $(self).data("modal")
    });
}

function out_plan_delete(id) {
    Confirm('Удаление задания на обзвон. Вы уверены?', function() {
        $.send('/outcoming/delete/' + id, {}, function(result) {
            if (result.invalid) {
                $.setError(result.invalid);
            }
            else {
                Alert('Задание на обзвон успешно удалено!');
                location.reload();
            }
        });
    });

}

function out_plan_cancel(id) {
    Confirm('Отмена задания на обзвон. Вы уверены?', function() {
        $.send('/outcoming/cancel/' + id, {}, function(result) {
            if (result.invalid) {
                $.setError(result.invalid);
            }
            else {
                Alert('Задание на обзвон отменено!');
                location.reload();
            }
        });
    });

}

function out_plan_xls(id) {
    location.href='/outcoming/details/' + id;
}

function out_plan_reply(id) {

    var msg = '';
    msg += '<p>';
    msg += '<input type="radio" class="form-control reply_mode" name="reply_mode" id="reply_mode_all" value="all_reply" checked>';
    msg += '<label for="reply_mode"> - повторить полностью</label>';
    msg += '</p><p>';
    msg += '<input type="radio" class="form-control reply_mode" name="reply_mode" id="reply_mode_err" value="err_reply">';
    msg += '<label for="reply_mode"> - только неотвеченные</label>';
    msg += '</p>';

    makeModal({
        id       : '#repeat-modal',
        title    : "Повтор обзвона",
        messages : msg,
        buttons  : [{text:"Повторить", handler: function() {

            var formData    = {};
            formData.id     = id
            formData.mode   = $('.reply_mode').val();

            jQuery.ajax({
                type: "POST",
                url: "/outcoming/repeat",
                data: {
                    data: JSON.stringify(formData)
                },
                error: function(data){
                    $.setError(data.invalid);
                },
                success: function(data){
                    var params  = data.params;
                    var repeat_numbers = data.numbers;
                    $('#outcoming_date').val(params.date);
                    $('#outcoming_time_start').val(params.time_start);
                    $('#outcoming_time_end').val(params.time_stop);
                    $('#outcoming_reply').val(params.reply_count);

                    $("#outcoming_phone option").removeAttr("selected");
                    $("#audiofile option").removeAttr("selected");
                    $("#outcoming_phone option[value=" + params.src_number + "]").attr('selected', 'true');
                    $("#audiofile option[value=" + params.filename + "]").attr('selected', 'true');

                    numbers = repeat_numbers;
                    var count = numbers.length;
                    $('#outcoming_numbers_counter').html('Количество номеров для повторного обзвона: <b>'+count+'</b>');
                    $('#repeat-modal').modal('hide');
                    Alert('Задайте новую дату и время обзвона!');
                }
            });
        }}]
    });

    $("#outcoming_phone").selectmenu('destroy').selectmenu({ style: "dropdown" });;
    $("#audiofile").selectmenu('destroy').selectmenu({ style: "dropdown" });;
}
