/** Выполняем сценарий только на странице просмотра конференции - /conferences/show/{\d+} */
if (/[a-z/]+\d+/.test(document.location.pathname)) {
  var actionConference = $('form').attr('data-conference');
  var intervalId = 0;

 var timerId = setTimeout(startConference, 10);

  var conferenceParams = {
    conferencenum: $('form input[name="number"]').val(),
    service_id: $('form input#service_id').val()
  };

  if (actionConference === 'start') {
    intervalId = setInterval(startConference, 6000);
  }

  function startConference() {
    $.send('/user_api/conference/members', conferenceParams, function(response) {
      if (response.status === true) {
        var members = response.message.members;
        var monitoringTarget = $('form').find('#collapseSecond table tbody');
        if (members.length > 0) {
          var data = Object.assign({}, members);
          var row = '';

          $.send('/conferences/members/info', data, function(html) {
            if (html) {
              $(html).each(function(key, val) {
                for (var item in val) {
                  if (typeof val[item] === 'string') {continue;}

                  row += '<tr>';

                  if (val[item].constructor.name === "Array") {
                    row  += '<td>Неизвестный пользователь</td>';
                  } else {
                    row  += '<td>' + val[item].name + ' ' + val[item].lastname + ' ' + val[item].middlename  + '</td>';
                  }

                  row  += '<td>' + (formatPhone(members[item].number) || '') + '</td>';

                  var dateTime  = new Date(members[item].tm * 1000) || '';

                  row += '<td>' + dateTime.toLocaleString('ru-RU', { hour12: false, 
                                               hour:   'numeric', 
                                               minute: 'numeric',
                                               second: 'numeric'
                                             }) +'</td>';
                  
                  row += '</tr>';
                }
              });

              monitoringTarget.html(row).slideDown();
            }
          });
        } else {
          monitoringTarget.html('<tr><td colspan="3" class="text-center"><small>Пользователи отсутствуют</small></td></tr>');
        }
      }
    });

     clearTimeout(timerId);
  }
  
  /** Остановка / Возобновление конференции по кнопке */
  $(document).on('click', 'form .btn#action', function(event) {
    var value = $(this).attr('data-value');
    if(intervalId > 0) {
      clearInterval(intervalId);
      $('form').attr('data-conference', 'stop');
      $(this).text('Возобновить конференцию').attr('data-value', value.replace(/stop/, 'start'));

      conferenceParams.action = 'close';

      $.send('/user_api/conference/stop', conferenceParams, {});

      $(this).parents('#accordion').find('#collapseSecond tbody').
        html('<tr><td colspan="3" class="text-center"><small>Конференция завершена</small></td></tr>');
      intervalId = 0;
    } else {
      intervalId = setInterval(startConference, 6000);
      $('form').attr('data-conference', 'start');
      $(this).text('Завершить конференцию').attr('data-value', value.replace(/start/, 'stop'));
    }
  });

  /** Очистка конференции перед удалением */
  $(document).on('click', '.delete_conference', function(event) {
    conferenceParams.action = 'close';
    Confirm('Вы точно хотите удалить конференцию?', function() {
      $.send('/user_api/conference/stop', conferenceParams, function(response) {
        $.send('/conferences/delete/' + conferenceParams.conferencenum, {});
        window.location = '/conferences';
      });
    });
  });
}

function delConference(self) {
  Confirm('Удалить конференцию?', function(){
    $('#modal').find('.btn-success').loading();

    var conferenceParams = {
      conferencenum: $(self).data('number'),
      service_id: $(self).data('service_id'),
      action: 'close'
    };

    $.send('/user_api/conference/stop', conferenceParams, function(response) {
      $.send('/conferences/delete/' + conferenceParams.conferencenum, {});
      $(self).closest('tr').remove();
    });
  });
}