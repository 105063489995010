function handleGroups() {
  handleFileUploadSelect();
  handleGroupEmployersList();
}

function handleFileUploadSelect() {
  $('.file-select-upload').change(function(){
    var self = this;
    if ($(self).val() == 'upload') {
      $('#tmp_upload_file').remove();
      $(self).after('<input type="file" id="tmp_upload_file">');
      setTimeout(function(){
        $('#tmp_upload_file').change(function(event){
          var file = event.target.files[0];
          var $uploadInput = $(this);
          if ( ! $uploadInput.next('.file-select-upload-data').length) {
            $uploadInput.after('<input class="file-select-upload-name" type="hidden" name="' + $(self).attr('name') + '_name">');
            $uploadInput.after('<input class="file-select-upload-data" type="hidden" name="' + $(self).attr('name') + '_file">');
          }
          $(self).find('option[value="upload"]').text(file.name);
          $(self).selectmenu( "refresh" );
          setTimeout(function(){
            var reader = new FileReader();
            reader.onload = function(e) {
              $uploadInput.parent().find('.file-select-upload-data').val(e.target.result);
              $uploadInput.parent().find('.file-select-upload-name').val(file.name);
              $('#tmp_upload_file').remove();
            };
            reader.readAsDataURL(file);
          }, 0);
        }).click();
      }, 0);
    }
  });
}

function saveGroup(self) {
  var $form = $(self).closest('form');
  var $clone = $form.clone();
  var selects = [
    'define_number_id',
    'calls_order_id',
    'welcome_audio_id',
    'wait_audio_id',
    'action_id',
    'action_option'
  ];
  $clone.find('.hide').remove();
  $form.removeAttr('data-form_changed');
  $form.find('.save-group').loading();
  //Сделано для восстановления value, т.к. clone очищать val у select
  for (var i in selects) {
    if ($('select[name=' + selects[i] + '] option').length > 1) {
      $clone.find('select[name=' + selects[i] + ']').val($form.find(':not(.hide) > select[name=' + selects[i] + ']').val());
    }
  }
  $.send($clone.attr('action'), $clone.serializeArray(), function(result){
    makeModal({
      messages: 'Данные сохранены',
      buttons : [{text: 'OK', handler: function() {
        $('#modal').modal('hide');
        window.location.href = '/' + $clone.attr('action').split('/')[1];
      }}]
    });
  });
  return false;
}

function delGroup(self) {
  Confirm('Удалить группу вызова?', function(){
    $('#modal').find('.btn-success').loading();
    $.send('/groups/del/' + $(self).data('id'), {}, function(result){
      $(self).closest('tr').remove();
    });
  });
}

function handleGroupEmployersList() {
  var $employersList = $('#group_employers_list');
  if ($employersList.length) {
    $employersList.find('tbody').empty();
    var selectedIds = $employersList.data('selected');
    var employersList = $employersList.data('list');
    var $template = $('.group-employer-tpl');
    var emp, key, $elem, $tpl;
    for (var i in selectedIds) {
      $tpl = $template.clone();
      $tpl.removeClass('hide');
      emp = employersList[selectedIds[i]];
      for (key in emp) {
        $elem = $tpl.find('.assign-' + key);
        if (key == 'id') {
          $elem.attr('data-id', emp[key]);
          if ($elem.attr('type') == 'hidden') {
            $elem.val(emp[key]);
          }
        }
        else {
          $elem.text(emp[key]);
        }
      }
      $employersList.find('tbody').append($tpl);
    }
  }
}

function addEmployerToGroup(self) {
  var $select = $('.add-group-employer-select');
  var id = $select.val();
  if ( ! id) {
    return false;
  }
  $select.find('option[value=' + id + ']').remove();
  if ( ! $select.find('option').length) {
    $select.append('<option></option>');
  }

  if($select.find('option').val() == '')
  {
    $select.find('option').remove();
  }

  selectMenuFilter($select);

  var $employersList = $('#group_employers_list');
  var selectedIds = $employersList.data('selected');
  selectedIds.push(id);
  $employersList.data('selected', selectedIds);
  handleGroupEmployersList();
  return false;
}

function removeEmployerFromGroup(self) {
  var id = $(self).data('id');
  var $employersList = $('#group_employers_list');
  var employersList = $employersList.data('list');
  var emp = employersList[id];
  var selectedIds = $employersList.data('selected');
  for (var i in selectedIds) {
    if (selectedIds[i] == id) {
      delete selectedIds[i];
      break;
    }
  }
  $employersList.data('selected', selectedIds);
  var $select = $('.add-group-employer-select');
  $select.find('option:not([value])').remove();
  $select.append('<option value="' + id + '">' + emp.number + ' - ' + emp.name + '</option>');
  selectMenuFilter($select);
  handleGroupEmployersList();
  return false;
}

/** Функция показывает элементы "Анонсов" */
function showAnouncesElements(elem) {
  $('#anounces').find(elem).removeClass('hide').
    find('input, select').addClass('valid-required').
    parent().find('label .text-danger').show();
}

/** Функция скрывает элементы "Анонсов" */
function hideAnouncesElements(elem) {
  $('#anounces').find(elem).addClass('hide').
    find('input, select').removeClass('valid-required').
    parent().find('label .text-danger').hide();
}

/**
 * Функция показывает используемые элементы в блоке "Анонсов" при загрузке страницы
 *
 * @author  Sedov Stas
 *
 * @return void
 */
(function() {
  var field = $('#anounces .form-group .checkbox input:checked') || null;

  $(field).each(function(key, val) {
    var checkName = $(val).attr('name') || null;

    if($(val).is(':checked') === true) {
      if(checkName === 'announce_position' || checkName === 'announce_holdtime') {
        showAnouncesElements('#announce_repeat');
      }
      else if(checkName === 'announce_custom') {
        showAnouncesElements('#periodic_announce_frequency, #announce_file');
      }
    }
  });
})();

/**
 * Функция скрывает или показывает поля в блоке "Анонсов"
 *
 * @author  Sedov Stas
 *
 * @return void
 */
$(document).on('change', '#anounces .form-group .checkbox', function(event) {
  var checkbox = $(this).find('input:checkbox');
  var checkName = $(checkbox).attr('name');

  if(checkName === 'announce_position') {
    if(checkbox.is(':checked') === true) {
      showAnouncesElements('#announce_repeat');
    }
    else {
      if($(this).parents('#anounces').find('#announce_holdtime').is(':checked') === false) {
        hideAnouncesElements('#announce_repeat');
      }
    }
  }

  if(checkName === 'announce_holdtime') {
    if(checkbox.is(':checked') === true) {
      showAnouncesElements('#announce_repeat');
    }
    else {
      if($(this).parents('#anounces').find('#announce_position').is(':checked') === false) {
        hideAnouncesElements('#announce_repeat');
      }
    }
  }

  if(checkName === 'announce_custom') {
    if(checkbox.is(':checked') === true) {
     showAnouncesElements('#periodic_announce_frequency, #announce_file');
    }
    else {
      hideAnouncesElements('#periodic_announce_frequency, #announce_file');
    }
  }
});

/** Функция показывает элементы "Выхода из очереди вызовов по нажатию клавиши телефона" */
function showExitQueueElements(elem) {
  $('#exits').find(elem).removeClass('hide').end().
  find('.rows-add').removeClass('hide').end().
  find('input, select').addClass('valid-required').
  parent().find('label .text-danger').show();
}

/** Функция скрывает элементы "Выхода из очереди вызовов по нажатию клавиши телефона" */
function hideExitQueueElements(elem) {
  $('#exits').find(elem).addClass('hide').end().
  find('.rows-add').addClass('hide').end().
  find('input, select').removeClass('valid-required').
  parent().find('label .text-danger').hide();
}

/**
 * Функция показывает используемые элементы в блоке "Выхода из очереди вызовов по нажатию клавиши телефона"
 * при загрузке страницы
 *
 * @author  Sedov Stas
 *
 * @return void
 */
(function() {
  var field = $('#exits .form-group .checkbox input:checked') || null;

  $(field).each(function(key, val) {
    var checkName = $(val).attr('name') || null;

    if($(val).is(':checked') === true) {
      if(checkName === 'exit_queue') {
        showExitQueueElements('#exit_key, #option_address, #scenario');
      }
    }
  });

  $('#exits .form-group').each(function(key, val) {
    // При загрузке страницы присваиваем раскрывающимся спискам последнее значение
    if($(val).hasClass('nonvisible')) {
      $(val).find('select option:last-of-type').attr('selected', 'selected');
    }
  });
})();

/**
 * Функция скрывает или показывает поля в блоке "Выхода из очереди вызовов по нажатию клавиши телефона"
 *
 * @author  Sedov Stas
 *
 * @return void
 */
$(document).on('change', '#exits .form-group .checkbox', function(event) {
  var checkbox = $(this).find('input:checkbox');
  var checkName = $(checkbox).attr('name');

  if(checkName === 'exit_queue') {
    if(checkbox.is(':checked') === true) {
      showExitQueueElements('#exit_key, #option_address, #scenario');
    }
    else {
      hideExitQueueElements('#exit_key, #option_address, #scenario');
    }
  }
});

/**
 * Функция скрывает или показывает поля "Выбора сценария" при выборе "Варианта адресации"
 *
 * @author  Sedov Stas
 *
 * @return void
 */
$(document).on('change', '#exits #option_address', function(event) {
  var rows = $(this).parents('.rows');

  var listScenario = $(rows).find('#scenario');

  if($(this).find('option:selected').val() === 'scenario') {
    $(listScenario).removeClass('nonvisible');
  } else {
    $(listScenario).addClass('nonvisible').find('option:selected').removeAttr('selected');
  }
});

/**
 * Функция добавляет новляет новую строку полей в форму
 *
 * @author  Sedov Stas
 *
 * @return void
 */
$(document).on('click', '.replication-rows .rows-add a', function(event) {
  event.preventDefault();

  var row   = $(this).closest('.rows:last-of-type');
  var clone = row.clone(false);

  $(row).after(clone).show(1000);
  formStyled();

  var index = 0;

  $(row).parent().find('.rows:last-of-type .rows-del').removeClass('hide');
  
  // Удаляем поля неподписанные на события
  $(clone).find('.form-group .ui-widget:nth-of-type(2)').hide().end().find('.tooltip').detach();

  var validateText = $(clone).find('.form-group .help-block').attr('validate').replace(/[\d]+/g,'');

  setMaskPhone($(clone).find('input.mask-phone'));

  $(clone).find('.form-group .help-block').each(function(key, val) {
    index = parseInt($(val).parents('.replication-rows .rows').prev().find('.form-group .help-block').attr('validate').replace(/\D+/g,''));

    index++;

    $(val).removeAttr('validate').attr('validate', validateText + index);
  });
  
  // Удаляем уже использованные клавиши
  dropExitKey(row, clone);
});

/**
 * Функция удаляет строку полей в форме
 *
 * @author  Sedov Stas
 *
 * @return void
 */
$(document).on('click', '.replication-rows .rows-del a', function(event) {
  event.preventDefault();

  var row   = $(this).closest('.rows');
  $(row).remove().hide(800);
});

/** Функция удаляет класс hode у иконки "Удалить" со 2-й и последующих строк */
(function() {
  var rows = $(document).find('.replication-rows .rows');

  $(rows).each(function(key, val) {
    if(key > 0) {
      $(val).find('.rows-del').removeClass('hide');
    }
  });
}());

/**
 * Функция удаляет выбранные клавиши из вновь создаваемой строки.
 *
 * @author  Sedov Stas
 * 
 * @param  {object} oldRow Исходная строка полей
 * @param  {object} newRow Клонированная строка полей
 * 
 * @return void
 */
function dropExitKey(oldRow, newRow) {
  var prevExitKey = $(oldRow).find('#exit_key select option:selected');
  var currExitKey = $(newRow).find('#exit_key select option');

  for (var i = currExitKey.length - 1; i >= 1; i--) {
    if($(prevExitKey).val() === $(currExitKey[i]).val()) {
      $(newRow).find('#exit_key select option[value="' + $(currExitKey[i]).val() + '"]').remove();
    }
  }

  $(newRow).find('#exit_key .ui-selectmenu-text').text('');
}
