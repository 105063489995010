function employers() {
  this.sendRequisites = function(self, id) {
    if ($('.form-leave-notice').attr('data-form_changed')) {
      makeModal({
        title    : 'Данные были изменены',
        messages : 'Для отправки реквизитов сначала сохраните изменения',
        buttons  : ['OK']
      });
      return false;
    }
    $(self).loading();
    $.send('/employers/sendRequisites/' + id, {}, function(data) {
      makeModal({
        messages : (data.ok ? 'Реквизиты отправлены' : 'Произошла ошибка'),
        buttons  : ['OK']
      });
    });
    return false;
  };

  this.zoiperDownload = function(proxy, login, pass) {
    window.open('https://www.zoiper.com/en/page/a203b783b07eeaeba7a8182e2fa7cedb?u=' + login + '&h=' + proxy + '&p=' + pass + '&o=&t=&x=&a=&tr=');
    return false;
  };
}

function getTerminalsStatuses() {
  $.send('/employers/terminals/', {}, function(data) {
    data.forEach(function(item) {
      if (parseInt(item.status_id) != 0) {
        $('#reg_' + item.number).html('<span class="green_color" title="' + item.status_id + ' зарегистрирован">&bull;</span>');
      } else {
        $('#reg_' + item.number).html('<span class="red_color" title="Не зарегистрирован">&bull;</span>');
      }
    });
    });
}

function handleEmployersSection() {
  $('.find-free-numbers').click();
}

function addEmployerTerminal(self) {
  var $terminal = $('.terminal-table:first').clone();
  $terminal.removeClass('hide');
  $('.add-terminal').before($terminal);
  changeEntityNumber();
  handlePasswordFields();
  return false;
}

function zoiperTermDownload(proxy, login, pass) {
  window.open('https://www.zoiper.com/en/page/a203b783b07eeaeba7a8182e2fa7cedb?u=' + login + '&h=' + proxy + '&p=' + pass + '&o=&t=&x=&a=&tr=');
  return true;
}

function delEmployerTerminal(self) {
  $(self).closest('.terminal-table').remove();
  changeEntityNumber();
  return false;
}

function saveEmployer(self) {
  var $form = $(self).closest('form');
  var $clone = $form.clone(true);
  var isAdmin = parseInt($('input[name=is_admin]').val());
  var selects = [
    'forwarding_id',
    'action_id',
    'action_option',
    'define_number_id'
  ];
  $clone.find('.hide').remove();
  $form.removeAttr('data-form_changed');
  $clone.find(':input:disabled').each(function(i, el) {
    $(el).removeAttr('disabled');
  });

  var t_c = 0;
  var err = 0;
  $('input[name="t_pass[]"]').each(function(){
    if (t_c > 0 && $(this).val() == '') {
      $(this).focus();
      err ++;
      return false;
    }
    t_c ++;
  });

  if (err != 0) {
    Alert('Требуется указать пароль у терминала!');
    return false;
  }

  //Сделано для восстановления value, т.к. clone очищать val у select
  for (var i in selects) {
    if ($('select[name=' + selects[i] + '] option').length > 1) {
      $clone.find('select[name=' + selects[i] + ']').val($form.find(':not(.hide) > select[name=' + selects[i] + ']').val());
    }
  }
  $form.find('button[type=submit]').loading();
  $.send($clone.attr('action'), $clone.serializeArray(), function(result){
     makeModal({
      messages: 'Данные сохранены',
      buttons : [{text: 'OK', handler: function() {
        $('#modal').modal('hide');
        window.location.href = '/' + $clone.attr('action').split('/')[1];
      }}]
    });
  });
  return false;
}

function delEmployer(self) {
  Confirm('Удалить абонента?', function(){
    $('#modal').find('.btn-success').loading();
    $.send('/employers/del/' + $(self).data('id'), {}, function(result){
      $(self).closest('tr').remove();
    });
  });
}

var type_comunication_employer = $("input[name=block_code_id]:checked").val();

function show_popup_full_comunication() {
  Confirm('<p><i>В целях обеспечения безопасности ' +
    'Вам необходимо заполнить IP адрес ' +
    'для ограниченного доступа к учетной записи ' +
    'или добавить Пин-код для совершения Международных звонков.</i></p>' +
    'Вы уверены, что хотите изменить тип исходящей связи?'
    , function () {
      type_comunication_employer = $("input[name=block_code_id]:checked").val();
    }, function () {
      $("input[name=block_code_id][value=" + type_comunication_employer + "]").click();
    });
}

function show_confirm_change_comunication() {
  Confirm('Вы уверены, что хотите изменить тип исходящей связи?', function () {
    type_comunication_employer = $("input[name=block_code_id]:checked").val();
  }, function () {
    $("input[name=block_code_id][value=" + type_comunication_employer + "]").click();
  });
}

function handleAddEmployerChangeName() {
  $('.employer-change-count').keyup(function(){
    $(this).closest('tr').find('.btn').text((parseInt($(this).val()) >= 0 ? 'Добавить' : 'Удалить') + ' сотрудника');
  });
}

function handleAddAdditionalNumbersChange() {
  var $abonpaySumInput = $('#add_number_total_abonpay_sum');
  var $countInput = $('#add_number_count');
  $abonpaySumInput.text(($countInput.val() * parseFloat($abonpaySumInput.data('one'))).toFixed(2));
}

function switchFunction(self, action, data, params) {
  data = data || {};
  data.count = data.count || 1;
  var selfData = $(self).data() || {};
  data = $.extend({}, selfData, data);
  params = params || {};
  action = data.count > 0 && action == 'add' ? 'add' : 'del';
  data.count = Math.abs(data.count);
  var perform = function(){
    var count, functionId;
    if (data.id) {
      count = data.count;
      functionId = data.id;
    }
    else {
      count = $('#add_number_count').val();
      functionId = $('#add_number_count').data('function_id');
    }
    $(self).loading();
    $.send('/functions/' + action + '/' + functionId, {'count' : count}, {
      done: function(result){
        params.callback && params.callback(result);
        if (result.ready && params.redirect) {
          window.location = '/employers/show';
        }
        else if (result.pay) {
          Alert('Для того, чтобы изменение вступило в силу, необходимо оплатить установочный платеж в размере ' + result.pay + 'руб.', "Изменение добавлено в план");
        }
        else if (params.reload) {
          Alert(
            'Изменения вступят в силу в течение 15 минут',
            "Изменение добавлено в план"
          );
          if (action == 'add' &&  data.id == 8 && data.active_autosecretary) {
            Confirm('Отключить функцию "Одноуровневое голосовое меню"?', function() {
              switchFunction(self, 'del', {id: 7}, {reload:true});
            });
          }
        }
        if (data.id == 7) {
          if (action == 'add') {
            $('.action-button').data('active_autosecretary', 1);
          }
          else {
            $('.action-button').data('active_autosecretary', 0);
          }
        }
        if (data.id == 8) {
          if (action == 'add') {
            $('.action-button').data('active_ivr', 1);
          }
          else {
            $('.action-button').data('active_ivr', 0);
          }
        }
      },
      fail: function(){
        $('#modal').modal('hide');
      }
    });
  };
  if (params.confirm) {
    var msg = '<p>Вы собираетесь ' + (action == 'add' ? 'подключить' : 'отключить') + ' функцию <b>' + (data.name || '') + '</b>.</p>';
    if ( ! isNaN(parseFloat(data.start_cost)) && action == 'add') {
      msg += '<p>Стоимость подключения составит <b>' + (parseFloat(data.start_cost) * data.count) + ' руб.</b></p>';
    }
    if ( ! isNaN(parseFloat(data.cost))) {
      msg += '<p>После ' + (action == 'add' ? 'подключения' : 'отключения') + ' функции, аб.плата ' + (action == 'add' ? 'увеличится' : 'уменьшится') + ' на <b>' + (parseFloat(data.cost) * data.count) + ' руб./мес.</b></p>';
    }

    if (data.count_scenario == 0 || typeof data.count_scenario == 'undefined') {
      msg += '<p>' + (action == 'add' ? 'Подключить' : 'отключить') + ' функцию?</p>';
      if (action == 'add') {
        if (data.id == 7 && data.active_ivr) {
          makeModal({
            modalDialogClass: 'modal-sx modal-alert',
            messages: ['Данная функция уже входит в "Многоуровневое голосовое меню"'],
            buttons: [{
              text: 'Ok', handler: function () {
                $('#modal').modal('hide');
                return false;
              }
            }]
          });
          return;
        }
      }
      Confirm(msg, perform, function () {
        $('#modal').modal('hide')
      }, {
        title: (action == 'add' ? 'Подключение функции' : 'Отключение функции'),
        button_text: {ok: (action == 'add' ? 'Подключить' : 'Отключить')}
      });
    } else {
      msg += "<p>Вы не можете отключить услугу, потому что данная услуга использует созданные Вами сценарии</p>";
      Alert(
          msg,
          "Предупреждение"
      );
    }
  }
  else {
    perform();
  }
  return false;
}

$( document ).ready(function() {
  if (document.location.pathname == '/employers') {
    getTerminalsStatuses();
  }

  // Изменяем ограничение доступа при выборе типа доступа
  $(document).on('click', '#out-call .checkbox label input:radio', function() {
    changeTypeAccess(this);
  });

  // Изменяем видимость полей блока "Ограничение доступа"
  $(document).on('click', '#limit-access label input:checkbox', function() {
    changeVisibleAccessInput(this);
  });

  // Меняем вмдимость полей выбора прав роли "Руководителя"
  $(document).on('change', '[data-target="perms_role"]', function(event) {
    changeVisebleFields(this);
  });

  // Отмечаем страницы, относящиеся к разделу
  $(document).on('click blur', '#level-access button', function() {
    $(document).on('click', '.ui-multiselect-menu:visible label input:checkbox[name="multiselect_sections"]', function() {
      changeCheckedPermissionsInputs(this);
    });
  });

  $(document).on('click blur', '#groups-select button', function(event) {
     $(document).on('click', '.ui-multiselect-menu:visible label input[name="multiselect_groups"]', function() {
      changeStaffField($(this).parent().parent());
    });
  });

  $(document).on('change keyup keydown', '#collapseIncomingPhone .rows input', function(event) {
    var input = $(this).val();

    var phone = input.replace(/[^+\d]*/g, '');

    $.send('/employers/incoming_phones/used', {
      'phone': phone
    }, function(response) {
      if(response.result === true) {
        addMessageError(event.currentTarget, 'Номер телефона уже используется', 'warning');
      } else {
        removeMessageError(event.currentTarget);
      }
    });

    
  });
});

defaultVisibleAccess();
defaultVisibleFields();

/**
 * Функция изменяет состояние checkbox "Ограничение доступа по IP или по Пин-код"
 * @author  Sedov Stas
 * @param object input Объект radio "Типа доступа исходящей связи"
 */
function changeTypeAccess(input) {
  var selTypeAccess = 1;
  selTypeAccess = parseInt($(input).val());

  var accessInput = $('#limit-access input:checkbox');

  $(input).parents('#out-call').find('input').each(function(key, val) {
    if($(val).is(':checked')) {
      if($(val).val() == 1) {
        $(accessInput).prop('checked', true).parent().addClass('checked').end().prop('disabled', true).parent().  addClass('disabled');
      }
      else {
        $(accessInput).removeAttr('disabled').parent().removeClass('disabled').parent().removeClass('disabled');
      }
    }
  });

  changeVisibleAccessInput(accessInput);
}

/**
 * Функция изменяет видимость полей блока "Ограничение доступа"
 * @author  Sedov Stas
 * @param  object parent Объект переключателя
 */
function changeVisibleAccessInput(parent) {
  var input = $(parent).parents('#limit-access').find('.form-group:not(:nth-of-type(1))');

  if($(parent).is(':checked')) {
    $(parent).prop('checked', true).parent().addClass('checked');
    $(input).removeClass('hidden');
  }
  else {
    $(parent).prop('checked', false).parent().removeClass('checked');
    $(input).addClass('hidden');
  }
}

/**
 * Функция устанавливает значение видимости флажка "Ограничение доступа" по умолчанию
 * @author  Sedov Stas
 */
function defaultVisibleAccess() {
  var input = $('#out-call .checkbox label input:radio').get(0);
  changeTypeAccess(input);
}

/**
 * Функция меняет видимость полей выбора прав для роли "Руководитель"
 * @author  Sedov Stas
 * @param  object parent Объект списка "Доступа"
 */
function changeVisebleFields(parent) {
  var roles  = $(parent).val() || [];
  var target = $(parent).data('target');
  var groupSelect = $(parent).parents('form').find('#groups-select');

  if($(parent).find('option:nth-of-type(3)').is(':selected')) {
    $(roles).each(function(key, val) {
      if(val == '3') {
        $(groupSelect).find('label span').removeClass('hide');
        $('#' + target).removeClass('hide').find('select').prop('disabled', false);
      }
    });
  } else {
    $(groupSelect).find('label span').addClass('hide');
    $('#' + target).addClass('hide').find('select').prop('disabled', true);
    $('#' + target).find('#staff').removeAttr('required');
  }
}

/**
 * Функция показывает поля, если у пользователя роль "Руководитель"
 * @author  Sedov Stas
 */
function defaultVisibleFields() {
  var roles  = $(document).find('[data-target="perms_role"]').val();
  var target = $(document).find('#perms_role');

  $(roles).each(function(key, val) {
    if(val == '3') {
      $(target).removeClass('hide').find('select');
    }
  });
}

/**
 * Функция переключает "Подчинённых" из "Группы"
 * @author  Sedov Stas
 * @param  {object} groups Объект поля "Группа"
 */
function changeStaffField(groups) {
  var group = $(groups).find('div');
  var selectGroup = $(group).find('input').val();

  if($(group).is(':not(.checked')) {
    selectStaffItem(selectGroup);
  } else {
    removeStaffItem(selectGroup);
  }
}

/**
 * Функция устанавливает значения поля "Подчинённые"
 * @author  Sedov Stas
 * @param  {object} item Значения выбранных групп
 */
function selectStaffItem(item) {
  var group = [];
  var items = [];

  $('#staff option').each(function(key, val) {
    group = $(val).data('group').toString();

    if(group != '' && item != '') {
      var Reg = new RegExp(item, 'gm');
      if(Reg.test(group)) {
        $(val).attr('selected', 'selected');
        $('.ui-multiselect-menu input[value="'+$(val).val()+'"][name="multiselect_staff"]').parent().addClass('checked');
      }
    }
  });
}

/**
 * Функция снимает значения поля "Подчинённые"
 * @author  Sedov Stas
 * @param  {object} item Значения выбранных групп
 */
function removeStaffItem(item) {
  var group  = [];
  var groups = [];

  $('#staff option').each(function(key, val) {
    group = $(val).data('group').toString();

    if(group != '' && item != '') {
      var groups = $('#groups').val().filter(function(key) {
        return key != item;
      }).join();

      // Если не выбрана ниодна группа
      if(groups.length === 0) {
        $(val).removeAttr('selected');
        $('.ui-multiselect-menu input[value="'+$(val).val()+'"]').parent().removeClass('checked');
      } else {
        var pattern = group.split(',').join('|') || '';
        var Reg = new RegExp(pattern.replace(/[\|]$/, ''), 'gm');

        if((groups.match(Reg) || []).length === 0) {
          $(val).removeAttr('selected');
          $('.ui-multiselect-menu input[value="'+$(val).val()+'"]').parent().removeClass('checked');
        }
      }
    }
  });
}

function changeCheckedPermissionsInputs(value) {
  if($(value).is(':checked') === true) {
    selectPermissionsItem($(value).val());
  } else {
    removePermissionsItem($(value).val());
  }
}

function selectPermissionsItem(item) {
  namesJoin   = '';
  permissions = $('#permissions');

  $(permissions).find('select option').each(function(key, val) {
    if($(val).data('section') == item && !$(val).is(':checked')) {
      namesJoin += $(val).text() + ', ';
      $(val).attr('selected', 'selected');
      $('.ui-multiselect-menu input[value="'+$(val).val()+'"][name="multiselect_permissions"]').parent().addClass('checked');
    }
  });
  
  $(permissions).find('select').multisekect('refresh');
}

function removePermissionsItem(item) {
  namesJoin   = '';
  permissions = $('#permissions');

  $(permissions).find('select option').each(function(key, val) {
    if($(val).data('section') == item && $(val).is(':checked')) {
      namesJoin += $(val).text() + ', ';
      $(val).removeAttr('selected');
      $('.ui-multiselect-menu input[value="'+$(val).val()+'"][name="multiselect_permissions"]').parent().removeClass('checked');
    } 
  });

  $(permissions).find('select').multisekect('refresh');
}

$(document).on('click', '#add-employers', function(event) {
  $.get('/employers/limit/').done(function(data) {
    if(data.limit === true) {
      makeModal({
        title:"Достигнут лимит",
        messages:$(event.target).data("modal"),
        buttons:[
        {text: "Добавить", handler: function() {
          switchFunction(event.target, "add", null, {redirect: true});
        }
      }]
    });
    } else {
      window.location='/employers/show';
    }
  });
});

