Chart.defaults.global.colours = [
{
  fillColor: "rgba(0,99,177 ,0.7)",
  strokeColor: "rgba(0,99,177 ,1)",
  pointColor: "rgba(0,99,177 ,1)",
  highlightFill: "rgba(0,99,177 ,0.9)",
  highlightStroke: "rgba(0,99,177 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(0,99,177 ,0.8)"
},
{
  fillColor: "rgba(231,72,86 ,0.7)",
  strokeColor: "rgba(231,72,86 ,1)",
  pointColor: "rgba(231,72,86 ,1)",
  highlightFill: "rgba(231,72,86 ,0.9)",
  highlightStroke: "rgba(231,72,86 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(231,72,86 ,0.8)"
},
{
  fillColor: "rgba(0,178,148 ,0.7)",
  strokeColor: "rgba(0,178,148 ,1)",
  pointColor: "rgba(0,178,148 ,1)",
  highlightFill: "rgba(0,178,148 ,0.9)",
  highlightStroke: "rgba(0,178,148 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(0,178,148 ,0.8)"
},
{
  fillColor: "rgba(0,153,188 ,0.7)",
  strokeColor: "rgba(0,153,188 ,1)",
  pointColor: "rgba(0,153,188 ,1)rgba(0,153,188 ,1)",
  highlightFill: "rgba(0,153,188 ,0.9)",
  highlightStroke: "rgba(0,153,188 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(0,153,188 ,0.8)"
},
{
  fillColor: "rgba(234,0,94 ,0.7)",
  strokeColor: "rgba(234,0,94 ,1)",
  pointColor: "rgba(234,0,94 ,1)",
  highlightFill: "rgba(234,0,94 ,0.9)",
  highlightStroke: "rgba(234,0,94 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(234,0,94 ,0.8)"
},
{
  fillColor: "rgba(16,137,62 ,0.7)",
  strokeColor: "rgba(16,137,62 ,1)",
  pointColor: "rgba(16,137,62 ,1)",
  highlightFill: "rgba(16,137,62 ,0.9)",
  highlightStroke: "rgba(16,137,62 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(16,137,62 ,0.8)"
},
{
  fillColor: "rgba(218,59,1 ,0.7)",
  strokeColor: "rgba(218,59,1 ,1)",
  pointColor: "rgba(218,59,1 ,1)",
  highlightFill: "rgba(218,59,1 ,0.9)",
  highlightStroke: "rgba(218,59,1 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(218,59,1 ,0.8)"
},
{
  fillColor: "rgba(136,23,152 ,0.7)",
  strokeColor: "rgba(136,23,152 ,1)",
  pointColor: "rgba(136,23,152 ,1)",
  highlightFill: "rgba(136,23,152 ,0.9)",
  highlightStroke: "rgba(136,23,152 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(136,23,152 ,0.8)"
},
{
  fillColor: "rgba(0,183,195 ,0.7)",
  strokeColor: "rgba(86,124,115 ,1)",
  pointColor: "rgba(86,124,115 ,1)",
  highlightFill: "rgba(86,124,115 ,0.9)",
  highlightStroke: "rgba(86,124,115 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(86,124,115 ,0.8)"
},
{
  fillColor: "rgba(76,74,72 ,0.7)",
  strokeColor: "rgba(76,74,72 ,1)",
  pointColor: "rgba(76,74,72 ,1)",
  highlightFill: "rgba(76,74,72 ,0.9)",
  highlightStroke: "rgba(76,74,72 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(76,74,72 ,0.8)"
},
{
  fillColor: "rgba(247,99,12 ,0.7)",
  strokeColor: "rgba(247,99,12 ,1)",
  pointColor: "rgba(247,99,12 ,1)",
  highlightFill: "rgba(247,99,12 ,0.9)",
  highlightStroke: "rgba(247,99,12 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(247,99,12 ,0.8)"
},
{
  fillColor: "#ffeb3b",
  strokeColor: "rgba(132,117,69 ,1)",
  pointColor: "rgba(132,117,69 ,1)",
  highlightFill: "rgba(132,117,69 ,0.9)",
  highlightStroke: "rgba(132,117,69 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(132,117,69 ,0.8)"
},
{
  fillColor: "rgba(16,124,16 ,0.7)",
  strokeColor: "rgba(16,124,16 ,1)",
  pointColor: "rgba(16,124,16 ,1)",
  highlightFill: "rgba(16,124,16 ,0.9)",
  highlightStroke: "rgba(16,124,16 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(16,124,16 ,0.8)"
},
{
  fillColor: "rgba(104,118,138 ,0.7)",
  strokeColor: "rgba(104,118,138 ,1)",
  pointColor: "rgba(104,118,138 ,1)",
  highlightFill: "rgba(104,118,138 ,0.9)",
  highlightStroke: "rgba(104,118,138 ,0.6)",
  pointStrokeColor: "#fff",
  pointHighlightFill: "#fff",
  pointHighlightStroke: "rgba(104,118,138 ,0.8)"
},
{
    fillColor: "rgba(109,135,100 ,0.7)",
    strokeColor: "rgba(109,135,100 ,1)",
    pointColor: "rgba(109,135,100 ,1)",
    highlightFill: "rgba(109,135,100 ,0.9)",
    highlightStroke: "rgba(109,135,100 ,0.6)",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "rgba(109,135,100 ,0.8)"
},
{
    fillColor: "rgba(0,80,239 ,0.7)",
    strokeColor: "rgba(0,80,239 ,1)",
    pointColor: "rgba(0,80,239 ,1)",
    highlightFill: "rgba(0,80,239 ,0.9)",
    highlightStroke: "rgba(0,80,239 ,0.6)",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "rgba(0,80,239 ,0.8)"
},
{
    fillColor: "rgba(229,20,0 ,0.7)",
    strokeColor: "rgba(229,20,0 ,1)",
    pointColor: "rgba(229,20,0 ,1)",
    highlightFill: "rgba(229,20,0 ,0.9)",
    highlightStroke: "rgba(229,20,0 ,0.6)",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "rgba(229,20,0 ,0.8)"
},
{
    fillColor: "rgba(27,161,226 ,0.7)",
    strokeColor: "rgba(27,161,226 ,1)",
    pointColor: "rgba(27,161,226 ,1)",
    highlightFill: "rgba(27,161,226 ,0.9)",
    highlightStroke: "rgba(27,161,226 ,0.6)",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "rgba(27,161,226 ,0.8)"
},
{
    fillColor: "rgba(240,163,10 ,0.7)",
    strokeColor: "rgba(240,163,10 ,1)",
    pointColor: "rgba(240,163,10 ,1)",
    highlightFill: "rgba(132,117,69 ,0.9)",
    highlightStroke: "rgba(240,163,10 ,0.6)",
    pointStrokeColor: "#fff",
    pointHighlightFill: "#fff",
    pointHighlightStroke: "rgba(240,163,10 ,0.8)"
},
];