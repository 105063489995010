var parent = {
  id: '',
  name: ''
};
function scenarios() {
  this.modalHoldMusic = function () {
    var modalHoldMusic = function (audioFiles) {
      var filesOptions = ['<option value="" disabled="disabled" selected hidden class="hidden">Выберите&hellip;</option>'],
        customMusicFileId = $('#custom_music_file_id').val();
      audioFiles.forEach(function (file) {
        if (!file.file_id) {
          return;
        }
        filesOptions.push(['<option ' + (customMusicFileId == file.id ? 'selected' : '') + ' value="' + file.id + '">' + file.name + '</option>']);
      });
      makeModal({
        id: '#modal-hold-music-file',
        title: 'Музыка ожидания',
        buttons: [{
          text: 'Сохранить',
          handler: function () {
            $(this).loading();
            var fileId = $('.audio_file').val();
            if (fileId == customMusicFileId) {
              $('#modal-hold-music-file').modal('hide');
            }
            if (!fileId) {
              $.send('/scenarios/del_music_on_hold', {}, function () {
                $('#icon_remove_on_hold_music').addClass('hide');
                $('#modal-hold-music-file').modal('hide');
                return false;
              });
            }
            $.send('/scenarios/set_music_on_hold', {file_id: fileId}, function (result) {
              $('#custom_music_file_id').val(fileId);
              $('#icon_remove_on_hold_music').removeClass('hide');
              $('#modal-hold-music-file').modal('hide');
              return false;
            });
          }
        }],
        messages:
        '<div class="validate-entity-audio-file form-group">' +
        '<select class="form-control audio_file" name="file">' + filesOptions.join('') + '</select> ' +
        '<span class="help-block" validate="file"></span>' +
        '<div class="p-t">' +
        '<form id="hold-music-load-file" enctype="multipart/form-data" class="hide" method="post">' +
        '<input id="hold-music-load" class="" type="file" onchange="$(\'#hold-music-load-submit\').click()">' +
        '<input id="hold-music-load-submit" class="" type="submit">' +
        '</form>' +
        '</div>' +
        '</div>'
      });
      $('body').off('submit', '#hold-music-load-file');
      $('body').on('submit', '#hold-music-load-file', function (event) {
        $('#modal-hold-music-file .modal-footer .btn').loading();
        event.preventDefault();
        var files = $(event.target).find('input[type=file]').get(0).files,
          formData = new FormData(),
          file = files[0];
        if (!file.type.match('audio/mp3')) {
          alert('Формат файла должен быть mp3');
          return;
        }
        if (file.size / 1000000 > 10.00) {
          alert('Размер файла не должен превышать 10Мб');
          return;
        }
        formData.append('file', file, file.name);
        $.send('/audio_files/save', formData, {
          processData: false,
          contentType: false,
          success: function (data) {
            var filesOptions = ['<option></option>'];
            audioFiles.push(data);
            audioFiles.forEach(function (file) {
              if (!file.file_id) {
                return;
              }
              filesOptions.push(['<option ' + (data.id == file.id ? 'selected' : '') + ' value="' + file.id + '">' + file.name + '</option>']);
            });
            $('select.audio_file').replaceWith('<select class="form-control audio_file" name="file">' + filesOptions.join('') + '</select> ');
            formStyled();
          }
        });
      });
    };
    $.send('/vo/api/file', {}, function (files) {
      modalHoldMusic(files);
    });
  }
}

$(document).ready(function () {
  listGroupDraggable();
  formSubmitListener();
  $('body').on('click', '.duplicate', duplicate);
  $('body').on('click', '.remove-scenario', removeScenario);

  $('#icon_remove_on_hold_music').click(function () {
    $.send('/scenarios/del_music_on_hold', {}, function () {
      $('#icon_remove_on_hold_music').addClass('hide');
      $('#custom_music_file_id').val('');
    });
  });
});
var ivrLeafGoToBlock, ivr, ivrTree, outerPhones;

/**
 * Обработчик отправки формы
 */
function formSubmitListener() {
  $('#form_wait_music').submit(function (event) {
    event.preventDefault();
    var files = $(event.target).find('input[type=file]').get(0).files,
      formData = new FormData(),
      file = files[0];
    if (!file.type.match('audio/mp3')) {
      alert('Формат файла должен быть mp3');
      return;
    }
    if (file.size / 1000000 > 5.00) {
      alert('Размер файла не должен превышать 5Мб');
      return;
    }
    formData.append('wait_music[]', file, file.name);
    $.send('/scenarios/set_music_on_hold', formData, {
      processData: false,
      contentType: false,
      success: function (data) {
        var result = JSON.parse(data);
        if (result.error) {
          alert(result.error);
          return false;
        }
        $('#icon_remove_on_hold_music').removeClass('hide');
      }
    });
  });
};

/**
 * Перемещение элементов в списке сценариев
 */
function listGroupDraggable() {
  function revertDraggable($selector) {
    var $selector = $(this),
      position = $selector.data("originalPosition");

    if (position) {
      $selector.animate({
        left: position.left,
        top: position.top
      }, 500, function () {
        $selector.data("originalPosition", null);
      });
    }
  }

  var group = $(document).find('.list-group-draggable'),
    groupItems = group.find('.list-group-draggable-item'),
    cloneElement = {},
    block = {};
  $(".sortable").sortable().disableSelection();
  groupItems.draggable({
    connectToSortable: ".sortable",
    scroll: false,
    revert: 'invalid',
    start: function (event, ui) {
      cloneElement = ui.helper[0];
      block = ui.helper.parents('.list-group-draggable');
      this.dragBlock = $(this).parent();
    },
    stop: function (event, ui) {
      var $draggableElement = $(this),
        $dropBlock = $draggableElement.parent(),
        $dragBlock = this.dragBlock;
      var priorities = [];
      setTimeout(function () {
        $('.list-group-draggable-item').each(function () {
          priorities.push({
            name: 'item[' + $(this).attr('data-handler-id') + ']',
            value: $(this).find('.index-handler').text()
          });
        });
        $.send('/scenarios/set_priorities', priorities);
      }, 10);
      if ($dropBlock.attr('id') == $dragBlock.attr('id')) {
        recalculateIndices($dropBlock);
        recalculateIndices($dragBlock);
      }
      else {
        Confirm('Вы уверены, что хотите переместить сценарий?', function () {
          scenarioMove($draggableElement, $dropBlock, function (data) {
            recalculateIndices($dropBlock);
            recalculateIndices($dragBlock);
          });
        }, function () {
          $(block).append($draggableElement);
          recalculateIndices($dropBlock);
          recalculateIndices($dragBlock);
        });
      }
    }
  });
};

/**
 * Переносим сценарий на другой номер
 * @author Yury Lugovoy
 */
function scenarioMove($draggableElement, $dropBlock, callback) {
  $.send('/scenarios/move_phone_scenario', {
    id: $draggableElement.data('handlerId'),
    phoneId: $dropBlock.data('numberId'),
    clone: $draggableElement.data('clone') ? 1 : 0
  }, function (data) {
    if (data.error) {
      alert(data.error);
    }
    else {
      $draggableElement.data('handlerId', data.object.id);
      $draggableElement.find('.duplicate-link').show();
      callback(data);
    }
  });
};

/**
 * Пересчет индексов
 * @param $block
 */
function recalculateIndices($block) {
  $block.find('.list-group-draggable-item').each(function (key, item) {
    $(item).find('.index-handler').html(key + 1);
  });
};

/**
 * Дублирование сценария
 * @author Yury Lugovoy
 */
function duplicate(e) {
  var $element = $(e.target).parents('.list-group-draggable-item'),
    $block = $element.parents('.list-group-draggable'),
    $cloneElement = $element.clone();
  $cloneElement.find('.duplicate-link').hide();
  $cloneElement
    .data('clone', true)
    .insertAfter($element);
  recalculateIndices($block);
  listGroupDraggable();
};

/**
 * Удаление сценария
 * @author Yury Lugovoy
 * @param e
 */
function removeScenario(e) {
  var $element = $(e.target).parents('.list-group-draggable-item'),
    $block = $element.parents('.list-group-draggable');
  if ($element.data('clone')) {
    $element.remove();
    recalculateIndices($block);
    return true;
  }
  Confirm('Удалить сценарий для номера?', function () {
    $.send('/scenarios/del_phone_scenario', {id: $element.data('handlerId')}, function (result) {
      if (result.error) {
        alert(result.error);
      }
      else {
        $element.remove();
        recalculateIndices($block);
      }
    });
  });
};

ivrTree = function (ivr, place) {
  var self = this;
  this.block_result_config = null;
  this.place = place;
  this.$place = $(place);
  this.$place.empty();
  this.$place.data('ivr', this);
  this.ivr = ivr;

  setTimeout(function () {
    self.build();
  }, 0);

  this.newId = function () {
    var id = 1;
    while ($.inArray(id, this.itemsIdNums) != -1) {
      id++;
    }
    return id;
  };

  this.build = function (leaf, link, $level) {
    var self = this;
    $level = $level ? $level : this.$place;
    if (!leaf) {
      self.itemsIds = [];
      self.itemsIdNums = [];
    }
    if (leaf && leaf.id) {
      self.itemsIds.push(leaf.id);
      var new_item_id = parseInt(leaf.id.replace(/^.*?_(\d+)$/, '$1'));
      self.itemsIdNums.push(new_item_id);
    }
    leaf = leaf ? leaf : this.ivr;
    var $leaf = self.leafHTML(leaf, link);
    $leaf.data('ivr_leaf', leaf);

    if (leaf.child.length) {
      var $childsLevel = $('<div class="level"></div>');
    }
    $.each(leaf.child, function (i, child) {
      self.build(child.child, child, $childsLevel);
    });
    if ($childsLevel) {
      $leaf.append($childsLevel);
    }
    $level.append($leaf);
  };

  this.leafHTML = function (block, link) {
    if (block.name == 'no_function') {
      return $('' +
        '<div class="leaf-block">' +
        '<div class="leaf leaf-type-no-function">' +
        '<div class="glyphicon glyphicon-remove-circle" onclick="ivrTree.delBlock(this)"></div>' +
        '<span>Подключите<br>многоуровневую схему<br> уже сейчас и расширьте<br>возможности вашего сценария</span><br>' +
        '<a href="javascript:void(0)"' +
        'class="btn btn-success btn-lg m-t-lg"' +
        'data-name="' + $('input[name=name_ivr]').val() + '"' +
        'data-cost="' + $('input[name=cost_ivr]').val() + '"' +
        'data-start_cost="' + $('input[name=start_cost_ivr]').val() + '"' +
        'onclick="switchFunction(this, \'add\', {id: 8, count: 1}, {reload: true, confirm: true})"' +
        '>' +
        'Включить функцию' +
        '</a>' +
        '</div>' +
        '<div class="brunch"></div>' +
        '<div class="brunch-right"></div>' +
        '<div class="brunch-left"></div>' +
        '</div>');
    }
    var params = ivrTree.getBlockParams(block),
      name = '',
      subName = '',
      domain = $('input[name=domain]').val();
      var countFile = 0;
    if (block.name == 'play_prompts') {

      if (block.playType === 'filePlay' && ivrTree.files && block.prompts && ivrTree.files[block.prompts[0]]) {
        name = ivrTree.files[block.prompts[0]];
        countFile = block.prompts.length;
      } else if (block.playType === 'contextPlay') {
        name = block.playPromptsContext;
      }
    }

    if (block.name == 'call_transfer_system' && block.transfer_number && ivrTree.employers) {
      var prefixId = block.transfer_number.slice(0, 1);
      if ((prefixId == 9) || (prefixId == 5) && (
          block.transfer_type != ivrTree.transferTypes.amocrm
          && block.transfer_type != ivrTree.transferTypes.bitrix24
          && block.transfer_type != ivrTree.transferTypes.cnord
        )) {
        ivrTree.employers.forEach(function (employer) {
          if (employer.number == block.transfer_number) {
            subName = (employer.lastname || '') + ' ' +
                      (employer.name || '') + ' ' +
                      (employer.middlename || '');
          }
        });
      }
      if (prefixId == 4 && (
          block.transfer_type != ivrTree.transferTypes.amocrm
          && block.transfer_type != ivrTree.transferTypes.bitrix24
          && block.transfer_type != ivrTree.transferTypes.cnord
        )) { //ivr
        ivrTree.ivrs.forEach(function (employer) {
          if (employer.number == block.transfer_number) {
            subName = employer.name;
          }
        });
      }
      if (prefixId == 6 && (
          block.transfer_type != ivrTree.transferTypes.amocrm
          && block.transfer_type != ivrTree.transferTypes.bitrix24
          && block.transfer_type != ivrTree.transferTypes.cnord
        )) { //groups
        ivrTree.groups.forEach(function (employer) {
          if (employer.number == block.transfer_number) {
            subName = employer.name;
          }
        });
      }
    }
    if (block.name == 'conference') {
      subName = ivrTree.conference_name;
    }

    if (
      block.name == 'call_transfer_system'
      && block.transfer_number
      && !subName
      && (
        block.transfer_type != ivrTree.transferTypes.amocrm
        && block.transfer_type != ivrTree.transferTypes.bitrix24
        && block.transfer_type != ivrTree.transferTypes.cnord
      )
    ) {
      if ($.inArray(parseInt(block.transfer_number.slice(0, 1)), [4, 5, 6, 9]) === -1) {
        subName = block.transfer_number;
      }
    }

    if (block.transfer_type == ivrTree.transferTypes.amocrm) {
      subName = 'AmoCrm';
    }

    if (block.transfer_type == ivrTree.transferTypes.bitrix24) {
      subName = 'bitrix24';
    }

    if (block.transfer_type == ivrTree.transferTypes.cnord) {
      subName = 'CNORD';
    }

    if (block.name == 'condition') {
      subName = block.condition_context;
    }

    if (block.name == 'condition_param') {
      subName = (block.condition_condition == 'equal' ? '=' : block.condition_condition == 'great' ? '>' : '<') + block.condition_value;
    }

    var parentBlock = '';

    if(block.name === 'goto_block') {
      if(parent.id === '' || block.id !== parent.id) {
        parent.id = '';
        parent.name = '';
      }

      if(typeof(block.goto2id) === 'string') {
        var headerName = block.goto2id.split(/_\d*$/)[0] || '';

        switch(headerName) {
          case 'menu':
            parent.name = 'Меню';
            break;
          case 'play_prompts':
            parent.name = 'Проиграть сообщение';
            break;
          case 'call_transfer_system':
            parent.name = 'Перевод вызова';
            break;
          case 'external_request':
            parent.name = 'Внешний запрос';
            break;

          case 'number_request':
            parent.name = 'Ввод цифр';
            break;
          case 'condition':
            parent.name = 'Условие';
            break;
          case 'conference':
            parent.name = 'Конференц комната';
            break;
        }
      }

      parentBlock = '<div class="go2block" data-parent="'+ block.id +'" data-text="'+ parent.name +'">'+ parent.name +'</div>';
    }

    return $(
      '<div class="leaf-block">' +
      '<div class="leaf menu-config leaf-type-' + block.name + '"' + (block.id ? ' id="' + block.id + '"' : '') + '>' +
      (link ? '<div class="leaf-link link-type-' + link.name + '" onclick="ivrTree.showChangeBlockLink(this)">' + (link.digit ? '<span>' + link.digit + '</span>' : '') + '</div>' : '') +
      (link ? '<div class="leaf-icon"></div>' : '') +
      (block.id ? '<div class="glyphicon glyphicon-remove-circle" onclick="ivrTree.delBlock(this)"></div>' : '') +
      (block.child.length ? '<div class="brunch-bottom"></div>' : '') +
      (block.id && block.name != 'exit' ? '<a href="javascript:void(0)" class="link_dotted text-uppercase ' +

        (block.name == 'call_transfer_system' && block.transfer_number ? block.transfer_number : '') + ' ' +
        (block.name == 'play_prompts' && block.prompts ? block.prompts[0] : '') +
        (block.name == 'conference' && block.prompts ? block.prompts[0] : '') +

        '" onclick="ivrTree.editBlock(this)">' + (name ? name : params.name) + '</a>' : '<span>' + params.name + '</span>') +
      ((block.name == 'play_prompts' && countFile > 1) ? '<div style="margin-top: 3px;"><small>&hellip; и ещё (' + (parseInt(countFile) - 1) + ')</small></div>' : '') +
      (subName ? '<span class=""><br>' + (subName.length >= 11 ? (subName.slice(0, 13).replace(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g, '8 ($2) $3-$4-$5')) + '...' : subName) + '</span>' : '') +
      ($.inArray(block.name, ivrTree.blocksWOJumps) != -1 ? '' : '<div class="add-after" onclick="ivrTree.addAfterMenu(this)"></div>') +
      parentBlock +
      '</div>' +
      (block.id ? '<div class="brunch"></div>' : '') +
      (block.id ? '<div class="brunch-right"></div>' : '') +
      (block.id ? '<div class="brunch-left"></div>' : '') +
      '</div>'
    );
  };

  this.findLeaf = function (leaf, parent) {
    var self = this;
    if (!parent) {
      parent = this.ivr;
    }

    var found = null;
    var foundInChilds = null;
    if (parent.child.length) {
      $.each(parent.child, function (i, child) {
        foundInChilds = self.findLeaf(leaf, child.child);
        if (foundInChilds) {
          found = foundInChilds;
        }
        if (child.child.id == leaf.id) {
          found = {parent: parent, i: i};
        }
      });
    }
    return found;
  };
};
ivrTree.names = {
  menu: 'Меню',
  //jump                 : 'Переход к меню',
  exit: 'Выход',
  start: 'Начало схемы',
  goto_block: 'Переход на блок',
  no_function: '',
  play_prompts: 'Проиграть сообщение',
  call_transfer_system: 'Перевод вызова',
  external_request: 'Внешний запрос',
  number_request: 'Ввод цифр',
  condition: 'Условие',
  condition_param: 'Значение',
  conference: 'Конференц комната'
};
ivrTree.icons = {
  menu: 'icon-menu-th',
  jump: 'icon-menu-const',
  exit: 'icon-menu-end',
  start: 'icon-menu-lk',
  goto_block: 'icon-menu-goto-block',
  no_function: '',
  play_prompts: 'icon-menu-volume',
  call_transfer_system: 'icon-menu-refresh',
  external_request: 'icon-menu-external-block',
  number_request: 'icon-menu-number-block',
  condition: 'icon-menu-goto-block',
  condition_param: 'icon-menu-goto-block',
  conference: 'icon-menu-key'

};
ivrTree.transferTypes = {
  employers: 3,
  groups: 3,
  ivrs: 3,
  phone: 7,
  fax: 8,
  email: 9,
  amocrm: 12,
  bitrix24: 14,
  cnord: 16,
  context: 23
};
ivrTree.symbols = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*', '#'];
ivrTree.blocksWOJumps = ['goto_block', 'jump', 'exit'];
ivrTree.files = {};
ivrTree.scripts = {};
ivrTree.conferences = {};
ivrTree.conferences_list = [];
ivrTree.ivrs = [];
ivrTree.getFiles = function () {
  $.send('/scenarios/files', {}, function (result) {
    var domain = $('input[name=domain]').val();
    result.forEach(function (item) {
      ivrTree.files[domain + '_' + item.id] = item.name;
      $('.' + domain + '_' + item.id).html(item.name);
    });
  });
};
ivrTree.getConferences = function () {
  $.send('/scenarios/conferences/list', {}, function(response) {
    response.forEach(function (item) {
      ivrTree.conferences[item.number] = item.name;
      ivrTree.conferences_list[item.number] = item.name;
      
      $('.' + item.number).after('<br><span>' + item.name + '</span>');
    });
  });
};
ivrTree.getScripts = function () {
  $.send('/scenarios/ivrs', {}, function (result) {
    var prefix = $('input[name=prefix_ivr]').val();
    ivrTree.ivrs = result;
    result.forEach(function (item) {
      ivrTree.scripts[prefix + '_' + item.number] = item.name;
      $('.4' + item.number).after('<br><span>' + item.name + '</span>');
    });
    ivrTree.ivrs.map(function (ivr) {
      ivr.number = prefix.toString() + ivr.number.toString();
    });
  });
};
ivrTree.getGroups = function () {
  $.send('/vo/api/group', {}, function (result) {
    var prefix = $('input[name=prefix_groups]').val();
    ivrTree.groups = result;
    ivrTree.groups.map(function (group) {
      group.number = prefix.toString() + group.number.toString();
      $('.' + group.number).after('<br><span>' + group.name + '</span>');
    });
  });
};
ivrTree.getEmployers = function () {
  $.send('/vo/api/employer', {}, function (result) {
    var prefix = $('input[name=prefix_employers]').val() || 9;
    ivrTree.employers = result;
    ivrTree.employers.map(function (employer) {
      if(employer.number.length === 3) {
        employer.number = prefix.toString() + employer.number.toString();
      } else {
        employer.number = employer.number.toString();
      }

      $('.' + employer.number).after('<br><span>' +
        (employer.lastname || '') + ' ' +
        (employer.name || '') + ' ' +
        (employer.middlename || '') +
      '</span>');
    });
  });
};
ivrTree.self = function (self) {
  return $(self).closest('.ivr-script').data('ivr');
};
ivrTree.getBlockParams = function (block) {
  if (ivrTree.names[block.name]) {
    return {
      name: ivrTree.names[block.name],
      icon: ivrTree.icons[block.name]
    };
  }
  if (block.name == 'digit_link') {
    return ivrTree.getBlockParams(block.child);
  }
};
ivrTree.addAfterMenu = function (self) {
  var params = {
    leaf: $(self).closest('.leaf').get(0),
    self: self,
    attrClass: 'float-menu-main'
  };
  ivrTree.jumpMenu(params);
};
ivrTree.addBeforeMenu = function (self) {
  var parentLeaf = $(self).closest('.leaf-block').parent().closest('.leaf-block').find('> .leaf');
  ivrTree.toBlockMenu({
    childLeaf: $(self).closest('.leaf').get(0),
    leaf: parentLeaf,
    self: self,
    attrClass: 'float-menu-main'
  });
};
ivrTree.jumpMenu = function (params) {
  var leaf = params.leaf;
  var childLeaf = params.childLeaf;
  var self = params.self;
  var $parentMenu = params.$parentMenu;
  var ivr = ivrTree.self(leaf);
  var menuLeaf = params.menuLeaf ? params.menuLeaf : leaf;
  var ivrMenuLeaf = $(menuLeaf).closest('.leaf-block').data('ivr_leaf');
  var expandMenu = ivrMenuLeaf.name;
  if ($parentMenu) {
    expandMenu = $(self).attr('param');
  }
  var jumpCall = null;
  var jumpMenu = 'toBlockMenu';
  if (childLeaf || params.menuLeaf) {
    jumpMenu = null;
    jumpCall = childLeaf ? 'addBlock' : 'changeBlockLink';
  }

  var jumps = [];
  var allJumps;

  if (expandMenu == 'call_transfer_system') {
    allJumps = [
      //{name: 'Удачно',        menu: jumpMenu, call: jumpCall, param: 'success',  link: 'true', icon: 'icon-menu-smile'},
      {name: 'Неудачно', menu: jumpMenu, call: jumpCall, param: 'fail', link: 'true', icon: 'icon-menu-frown'},
      {name: 'При неответе', menu: jumpMenu, call: jumpCall, param: 'noanswer', link: 'true', icon: 'icon-menu-remove'},
      {name: 'При занятости', menu: jumpMenu, call: jumpCall, param: 'busy', link: 'true', icon: 'icon-menu-ban-circle'}
    ];
  }
  else {
    allJumps = [
      {
        name: 'По умолчанию',
        menu: jumpMenu,
        call: jumpCall,
        param: 'default_link',
        link: 'true',
        icon: 'icon-menu-default'
      }
    ];
  }

  if (expandMenu == 'menu') {
    allJumps.unshift({name: 'По символу', menu: 'jumpMenu', param: 'digits', icon: 'icon-menu-key'});
  }
  if (expandMenu == 'external_request') {
    allJumps = [
      {name: 'Успешно', menu: jumpMenu, call: jumpCall, param: 'success_link', link: 'true', icon: 'icon-menu-smile'},
      {name: 'Неуспешно', menu: jumpMenu, call: jumpCall, param: 'fail', link: 'true', icon: 'icon-menu-frown'}
    ];
  }

  if (expandMenu == 'condition') {
    allJumps = [
      {name: 'Значение', menu: '', call: 'addBlock', param: 'condition_param', link: 'true', icon: 'icon-menu-default'},
      {name: 'По-умолчанию', menu: jumpMenu, call: jumpCall, param: 'condition_default_link', link: 'true', icon: 'icon-menu-condition_default'}
    ];
  }


  if (expandMenu == 'menu') {
    jumps = [];
    var busyDigits = [];
    if (!childLeaf) {
      $.each(ivrMenuLeaf.child, function (i, child) {
        if (child.name == 'digit_link') {
          busyDigits.push(child.digit);
        }
        if (child.name == 'default_link') {
          busyDigits.push(child.name);
        }
      });
    }
    $.each(ivrTree.symbols, function (i, symbol) {
      if ($.inArray(symbol, busyDigits) != -1) {
        return;
      }
      jumps.push({
        name: '<span class="icon-menu-key-symbol">' + symbol + '</span>' + 'По символу: ' + symbol,
        menu: jumpMenu,
        call: jumpCall,
        param: 'digit_link',
        link: 'true',
        digit: symbol,
        icon: 'icon-menu-key'
      });
    });
    if ($.inArray('default_link', busyDigits) === -1) {
      jumps.unshift({
        name: 'По умолчанию',
        menu: jumpMenu,
        call: jumpCall,
        param: 'default_link',
        link: 'true',
        icon: 'icon-menu-default'
      });
    }
  }
  else {
    var busyJumps = [];
    if (!childLeaf) {
      $.each(ivrMenuLeaf.child, function (i, child) {
        busyJumps.push(child.name);
      });
    }
    $.each(allJumps, function (i, jump) {
      if ($.inArray(jump.param, busyJumps) != -1) {
        return;
      }
      jumps.push(jump);
    });
  }

  if (!jumps.length || jumpCall == 'changeBlockLink') {
    return;
  }

  if (jumps.length == 1 && jumpCall != 'changeBlockLink' && expandMenu != 'external_request' && expandMenu != 'condition') {
    $(leaf).find('.add-after').attr('param', jumps.pop().param);
    ivrTree.toBlockMenu({
      self: self,
      leaf: leaf,
      childLeaf: childLeaf,
      menuLeaf: params.menuLeaf,
      attrClass: params.attrClass
    });
    return;
  }

  ivrTree.showMenu(self, jumps, {
    ivr: ivr.place,
    leaf: leaf,
    childLeaf: childLeaf,
    menuLeaf: params.menuLeaf,
    attrClass: params.attrClass
  });
};
ivrTree.toBlockMenu = function (params) {
  var self = params.self;
  var childLeaf = params.childLeaf;
  var leaf = params.leaf;
  var ivr = ivrTree.self(leaf);
  var jumpCall = 'addBlock';
  var jumpMenu = null;
  if (childLeaf) {
    jumpCall = null;
    jumpMenu = 'jumpMenu';
  }

  var menu = [
    {name: 'Меню', call: jumpCall, menu: jumpMenu, param: 'menu', icon: 'icon-menu-th'},
    {name: 'Проиграть сообщение', call: jumpCall, menu: jumpMenu, param: 'play_prompts', icon: 'icon-menu-volume'},
    {name: 'Перевод вызова', call: jumpCall, menu: jumpMenu, param: 'call_transfer_system', icon: 'icon-menu-refresh'},
    {
      name: 'Внешний запрос',
      call: jumpCall,
      menu: jumpMenu,
      param: 'external_request',
      icon: 'icon-menu-external-block'
    },
    {name: 'Ввод цифр', call: jumpCall, menu: jumpMenu, param: 'number_request', icon: 'icon-menu-number-block'},
    {name: 'Условие', call: jumpCall, menu: jumpMenu, param: 'condition', icon: 'icon-menu-condition'},
    {name: 'Конференц комната', call: jumpCall, menu: jumpMenu, param: 'conference', icon: 'icon-menu-key'}
  ];

  if (!childLeaf) {
    menu = menu.concat([
      {name: 'Переход на блок', call: jumpCall, menu: jumpMenu, param: 'goto_block', icon: 'icon-menu-goto-block'},
      //{name: 'Переход к меню',     call: jumpCall, menu: jumpMenu, param: 'jump', icon: 'icon-menu-const'},
      {name: 'Выход', call: jumpCall, menu: jumpMenu, param: 'exit', icon: 'icon-menu-end'}
    ]);
  }

  ivrTree.showMenu(self, menu, {
    ivr: ivr.place,
    leaf: leaf,
    childLeaf: childLeaf,
    menuLeaf: params.menuLeaf,
    attrClass: params.attrClass
  });
};
ivrTree.showMenu = function (self, menu, params) {
  if (!$('.shadow').length) {
    $('body').append('<div class="shadow" onclick="ivrTree.cancel()"></div>');
  }
  var $menu = $('<ul class="float-menu menu-config ' + params.attrClass + '"' + (params && params.ivr ? ' ivr="' + params.ivr + '"' : '') + '></ul>');
  $.each(menu, function (i, item) {
    $menu.append('<li class="float-menu-item" onmouseover="ivrTree.openSubmenu(this)"' + (item.call ? ' onclick="ivrTree.clickMenu(this)"' : '') + ' ' + ivrTree.domAttrs(item) + '><span class="icon-menu ' + item.icon + '"></span>' + item.name + '</li>');
  });
  $('body').append($menu);
  setTimeout(function () {
    if (params && params.leaf) {
      $menu.data('leaf', params.leaf);
      $menu.data('childLeaf', params.childLeaf);
      $menu.data('menuLeaf', params.menuLeaf);
    }
    $menu.data('parent', $(self));
    $(self).data('child', $menu);
    var selfPos = $(self).pos();
    var selfHeight = $(self).outerHeight(true);
    if ($menu.hasClass('float-menu-main')) {
      $menu.css({left: (selfPos.right - $menu.outerWidth(true) / 2 - 14), top: selfPos.top + selfHeight + 12});
    } else {
      $menu.css({left: selfPos.right, top: selfPos.top});
    }
  }, 0);
  return $menu;
};
ivrTree.domAttrs = function (object) {
  var attrs = [];
  $.each(object, function (key, value) {
    value = value ? value : '';
    attrs.push(key + '="' + value.replace(/"/g, '&quot;') + '"');
  });
  return attrs.join(' ');
};
ivrTree.showChangeBlockLink = function (self) {
  var parentLeaf = $(self).closest('.leaf-block').parent().closest('.leaf-block').find('> .leaf');
  ivrTree.jumpMenu({
    menuLeaf: parentLeaf,
    leaf: $(self).closest('.leaf').get(0),
    self: self
  });
};
ivrTree.closeSubmenu = function ($menu) {
  $menu.find('li').each(function () {
    if ($(this).data('child')) {
      ivrTree.closeSubmenu($(this).data('child'));
      $(this).data('child').remove();
    }
  });
};

ivrTree.openSubmenu = function (self) {
  var $floatMenu = $(self).closest('.menu-config');
  ivrTree.closeSubmenu($floatMenu);
  var menu = $(self).attr('menu');
  if (menu) {
    menu = ivrTree[menu]({
      self: self,
      leaf: $floatMenu.data('leaf'),
      childLeaf: $floatMenu.data('childLeaf'),
      menuLeaf: $floatMenu.data('menuLeaf'),
      $parentMenu: $floatMenu
    });
  }
};
ivrTree.clickMenu = function (self) {
  var $floatMenu = $(self).closest('.menu-config');
  var call = $(self).attr('call');
  if (call) {
    ivrTree[call]({
      ivr: $($floatMenu.attr('ivr')).data('ivr'),
      ivrLeaf: $($floatMenu.data('leaf')).closest('.leaf-block').data('ivr_leaf'),
      ivrChildLeaf: $($floatMenu.data('childLeaf')).closest('.leaf-block').data('ivr_leaf'),
      param: $(self).attr('param'),
      self: self
    });
  }
  ivrTree.cancel();
};
ivrTree.addBlock = function (params) {
  var self = params.self;
  var ivr = params.ivr;
  var ivrLeaf = params.ivrLeaf;
  var ivrChildLeaf = params.ivrChildLeaf;
  var $parent = $(params.self).closest('.menu-config').data('parent');
  var $parentParent = $parent.closest('.menu-config').data('parent');
  var parentParam = $parent.attr('param');
  var parentParentParam;
  this.block_result_config = null;

  if ($parentParent) {
    parentParentParam = $parentParent.attr('param');
  }
  var newItem;
  var haveLimitMenu = $('input[name=have_ivr_function]').val() == 1 ? false : true;
  var limit = false;
  if (haveLimitMenu && ivr.itemsIds) {
    ivr.itemsIds.forEach(function (item) {
      if (item.match(/menu/gi)) {
        limit = true;
      }
      ;
    });
  }

  if (ivrChildLeaf) {
    var foundIvrChild = ivr.findLeaf(ivrChildLeaf);
    var ivrChildLink = foundIvrChild.parent.child[foundIvrChild.i];
    var newItemName = parentParam == 'digits' ? parentParentParam : parentParam;
    if ((limit && newItemName == 'menu') || (haveLimitMenu && newItemName == 'jump')) {
      ;
      newItemName = 'no_function';
    }
    newItem = {
      "name": ivrChildLink.name,
      "type": "link",
      "child": {"name": newItemName, "type": "block", "child": [], "id": newItemName + "_" + ivr.newId()}
    };
    if (ivrChildLink.name == 'digit_link') {
      newItem.digit = ivrChildLink.digit;
    }
    if (newItemName == 'external_request') {
      newItem.child.external_action = '';
      newItem.child.external_timeout_request = 3;
    }
    if (newItemName == 'number_request') {
      newItem.child.number_request_timeout = 5;
      newItem.child.number_request_count = 3;
      newItem.child.field_name = '';
    }
    if (newItemName == 'condition') {
      newItem.child.condition_context = '';
    }
    if (newItemName == 'menu') {
      newItem.child.timeout_input = 10;
    }
    if (newItemName == 'call_transfer_system') {
      newItem.child.timeout_connection = 30;
      newItem.child.movie_off = 0;
    }

    if (params.param == 'digit_link') {
      ivrChildLink.digit = $(self).attr('digit');
    }
    var found = ivr.findLeaf(ivrChildLeaf);
    if (found) {
      ivrChildLink.name = params.param;
      params.param == 'digit_link'
        ? (ivrChildLeaf.digit = $(self).attr('digit'))
        : (delete ivrChildLink.digit);
      found.parent.child[found.i] = newItem;
      newItem.child.child.push(ivrChildLink);
    }
  }
  else {
    if (
      (limit && params.param == 'menu') ||
      (haveLimitMenu && params.param == 'jump') ||
      (haveLimitMenu && params.param == 'goto_block') ||
      (haveLimitMenu && ivrLeaf && params.param == ivrLeaf.name) ||
      (haveLimitMenu && (ivrLeaf.name != 'menu' && ivrLeaf.name != 'play_prompts') && ivrLeaf.name != 'start' && params.param != 'exit')
    ) {
      params.param = 'no_function';
    }
    newItem = {
      "name": parentParam,
      "type": "link",
      "child": {
        "name": params.param,
        "type": "block",
        "child": [],
        "id": params.param + "_" + ivr.newId()
      }
    };

    if (parentParam == 'digit_link') {
      newItem.digit = $parent.attr('digit');
    }
    if (params.param == 'menu') {
      newItem.child.timeout_input = 10;
    }
    if (params.param == 'call_transfer_system') {
      newItem.child.timeout_connection = 30;
      newItem.child.movie_off = 0;
    }

    if (params.param == 'external_request') {
      newItem.child.external_action = '';
      newItem.child.external_timeout_request = 3;
    }
    if (params.param == 'condition') {
      newItem.child.condition_context = '';
    }

    if (params.param == 'number_request') {
      newItem.child.number_request_timeout = 3;
      newItem.child.number_request_count = 5;
      newItem.child.field_name = '';
    }

    if (params.param == 'condition_param') {
      newItem.child.condition_condition = 'equal';
      newItem.child.condition_value = '';
      newItem.name = 'condition_link';
    }

    if (params.param === 'conference') {
      newItem.child.conference_list = '';
      newItem.child.conference_name = '';
    }

    this.block_result_config = jQuery.extend({}, newItem);
    this.child_element = ivrLeaf;
    ivrLeaf.child.push(newItem);
  }
  ivrTree.rebuild(ivr);
  if (newItem.child.name != 'exit' && params.param != 'no_function') {
    setTimeout(function () {
      ivrTree.editBlock(newItem.child, ivr);
    }, 0);
  }
};
ivrTree.changeBlockLink = function (params) {
  var self = params.self;
  var ivr = params.ivr;
  var ivrLeaf = params.ivrLeaf;
  var foundIvrLeaf = ivr.findLeaf(ivrLeaf);
  var ivrLeafLink = foundIvrLeaf.parent.child[foundIvrLeaf.i];
  ivrLeafLink.name = params.param;
  params.param == 'digit_link'
    ? (ivrLeafLink.digit = $(self).attr('digit'))
    : (delete ivrLeafLink.digit);

  ivrTree.rebuild(ivr);
};
ivrTree.delBlock = function (self) {
  var ivrLeaf = $(self).closest('.leaf-block').data('ivr_leaf');
  var ivr = ivrTree.self(self);
  var found = ivr.findLeaf(ivrLeaf);
  if (found) {
    found.parent.child.splice(found.i, 1);
  }
  ivrTree.rebuild(ivr);
};
ivrTree.editBlock = function (self, ivr) {
  var ivrLeaf,
    enable_integrate = $("#form_create_scenario").data('integrate') == 1 ? true : false,
    enable_integrate_bitrix24 = $("#form_create_scenario").data('integrate_bitrix24') == 1 ? true : false;
  enable_integrate_cnord = $("#form_create_scenario").data('integrate_cnord') == 1 ? true : false;
  if (ivr) {
    ivrLeaf = self;
  }
  else {
    ivrLeaf = $(self).closest('.leaf-block').data('ivr_leaf');
    ivr = ivrTree.self(self);
  }
  var $dialog = $('<div class="ivr-dialog"></div>');
  $dialog.data('ivrLeaf', ivrLeaf);

  if (
    this.block_result_config
    && $.inArray(this.block_result_config.name, ['fail', 'noanswer', 'busy']) != -1
    && ivrTree.child_element.name != 'external_request'
  ) {
    var dop_type_check = {
      fail: (this.block_result_config.name == 'fail' ? true : false),
      noanswer: (this.block_result_config.name == 'noanswer' ? true : false),
      busy: (this.block_result_config.name == 'busy' ? true : false)
    };
    $.each(ivrTree.child_element.child, function (i, m) {
      dop_type_check[m.name] = true;
    });
    $dialog.append(
      '<div class="ivr-input form-group">' +
      ' <label class="checkbox-inline p-a-0"><input id="dop_type_fail" ' + (dop_type_check['fail'] ? 'checked disabled' : '') + ' type="checkbox"/><span>Неудачно</span></label> ' +
      ' <label class="checkbox-inline p-a-0"><input id="dop_type_noanswer" ' + (dop_type_check['noanswer'] ? 'checked disabled' : '') + ' type="checkbox"/><span>При неответе</span></label> ' +
      ' <label class="checkbox-inline p-a-0"><input id="dop_type_busy" ' + (dop_type_check['busy'] ? 'checked disabled' : '') + ' type="checkbox"/><span>При занятости</span></label> ' +
      ' <hr> ' +
      '</div> '
    );
  }

  if ($.inArray(ivrLeaf.name, ['external_request']) != -1) {
    $dialog.append(
      '<div class="ivr-input form-group">' +
      '<label class="control-label" for="ivr_external_action">Событие <span class="text-danger">*</span></label> <i class="info-block fa fa-info-circle" aria-hidden="true" title="Только латинские буквы, дефис, цифры, символ _"></i>' +
      '<input type="text" id="ivr_external_action" class="form-control valid-required valid-letnum" value="' + ivrLeaf.external_action + '"/>' +
      '<span class="help-block" validate="ivr_external_action"></span>' + '</div><div class="ivr-input form-group">' +
      '<label class="control-label" for="ivr_external_timeout_request">Время ожидания, сек</label>' +
      '<input type="text" id="ivr_external_timeout_request" class="form-control valid-required valid-digit" data-mask="999" maxlength="3" value="' + ivrLeaf.external_timeout_request + '"/>' +
      '<span class="help-block" validate="ivr_external_timeout_request"></span>' +
      '</div>'
    );
  }

  if ($.inArray(ivrLeaf.name, ['condition']) != -1) {
    $dialog.append(
      '<div class="ivr-input form-group">' +
      '<label class="control-label" for="ivr_condition_context">Переменная из контекста <span class="text-danger">*</span></label> <i class="info-block fa fa-info-circle" aria-hidden="true" title="Только латинские буквы, дефис, цифры, символ _"></i>' +
      '<input type="text" id="ivr_condition_context" class="form-control valid-required valid-letnum" value="' + ivrLeaf.condition_context + '"/>' +
      '<span class="help-block" validate="ivr_condition_context"></span>' + '</div><div class="ivr-input form-group">' +
      '</div>'
    );
  }

  if ($.inArray(ivrLeaf.name, ['conference']) != -1) {
    var prompts = [];
    if (ivrLeaf.prompts) {
      $.each(ivrLeaf.prompts, function (i, v) {
        prompts.push(v);
      });
    }
    $dialog.append(
      '<div class="ivr-input form-group">' +
        '<label class="control-label" for="ivr_conferences_list">Конференц комнаты <span class="text-danger">*</span></label>' +
          ivrTree.addConferenceInput(null, prompts) +
      '</div>'
    );
  }

  if ($.inArray(ivrLeaf.name, ['number_request']) != -1) {
    $dialog.append(
      '<div class="ivr-input form-group">' +
      '<label class="control-label" for="ivr_number_request_timeout">Время ожидания, сек</label>' +
      '<input type="text" id="ivr_number_request_timeout" class="form-control valid-required valid-digit" data-mask="999" maxlength="3" value="' + ivrLeaf.number_request_timeout + '"/>' +
      '<span class="help-block" validate="ivr_number_request_timeout"></span>' +
      '</div><div class="ivr-input form-group">' +
      '<label class="control-label" for="ivr_number_request_count">Максимальное количество цифр</label>' +
      '<input type="text" id="ivr_number_request_count" class="form-control valid-required valid-digit" data-mask="99" maxlength="2" value="' + ivrLeaf.number_request_count + '"/>' +
      '<span class="help-block" validate="ivr_number_request_count"></span>' +
      '</div><div class="ivr-input form-group">' +
      '<label class="control-label" for="ivr_field_name">Поле в context <span class="text-danger">*</span></label> <i class="info-block fa fa-info-circle" aria-hidden="true" title="Только латинские буквы, дефис, цифры"></i>' +
      '<input type="text" id="ivr_field_name" class="form-control valid-required       valid-letnum" value="' + ivrLeaf.field_name + '"/>' +
      '<span class="help-block" validate="ivr_field_name"></span>' +
      '</div>'
    );
  }

  if ($.inArray(ivrLeaf.name, ['condition_param']) != -1) {
    $dialog.append(
      '<div class="ivr-input form-group">' +
      '<label class="control-label" for="ivr_condition_condition">Сравнение</label>' +
      '<select id="ivr_condition_condition" style="display: none; height: 150px;">' +
      '<option value="equal" ' + (ivrLeaf.condition_condition == "equal" ? 'selected' : '') + '>Равно</option>' +
      '<option value="great" ' + (ivrLeaf.condition_condition == "great" ? 'selected' : '') + '>Больше</option>' +
      '<option value="less" ' + (ivrLeaf.condition_condition == "less" ? 'selected' : '') + '>Меньше</option>' +
      '</select>' +
      '<label class="control-label" for="ivr_condition_value">Значение</label>' +
      '<input type="text" id="ivr_condition_value" class="form-control valid-required valid-letnum" value="' + ivrLeaf.condition_value + '"/>' +
      '</div>'
    );
  }

  if ($.inArray(ivrLeaf.name, ['call_transfer_system']) != -1) {
    $dialog.append(
      '<div class="ivr-input form-group" style="display: none">' +
      '<label class="checkbox-inline p-a-0"><input type="checkbox" ' + (ivrLeaf.use_originator_rights ? 'checked' : '') + ' id="ivr_input_use_originator_rights"/><span>Производить вызов от имени вызывающего абонента</span></label>' +
      '</div>');
  }

  if ($.inArray(ivrLeaf.name, ['menu']) != -1) {
    var prompts = [];
    if (ivrLeaf.prompts) {
      $.each(ivrLeaf.prompts, function (i, v) {
        prompts.push(v);
      });
    }
    $dialog.append(
      '<div class="ivr-input form-group">' +
      '<label class="control-label valid-required">Аудиофайл <span class="text-danger">*</span></label>' +
      ivrTree.addFileInput(null, prompts) +
      '</div>');
  }

  if ($.inArray(ivrLeaf.name, ['play_prompts']) != -1) {
    $dialog.append(
      '<div class="radio m-t-0">\n' +
      '  <label class="p-y" for="fileSelect">\n' +
      '    <input type="radio" name="playType" id="fileSelect" value="filePlay" ' + ((ivrLeaf.playType === 'filePlay' || ivrLeaf.playType === undefined) ? 'checked' : '') + '>\n' +
      '    <span>Файл из списка</span>\n' +
      '  </label>\n' +
      '  <label class="p-y m-l-lg" for="contextSelect">\n' +
      '    <input type="radio" name="playType" id="contextSelect" value="contextPlay" ' + (ivrLeaf.playType === 'contextPlay' ? 'checked' : '') + '>\n' +
      '    <span>Поле в context</span>\n' +
      '  </label>\n' +
      '</div>'
    );
  }

  if ($.inArray(ivrLeaf.name, ['play_prompts']) != -1) {
    var prompts = [];
    if (ivrLeaf.prompts) {
      $.each(ivrLeaf.prompts, function (i, v) {
        prompts.push(v);
      });
    }
    $dialog.append(
      '<div class="ivr-input form-group p-t-md ' + ((ivrLeaf.playType === 'filePlay' || ivrLeaf.playType === undefined) ? 'show' : 'hide') + '" id="typePlayFile">' +
      '<label class="control-label valid-required">Аудиофайл <span class="text-danger">*</span></label>' +
      ivrTree.addFileInput(null, prompts) +
      '</div>' +
      '<div class="ivr-input form-group p-t-md ' + (ivrLeaf.playType === 'contextPlay' ? 'show' : 'hide') + '" id="typePlayContext">' +
      '<label class="control-label" for="ivr_play_prompts_context">Файлы из контекста <span class="text-danger">*</span></label> <i class="info-block fa fa-info-circle" aria-hidden="true" title="Только латинские буквы, дефис, цифры, символ _"></i>' +
      '<input type="text" id="ivr_play_prompts_context" class="form-control valid-required valid-letnum" value="' + (ivrLeaf.playPromptsContext ? ivrLeaf.playPromptsContext : '') + '"/>' + '<span class="help-block" validate="ivr_play_prompts_context"></span>' +
      '</div>');
  }
  if ($.inArray(ivrLeaf.name, ['menu']) != -1) {
    $dialog.append(
      '<div class="ivr-input form-group">' +
      '<label class="control-label valid-required" for="ivr_input_timeout_input">Ожидание ввода, сек <span class="text-danger">*</span></label>' +
      '<input type="text" id="ivr_input_timeout_input" class="form-control valid-required valid-digit" data-mask="999" maxlength="3" value="' + ivrLeaf.timeout_input + '"/>' +
      '<span class="help-block" validate="ivr_input_timeout_input"></span>' +
      '</div>');
  }
  if ($.inArray(ivrLeaf.name, ['call_transfer_system']) != -1) {

    //тикет 9667 - при параметре movie_off=1 (отмечена галка) скрываем "ожидание соединения".
    if (ivrLeaf.movie_off == 1) {
      timeout_hide = ' hide';
    } else {
      timeout_hide = '';
    }

    $dialog.append(
      '<div class="ivr-input form-group' + timeout_hide + '" id="wait_connect_time">' +
      '<label class="control-label" for="ivr_input_timeout_connection">Ожидание соединения, сек</label>' +
      '<input type="text" id="ivr_input_timeout_connection" class="form-control valid-required valid-digit" data-mask="999" maxlength="3" value="' + ivrLeaf.timeout_connection + '"/>' +
      '<span class="help-block" validate="ivr_input_timeout_input"></span>' +
      '</div>');

    var input = '', selectedNumber = '', selected = '', component, i, j;
    var components = ['ivrs', 'groups', 'employers'];
    for (j in components) {
      component = components[j];
      for (i in ivrTree[component]) {
        if (!selected && ivrLeaf.transfer_number && ivrTree[component][i]['number'] == ivrLeaf.transfer_number) {
          selected = component;
          selectedNumber = ivrTree[component][i]['number'];
        }
      }
    }


    if (!selected && ivrLeaf.transfer_type && $.inArray(ivrLeaf.transfer_type, [8, 9]) != -1) {
      $.each(ivrTree.transferTypes, function (k, v) {
        if (v == ivrLeaf.transfer_type) {
          selected = k;
          selectedNumber = ivrLeaf.transfer_number
        }
      });
    }
    if (!selected && (ivrLeaf.transfer_type == ivrTree.transferTypes.amocrm)) {
      selected = 'amocrm';
    }
    if (!selected && (ivrLeaf.transfer_type == ivrTree.transferTypes.bitrix24)) {
      selected = 'bitrix24';
    }
    if (!selected && (ivrLeaf.transfer_type == ivrTree.transferTypes.cnord)) {
      selected = 'cnord';
    }
    if (!selected && (ivrLeaf.transfer_type == ivrTree.transferTypes.context)) {
      selected = 'context';
    }
    if (!selected && !ivrLeaf.transfer_number) {
      selected = 'employers';
    }
    if (!selected && ivrLeaf.transfer_number) {
      selected = 'phone';
    }

    if (selected != 'amocrm' && selected != 'bitrix24' && selected != 'cnord') {
      if (selected != 'phone' && selected != 'context') {
        var selectedList = ivrTree[selected];
        var keyName = 'name';
        var keyValue = 'number';
        if ($.inArray(selected, ['fax', 'email']) != -1) {
          selectedList = ivrTree['employers'].slice();
          selectedList.push({email: 'другой'});
          keyName = 'email';
          keyValue = 'email';
        }
        var options = [];
        var selectedValueFound = false;
        for (i in selectedList) {
          selectedValueFound = selectedValueFound || selectedNumber == selectedList[i][keyValue];

          options.push('<option ' + (selectedNumber == selectedList[i][keyValue] ? "selected" : "") + ' value="' + selectedList[i][keyValue] + '">' +
            (selectedList[i].lastname || '') + ' ' +
            (selectedList[i][keyName] || '') + ' ' +
            (selectedList[i].middle || '') +
            (~$.inArray(selected, ['employers']) ?
            ' (' + selectedList[i].number + ')' : '') +
            '</option>');
        }
        if (selectedNumber && $.inArray(selected, ['fax', 'email']) != -1 && !selectedValueFound) {
          options.unshift('<option value="' + selectedNumber + '">' + selectedNumber + '</option>');
        }
        input = '<select  id="ivr_input_transfer_number" class="select-search">' + options.join('') + '</select>';
      }
      else if (selected === 'context'){
        input = '<input class="form-control valid-required valid-letnum" type="text" id="ivr_input_transfer_number" value="' + ivrLeaf.transfer_number + '">';
      }
      else {
        if(selected === 'phone') {
          ivrLeaf.transfer_number = ivrLeaf.transfer_number.replace(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g, '+$1 ($2) $3-$4-$5');
        }

        input = '<input class="form-control valid-required mask-phone" maxlength="11" type="text" id="ivr_input_transfer_number" value="' + ivrLeaf.transfer_number + '">';
      }
    } else {
      input = '<div id="ivr_input_transfer_number"></div>';
    }

    $dialog.append(
      '<div class="ivr-input form-group m-b-0">' +
      '<label class="control-label" for="ivr_input_transfer_number">Перевод</label>' +
      '<i class="info-block fa fa-info-circle hide" data-target="context-number" aria-hidden="true" title="Только латинские буквы, дефис, цифры, символ _"></i>' +
      '<div class="row">' +
      '<div class="form-group col-sm-6 m-b-md">' +
      '<select id="ivr_input_transfer_type">' +
      '<option value="employers" ' + (selected == "employers" ? 'selected' : '') + '>на сотрудника</option>' +
      '<option value="groups" ' + (selected == "groups" ? 'selected' : '') + '>на группу вызова</option>' +
      '<option value="ivrs" ' + (selected == "ivrs" ? 'selected' : '') + '>на сценарий</option>' +
      '<option value="phone" ' + (selected == "phone" ? 'selected' : '') + '>на мобильный телефон</option>' +
      '<option value="fax" ' + (selected == "fax" ? 'selected' : '') + '>на виртуальный факс</option>' +
      '<option value="email" ' + (selected == "email" ? 'selected' : '') + '>на голосовую почту</option>' +
      '<option value="context" ' + (selected == "context" ? 'selected' : '') + '>на номер из контекста</option>' +
      (enable_integrate ? '<option value="amocrm" ' + (selected == "amocrm" ? 'selected' : '') + '>на менеджера AmoCrm</option>' : '') +
      (enable_integrate_bitrix24 ? '<option value="bitrix24" ' + (selected == "bitrix24" ? 'selected' : '') + '>на менеджера Битрикс24</option>' : '') +
      (enable_integrate_cnord ? '<option value="cnord" ' + (selected == "cnord" ? 'selected' : '') + '>на пульт CNORD</option>' : '') +
      '</select>' +
      '</div>' +
      '<div class="form-group col-sm-6 m-b-lg">' +
      input +
      '<span class="help-block" validate="ivr_input_transfer_number"></span>' +
      '</div>' +
      '</div>' +
      '</div>'
    );

    if (selected == 'email') {
      movie_off_hide = '';
    } else {
      movie_off_hide = ' hide';
    }

    $('#ivr_input_transfer_number > option').remove();

    var alertMissedInput = '';
    if (ivrLeaf.alert_missed && ivrLeaf.alert_missed.length) {
      $.each(ivrLeaf.alert_missed, function (k, item) {
        alertMissedInput += emailsSelectInput(item);
      });
    }
    else {
      alertMissedInput += emailsSelectInput();
    }

    $dialog.append(
      '<div class="ivr-movie-off ' + movie_off_hide + '"><label class="checkbox-inline p-a-0" style="margin-bottom:10px"><input type="checkbox" ' + (ivrLeaf.movie_off && ivrLeaf.movie_off == 1 ? 'checked' : '') + ' id="ivr_input_movie_off"/><span>Отключить ролик по умолчанию</span></label></div>' +
      '<div class="ivr-input form-group' + (selected != 'employers' && selected != 'groups' && selected != 'amocrm' && selected != 'bitrix24' ? ' hide' : '') + '">' +
      '<label class="checkbox-inline p-a-0" style="margin-bottom:10px"><input type="checkbox" ' + (ivrLeaf.alert_missed && ivrLeaf.alert_missed.length ? 'checked' : '') + ' id="ivr_input_alert_missed"/><span>Уведомлять на почту о пропущенных вызовах</span></label>' +
      '<div class="ivr-alert-missed-group p-t-md' + (ivrLeaf.alert_missed && ivrLeaf.alert_missed.length ? '' : ' hide') + '">' + alertMissedInput + '</div>' +
      '</div>');

    formStyled();
  }

  $('body').on('change', '#ivr_input_transfer_type', function (e) {
    var option = '';
    if ($(this).val() == 'email') {
      $('.ivr-movie-off').removeClass('hide');
      $('.ivr-movie-off').addClass('show');
    }
    else {
      $('.ivr-movie-off').removeClass('show');
      $('.ivr-movie-off').addClass('hide');
      ivrLeaf.movie_off = 0;
    }

    var infoBlock = $(this).parents('.form-group').find('.info-block');

    if ($.inArray($(this).val(), ['employers', 'groups', 'ivrs', 'fax', 'email']) != -1) {
      var optionsList = ivrTree[$(this).val()];
      var keyValue = 'number';
      var keyName = 'name';
      var go2id = null;
      var ivrParentBack = null;
      var item = '';

      infoBlock.addClass('hide');
      if ($.inArray($(this).val(), ['fax', 'email']) != -1) {
        keyValue = 'email';
        keyName = 'email';
        optionsList = ivrTree['employers'].slice();
        optionsList.push({email: 'другой'});
      }
      for (var i in optionsList) {
        if(~$.inArray($(this).val(), ['fax', 'email'])) {
          item = (optionsList[i][keyName] || '');
        } else {
          item = (optionsList[i].lastname || '') + ' ' +
            (optionsList[i][keyName] || '') + ' ' +
            (optionsList[i].middle || '') +
            ((/^[59]{1}/.test(optionsList[i].number)) ?
              ' (' + (optionsList[i].number || '') + ')' : '');
        }

        option += '<option value="' + optionsList[i][keyValue] + '">' + item +
          '</option>';
      }
      $('#ivr_input_transfer_number').replaceWith('<select id="ivr_input_transfer_number">' + option + '</select>');
    }
    if ($(this).val() == 'phone') {
      $('#ivr_input_transfer_number').replaceWith('<input class="form-control valid-phone valid-required mask-phone" type="text" id="ivr_input_transfer_number" value="" placeholder="+7 (xxx) xxx-xx-xx">');
    }
    if ($(this).val() == 'context') {
      infoBlock.removeClass('hide');
      $('#ivr_input_transfer_number').replaceWith('<input class="form-control valid-required valid-letnum www" type="text" id="ivr_input_transfer_number" value="">');
    }
    if ($(this).val() == 'amocrm' || $(this).val() == 'bitrix24' || $(this).val() == 'cnord') {
      $('#ivr_input_transfer_number').parent().hide();
    } else {
      $('#ivr_input_transfer_number').parent().show();
    }
    if ($.inArray($(this).val(), ['employers', 'phone', 'amocrm', 'bitrix24', 'groups']) == -1) {
      if ($('#ivr_input_alert_missed').is(':checked')) {
        $('#ivr_input_alert_missed').click();
      }
      $('#ivr_input_alert_missed').closest('.ivr-input').addClass('hide');
      $('.ivr-alert-missed-group').addClass('hide');
    }
    else {
      $('#ivr_input_alert_missed').closest('.ivr-input').removeClass('hide');
    }
    formStyled();
  });
  $('body').on('change', '#ivr_input_transfer_number', function (e) {
     $('.help-block').attr('validate', 'ivr_email');
    $('.help-block:last-of-type').text('').parent().removeClass('has-error');
    if ($.inArray($('#ivr_input_transfer_type').val(), ['fax', 'email']) != -1 && $(this).val() == 'другой') {
      $('#ivr_input_transfer_number').replaceWith('<div id="ivr_input_transfer_number" class="input-group form-group">' +
        '<input type="text" class="form-control valid-email" id="ivr_email" placeholder="user@mail.ru">' +
        '<div class="input-group-btn">' +
        '<button type="button" class="btn btn-default"><span class="glyphicon glyphicon-remove"></span></button>' +
        '</div>' +
        '<div class="help-block" validate="ivr_email" style="top: 2.8em;"></div>' +
        '</div>');
      setTimeout(function () {
        $('#ivr_input_transfer_number input').focus();
        $('#ivr_input_transfer_number button').click(function () {
          $('#ivr_input_transfer_type').change();
        });
      }, 0);
    }
  });
  $('body').on('change', 'input[name=playType]', function () {
    if ($('input[name=playType]:checked').val() === 'contextPlay') {
      $('#typePlayFile').removeClass('show');
      $('#typePlayFile div').removeClass('validate-entity-file');
      $('#typePlayFile div select').removeClass('valid-required');
      $('#typePlayFile').addClass('hide');
      $('#typePlayContext').removeClass('hide');
      $('#typePlayContext').addClass('show');
    } else {
      $('#typePlayFile').removeClass('hide');
      $('#typePlayFile').addClass('show');
      $('#typePlayFile div').addClass('validate-entity-file');
      $('#typePlayFile div select').addClass('valid-required');
      $('#typePlayContext').removeClass('show');
      $('#typePlayContext').addClass('hide');
    }

  });
  //тикет 9667 - при отметке "отключить ролик по умолч." скрываем "ожидание соединения".
  $('body').on('change', '#ivr_input_movie_off', function () {
    if ($('#ivr_input_movie_off').is(':checked')) {
      mo_checked = 1;
      $('#wait_connect_time').removeClass('show');
      $('#wait_connect_time').addClass('hide');
    }
    else {
      mo_checked = 0;
      $('#wait_connect_time').removeClass('hide');
      $('#wait_connect_time').addClass('show');
    }
    ivrLeaf.movie_off = mo_checked;
  });
  $('body').on('change', '.ivr_input_alert_missed', function () {
    var $parent = $(this).parent();
    if ($(this).val() == 'другой') {
      $(this).replaceWith('<div class="ivr_input_alert_missed input-group">' +
        '<input type="text" id="ivr_input_alert_email" class="form-control valid-email" placeholder="user@mail.ru">' +
        '<div class="input-group-btn">' +
        '<button type="button" class="btn btn-default"><span class="glyphicon glyphicon-remove"></span></button>' +
        '</div>' +
        '</div>');
      setTimeout(function () {
        $parent.find('input').focus();
        $parent.find('button').click(function () {
          $parent.find('.ivr_input_alert_missed').replaceWith(emailsSelectInput(null, true));
          formStyled();
        });
      }, 0);
    }
  });
  $('body').on('click', '.btn-ivr-remove-email', function () {
    if ($('.ivr-alert-missed-group .row').length > 1) {
      $(this).closest('.row').remove();
    }
  });
  $(document).on('click', '.btn-ivr-add-email', function () {
    var row = $(this).closest('.row:last-of-type');
    $(row).after($(emailsSelectInput()));
    formStyled();
  });
  $('body').on('change', '#ivr_input_alert_missed', function () {
    $('.ivr-alert-missed-group')[$(this).is(':checked') ? 'removeClass' : 'addClass']('hide');
  });
  if ($.inArray(ivrLeaf.name, ['goto_block']) != -1) {
    go2id = ivrLeaf.id || null;
    ivrTree.selectBlock($dialog);
    ivrTree.ivrLeafGoToBlock = $dialog;
    return;
  }

  if ($.inArray(ivrLeaf.name, ['jump']) != -1) {
    var jump2scriptOptions = ['<option></option>'];
    $.each(ivrTree.scripts, function (id, name) {
      jump2scriptOptions.push('<option value="' + id + '"' + (ivrLeaf.jump2script == id ? ' selected' : '') + '>' + name + '</option>');
    });
    $dialog.append(
      '<div class="ivr-input form-group">' +
      '<label class="control-label" for="ivr_input_jump2script">Сценарий для перехода</label> ' +
      '<select id="ivr_input_jump2script">' + jump2scriptOptions.join('') + '</select>' +
      '</div>');
  }

  var header = '';

  switch(ivrLeaf.name) {
    case 'menu':
      header = 'Меню';
      break;
    case 'play_prompts':
      header = 'Проиграть сообщение';
      break;
    case 'call_transfer_system':
      header = 'Перевод вызова';
      break;
    case 'external_request':
      header = 'Внешний запрос';
      break;

    case 'number_request':
      header = 'Ввод цифр';
      break;
    case 'condition':
      header = 'Условие';
      break;
    case 'conference':
      header = 'Конференц комната';
      break;
  }

  makeModal({
    id: '#ivr-dialog',
    title: header,
    messages: $('<div>').append($dialog.clone()).html(),
    buttons: [{
      text: 'Сохранить', handler: function () {
        try {
          var result = ivrTree.saveBlock(this);

          if (typeof(result) == 'undefined') {
            $('#ivr-dialog').modal('hide');
          }
        } catch(exp) {
          console.warn(exp.name + ': ' + exp.message);
        }

        ivrTree.rebuild(ivr);
        ivrTree.block_result_config = null;
        return false;
      }
    }],
    events: [
      {
        name: 'show.bs.modal', func: function () {
          $('.float-menu').remove();
          return false;
        }
      },
      {
        name: 'hidden.bs.modal', func: function () {
          $('#ivr-dialog').remove();
          return false;
        }
      }
    ]
  });
  $('.ivr-dialog').data('ivrLeaf', ivrLeaf);

  function emailsSelectInput(selected, justInput) {
    var alertMissedOptions = [];
    var alertMissedEmails = [];
    $.each(ivrTree['employers'].slice(), function (k, item) {
      alertMissedEmails.push(item.email);
    });
    if (selected && $.inArray(selected, alertMissedEmails) == -1) {
      alertMissedEmails.unshift(selected);
    }
    alertMissedEmails.push('другой');
    $.each(alertMissedEmails, function (k, item) {
      alertMissedOptions.push('<option' + (item == selected ? ' selected' : '') + '>' + item + '</option>');
    });
    var select = '<select class="ivr_input_alert_missed">' + alertMissedOptions.join('') + '</select>';
    if (justInput) {
      return select;
    }
    return '<div class="row form-group" style="margin-bottom:5px">' +
      '<div class="col-xs-10 p-r-0">' + select + '</div>' +
      '<div class="col-xs-1 p-r-0"><span class="btn-ivr-remove-email btn" style="padding-left:0;padding-right:0"><span class="glyphicon glyphicon-remove"></span></span></div>' +
      '<div class="col-xs-1 p-l-0"><span class="btn-ivr-add-email btn" style="padding-left:0;padding-right:0"><span class="glyphicon glyphicon-plus"></span></span></div>' +
      '</div>';
  }
};
ivrTree.cancelSelectBlock = function () {
  $('.background-fade-in').addClass('hide');
  $('.add-before').show();
  $('.add-after').show();
  $('.leaf-block .glyphicon-remove-circle').show();
  $('.leaf-block-fade').hide();
  $('#select_block_description').remove();
  $('.leaf-block').css('z-index', '0');
  $('#ivrPlace').css('z-index', '0');
  $('.leaf').css('z-index', '');
};
ivrTree.selectedBlock = function (id) {
  ivrTree.ivrLeafGoToBlock.data('ivrLeaf')['goto2id'] = id;
  ivrTree.cancelSelectBlock();
  ivrParentBack = $('#' + ivrTree.ivrLeafGoToBlock.data('ivrLeaf')['goto2id']).find('.link_dotted');

  $('#' + go2id).find('div[data-parent="' + go2id + '"]').attr('data-text', ivrParentBack.text()).text(ivrParentBack.text());

  parent.id = go2id;
  parent.name = ivrParentBack.text();
};
ivrTree.selectBlock = function ($dialog) {
  $('.background-fade-in').removeClass('hide');
  $('.leaf-block').css('z-index', '100000');
  $('#ivrPlace').css('z-index', '100000');
  $('.leaf').css('z-index', '100000');
  $('.add-before').hide();
  $('.add-after').hide();
  $('.leaf-block .glyphicon-remove-circle').hide();
  $('.leaf').append('<div class="leaf-block-fade"></div>');
  $('#ivrPlace').append('<div id="select_block_description">Выберите блок для перехода <button onclick="ivrTree.cancelSelectBlock()" class="btn btn-danger leaf-block-fade-button">Отмена</button></div>');
  $('#' + $dialog.data('ivrLeaf')['goto2id'] + ' .leaf-block-fade').css('background-color', '#19bb9a').css('opacity', 0.3);
};

/**
 * Сохранение параметров для блоков в переменные.
 * Проверку на валидность данных можно сделать сдесь
 * если вернуть false модальное окно не закроется и данные не обновятся
 * @param self
 * @returns {boolean}
 *
 */
ivrTree.saveBlock = function (self) {
  var $dialog = $(self).closest('.modal-dialog').find('.ivr-dialog');
  var ivrLeaf = $dialog.data('ivrLeaf');

  // Валидация формы модального окна
  if(!isModalFormValid()) return false;

  if ($.inArray(ivrLeaf.name, ['menu', 'play_prompts']) != -1) {
    $('#ivr_input_change_dtmf:checked').length
      ? delete ivrLeaf['interrupt_by_dtmf']
      : ivrLeaf['interrupt_by_dtmf'] = false;
  }

  if ($.inArray(ivrLeaf.name, ['play_prompts']) != -1) {
    ivrLeaf['playType'] = $('input[name=playType]:checked').val();
    if (ivrLeaf.playType === 'contextPlay') {
        ivrLeaf['playPromptsContext'] = $('#ivr_play_prompts_context').val().trim();
        delete ivrLeaf['prompts'];
    } else {
      delete ivrLeaf['playPromptsContext'];
      var prompts = [];
      $('.audio_file').each(function () {
        if (!$(this).val()) {
          return;
        }
        prompts.push($(this).val());
      });
      prompts.length
        ? ivrLeaf['prompts'] = prompts
        : delete ivrLeaf['prompts'];
    }
  }

  if ($.inArray(ivrLeaf.name, ['call_transfer_system']) != -1) {
    $('#ivr_input_use_originator_rights:checked').length
      ? ivrLeaf['use_originator_rights'] = true
      : delete ivrLeaf['use_originator_rights'];
  }

  if ($.inArray(ivrLeaf.name, ['menu', 'call_transfer_system']) != -1) {
    var prompts = [];
    $('.audio_file').each(function () {
      if (!$(this).val()) {
        return;
      }
      prompts.push($(this).val());
    });
    prompts.length
      ? ivrLeaf['prompts'] = prompts
      : delete ivrLeaf['prompts'];
  }

  if ($.inArray(ivrLeaf.name, ['menu']) != -1) {
    ivrLeaf.timeout_input = parseInt($('#ivr_input_timeout_input').val());
  }

  if ($.inArray(ivrLeaf.name, ['conference']) != -1) {
    ivrLeaf.conference_list = $('#ivr_conference').val();
    ivrTree.conference_name = $('#ivr_conference option:selected').text();

    var prompts = [];
    $('.conference').each(function () {
      if (!$(this).val()) {
        return;
      }
      prompts.push($(this).val());
    });
    prompts.length
      ? ivrLeaf['prompts'] = prompts
      : delete ivrLeaf['prompts'];
  }

  if ($.inArray(ivrLeaf.name, ['external_request']) != -1) {

    ivrLeaf.external_action = $('#ivr_external_action').val();

    if(ivrLeaf.external_action == '') {
      throw new Error('Поле "Событие" не должно быть пустым');
    }
    else {
      if(!(/^[\w\_]*$/i.test(ivrLeaf.external_action))) {
        throw new Error('Поле "Событие" должно содержать только латинские буквы, дефис, цифры, символ "_"');
      }
    }

    ivrLeaf.external_timeout_request = $('#ivr_external_timeout_request').val();

    if(ivrLeaf.external_timeout_request == '') {
      throw new Error('Поле "Время ожидания" не должно быть пустым');
    }
    else {
      if(!(/^\d+$/i.test(ivrLeaf.external_timeout_request))) {
        throw new Error('Поле "Время ожидания" должно содержать только цифры');
      }
    }
  }

  if ($.inArray(ivrLeaf.name, ['condition']) != -1) {
    ivrLeaf.condition_context = $('#ivr_condition_context').val();
  }

  if ($.inArray(ivrLeaf.name, ['number_request']) != -1) {
    ivrLeaf.number_request_timeout = $('#ivr_number_request_timeout').val();

    if(ivrLeaf.number_request_timeout == '') {
      throw new Error('Поле "Время ожидания" не должно быть пустым');
    }
    else {
      if(!(/^\d+$/i.test(ivrLeaf.number_request_timeout))) {
        throw new Error('Поле "Время ожидания" должно содержать только цифры');
      }
    }

    ivrLeaf.number_request_count = $('#ivr_number_request_count').val();

    if(ivrLeaf.number_request_count == '') {
      throw new Error('Поле "Максимальное количество цифр" не должно быть пустым');
    }
    else {
      if(!(/^\d+$/i.test(ivrLeaf.number_request_count))) {
        throw new Error('Поле "Максимальное количество цифр" должно содержать только цифры');
      }
    }

    ivrLeaf.field_name = $('#ivr_field_name').val();

    if(ivrLeaf.field_name == '') {
      throw new Error('Поле "context" не должно быть пустым');
    }
    else {
      if(!(/^\w+$/i.test(ivrLeaf.field_name))) {
        throw new Error('Поле "context" должно содержать только латинские буквы, дефис, цифры');
      }
    }
  }

  if ($.inArray(ivrLeaf.name, ['condition_param']) != -1) {
    ivrLeaf.condition_condition = $('#ivr_condition_condition').val();
    ivrLeaf.condition_value = $('#ivr_condition_value').val();
  }

  if ($.inArray(ivrLeaf.name, ['call_transfer_system']) != -1) {
    ivrLeaf.timeout_connection = $('#ivr_input_timeout_connection').val();

    if(ivrLeaf.timeout_connection == '') {
      throw new Error('Поле "Ожидание соединения" не должно быть пустым');
    }
    else {
      if(!(/^\d+$/i.test(ivrLeaf.timeout_connection))) {
        throw new Error('Поле "Ожидание соединения" должно содержать только цифры');
      }
    }
  }

  if ($.inArray(ivrLeaf.name, ['call_transfer_system']) != -1) {
    var transferType = $('#ivr_input_transfer_type').val();
    var $transferNumberInput = $('#ivr_input_transfer_number');
    if (!$transferNumberInput.is(':input')) {
      $transferNumberInput = $transferNumberInput.find(':input');
    }

    if ($transferNumberInput.length) {
      $transferNumberInput.removeClass('has-error');
      var transferNumber = $transferNumberInput.val().toString();

      if ($.inArray(transferType, ['fax', 'email']) != -1) {
        if (!/.+@.+\..+/.test(transferNumber)) {
          $transferNumberInput.focus().parent().addClass('has-error');
          return false;
        }
      }
      else if (~ $.inArray(transferType, ['context', 'phone'])){
        var info = (transferType === 'phone') ? 'Мобильный телефон' : 'Номер из контекста';

        if(transferNumber == '') {
            $('#ivr_input_transfer_number').parent().addClass('has-error').end().parent().find('.help-block').html('Поле не должно быть пустым');
          throw new Error('Поле "' + info + '" не должно быть пустым');
        }
        else {
          if(transferType === 'context') {
            if(!(/^\w+$/i.test(transferNumber))) {
              throw new Error('Поле "' + info + '" должно содержать только латинские буквы и/или цифры');
            }
          }
          else if(transferType === 'phone') {
            if (transferNumber.length > 4) {
              transferNumber = transferNumber.replace(/[^0-9]/g, '');

              if (transferNumber.length <= 11 && transferNumber.length >= 17) {
                $transferNumberInput.focus().parent().addClass('has-error');
                return false;
              }
            }
          }
        }
      }
    } else {
      transferNumber = '';
    }

    ivrLeaf.transfer_number = (transferNumber.length == 3 ? '4' : '') + transferNumber;
    ivrLeaf.transfer_type = ivrTree.transferTypes[transferType];

    if (ivrLeaf.alert_missed) {
      delete ivrLeaf.alert_missed;
    }
    if ($('#ivr_input_alert_missed').is(':checked') && $('.ivr-alert-missed-group').length) {
      ivrLeaf.alert_missed = [];
      $('.ivr_input_alert_missed').each(function () {
        var val = $(this).hasClass('input-group') ? $(this).find('input').val() : $(this).val();
        if (val) {
          ivrLeaf.alert_missed.push(val);
        }
      });
    }
  }

  if ($.inArray(ivrLeaf.name, ['goto_block']) != -1) {
    $('#ivr_input_block_id').val()
      ? ivrLeaf['goto2id'] = $('#ivr_input_block_id').val()
      : delete ivrLeaf['goto2id'];
  }

  if ($.inArray(ivrLeaf.name, ['jump']) != -1) {
    $('#ivr_input_jump2script').val()
      ? ivrLeaf['jump2script'] = $('#ivr_input_jump2script').val()
      : delete ivrLeaf['jump2script'];
  }

  if (
    ivrLeaf.transfer_type == ivrTree.transferTypes.amocrm
    || ivrLeaf.transfer_type == ivrTree.transferTypes.bitrix24
    || ivrLeaf.transfer_type == ivrTree.transferTypes.cnord
  ) {
    delete ivrLeaf.transfer_number;
  }

  if ($("#dop_type_fail:checked:not(:disabled)").length) {
    ivrTree.copyBlock(ivrLeaf, 'fail');
  }
  if ($("#dop_type_noanswer:checked:not(:disabled)").length) {
    ivrTree.copyBlock(ivrLeaf, 'noanswer');
  }
  if ($("#dop_type_busy:checked:not(:disabled)").length) {
    ivrTree.copyBlock(ivrLeaf, 'busy');
  }

  //ivrTree.cancel();
};

ivrTree.copyBlock = function (leaf, type) {
  var fail_copy_element = jQuery.extend({}, ivrTree.block_result_config);
  var new_ivrLeaf = jQuery.extend({}, leaf);
  new_ivrLeaf.id = new_ivrLeaf.name + "_" + ivr.newId();
  fail_copy_element.child = new_ivrLeaf;
  fail_copy_element.name = type;
  ivrTree.child_element.child.push(fail_copy_element);
};

ivrTree.addConferenceInput = function(self, selected, params) {
  var conferencesOptions = ['<option value="" disabled="disabled" selected hidden class="hidden">Выберите&hellip;</option>'];
  selected = selected ? selected : [];
  var found = false;
  var foundI = null;

  $.each(ivrTree.conferences, function (fileId, fileName) {
    if (!found && (foundI = $.inArray(fileId, selected)) != -1) {
      found = fileId;
      selected.splice(foundI, 1);
    }
    conferencesOptions.push('<option value="' + fileId + '"' + (found == fileId ? ' selected' : '') + '>' + fileName + '</option>');
  });

  var html = '<select class="conference valid-required" name="conference_list" id="ivr_conference">' + conferencesOptions.join('') + '</select> ' +
    '<span class="help-block" validate="conference_list"></span>';
  return html;
}

ivrTree.addFileInput = function (self, selected, params) {
  var filesOptions = ['<option value="" disabled="disabled" selected hidden class="hidden">Выберите&hellip;</option>'];
  selected = selected ? selected : [];
  var found = false;
  var foundI = null;

  $.each(ivrTree.files, function (fileId, fileName) {
    if (!found && (foundI = $.inArray(fileId, selected)) != -1) {
      found = fileId;
      selected.splice(foundI, 1);
    }
    filesOptions.push('<option value="' + fileId + '"' + (found == fileId ? ' selected' : '') + '>' + fileName + '</option>');
  });

  var html = '<select class="audio_file valid-required" name="file_list">' + filesOptions.join('') + '</select> ' +
    '<span class="help-block" validate="file_list"></span>';
  return html;
};
ivrTree.delFileInput = function (self) {
  $(self).closest('.ivr-block-input').remove();
};
ivrTree.onUploadFile = function (params) {
  if (params.error) {
    alert(params.error);
    return;
  }
  var domain = $('input[name=domain]').val();
  ivrTree.files[domain + '_' + params.id] = params.name;
  $('.audio_file').append('<option value="' + domain + '_' + params.id + '">' + params.name + '</option>');
  $('.audio_file').selectmenu('refresh');
};
ivrTree.delFile = function (self) {
  var $input = $(self).closest('.ivr-block-input').find('.audio_file');
  var fileId = $input.val();

  $.send('/scenarios/delFile/' + (fileId.split('_'))[1], {}, function () {
    $input.find('option[value="' + fileId + '"]').remove();
    $('.audio_file').selectmenu('refresh');
  });
};
ivrTree.rebuild = function (ivr) {
  $('.form-leave-notice').attr('data-form_changed', 'true');
  ivr.$place.empty();
  ivr.build();
};
ivrTree.cancel = function () {
  $('.shadow').remove();
  $('.float-menu').remove();
  $('.ivr-dialog').remove();
};

function saveIvr() {
  var id, phoneHandlerId;
  var errorField = $('.form-group input[name="error"]');
  if ($('input[name=scenario_id]').length) {
    id = $('input[name=scenario_id]').val();
  }
  if ($('input[name=handler_id]').length) {
    phoneHandlerId = $('input[name=handler_id]').val();
  }
  var name = $('#name').val();
  var $block = $('input[name=time_type]:checked').parents('.radio');
  var time_from_hours = $block.find('input[name=from_hours]').val(),
    time_from_minutes = $block.find('input[name=from_minutes]').val(),
    time_to_hours = $block.find('input[name=to_hours]').val(),
    time_to_minutes = $block.find('input[name=to_minutes]').val();
  time_from_hours = (time_from_hours && time_from_hours.length == 1 ? ('0' + time_from_hours.toString()) : time_from_hours);
  time_from_minutes = (time_from_minutes && time_from_minutes.length == 1 ? ('0' + time_from_minutes.toString()) : time_from_minutes);
  time_to_hours = (time_to_hours && time_to_hours.length == 1 ? ('0' + time_to_hours.toString()) : time_to_hours);
  time_to_minutes = (time_to_minutes && time_to_minutes.length == 1 ? ('0' + time_to_minutes.toString()) : time_to_minutes);
  if (time_to_hours == '24' && time_to_minutes == '00') {
    time_to_hours = '23';
    time_to_minutes = '59';
  }

  if(time_from_hours+time_from_minutes > time_to_hours+time_to_minutes) {
    addMessageError(errorField, 'Временной интервал указан неверно');
    return false;
  } else {
    removeMessageError(errorField);
  }

  if(ivr.ivr.child.length === 0) {
    addMessageError(errorField, 'Схема не создана');
    return false;
  } else {
    removeMessageError(errorField);
  }

  if (!name) {
    addMessageError(errorField, 'Введите название сценария');
    return false;
  } else {
    removeMessageError(errorField);
  }

  var data = {
    name: name,
    script: JSON.stringify(ivr.ivr),
    phoneId: $('select[name=phone]').val(),
    timeType: $('input[name=time_type]:checked').val(),
    days: [],
    phoneHandlerId: phoneHandlerId ? phoneHandlerId : '',
    time_from: (time_from_hours && time_from_minutes) ? time_from_hours + ':' + time_from_minutes : '',
    time_to: (time_to_hours && time_to_minutes) ? time_to_hours + ':' + time_to_minutes : ''
  };
  if (data.timeType === 'custom') {
    $('#weekdays_list li.active').each(function () {
      data.days.push($(this).data('id'));
    });
  }
  $('#save-scenario').loading();
  $.send('/scenarios/save' + (id ? ('/' + id) : ''), data, function (result) {
    if (parseInt(result.handler_id)) {
      $('.form-leave-notice').removeAttr('data-form_changed');
      window.location = '/scenarios/' + parseInt(result.handler_id);
    }
  });
}

$.fn.extend({
  attrs: function () {
    if (this.length === 0) {
      return null;
    }

    var attrs = {};
    $.each(this[0].attributes, function () {
      if (this.specified) {
        attrs[this.name] = this.value;
      }
    });
    return attrs;
  }
});

function ivrInit() {
  if ($('input[name=current_script]').length) {
    ivr = new ivrTree(JSON.parse($('input[name=current_script]').val()), '#ivrPlace');
  }
  else {
    ivr = new ivrTree({
      "id": null,
      "name": "start",
      "type": "block",
      "child": []
    }, '#ivrPlace');
  }
  ivrTree.getFiles();
  ivrTree.getScripts();
  ivrTree.getGroups();
  ivrTree.getEmployers();
  ivrTree.getConferences();
};

function handleIvrTree() {
  if ($('#ivrPlace').length) {
    ivrInit();
  }
}

function handleScenario() {
  handleIvrTree();
  $(".time").mask("99");
  $('input[name=time_type]').change(function (event) {
    $('.scenario-block:visible').hide();
    var $mustShow = $('#' + $(this).attr('id') + '-scenario-block');
    if ($mustShow.hasClass('hide')) {
      $mustShow.removeClass('hide');
    }
    $mustShow.show();
  });

  $('#weekdays_list>li').click(function (e) {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    }
    else {
      $(this).addClass('active');
    }
    return false;
  });
  $('#edit_name').click(function (e) {
    var nameLength = $('#scenario-name-edit>input').val().length;
    $(this).hide();
    $('#scenario-name').hide();
    $('#scenario-name-edit').removeClass('hide');
    $('#scenario-name-edit>input').focus();
    $('#scenario-name-edit>input').get(0).setSelectionRange(nameLength, nameLength);
    return false;
  });

  $('#scenario-name-edit>input').focusout(function () {
    var name = $('#scenario-name-edit>input').val();

    if(!isFormValid()) return false;

    $('#scenario-name>h2').html(name);
    $('#scenario-name-edit').addClass('hide');
    $('#scenario-name').show();
    $('#edit_name').show();
  });

  $('.reset-time').click(function () {
    var $block = $(this).parents('.scenario-block');
    $block.find('input').each(function (index, element) {
      $(element).val('00');
    });
  });

  $('#save-scenario').click(function () {
    if(!isModalFormValid()) return false;
    saveIvr();
  });
};

function goToScenario(id, self) {
  var $draggableElement,
    $dropBlock;
  $('[data-handler-id="' + id + '"]').each(function (i, element) {
    if ($(element).data('clone')) {
      $draggableElement = $(element);
    }
  });
  if ($draggableElement) {
    $dropBlock = $draggableElement.parents('div');
    $(self).attr('onclick', 'return false;');
    scenarioMove($draggableElement, $dropBlock, function (data) {
      location.href = '/scenarios/' + data.object.id;
    });
  }
  else {
    location.href = '/scenarios/' + id;
  }
};

$('body').on('submit', '#form-load-file', function (event) {
  event.preventDefault();
  var files = $(event.target).find('input[type=file]').get(0).files,
    formData = new FormData(),
    file = files[0];
  if (!file.type.match('audio/mp3')) {
    alert('Формат файла должен быть mp3');
    return;
  }
  if (file.size / 1000000 > 5.00) {
    alert('Размер файла не должен превышать 5Мб');
    return;
  }
  formData.append('file', file, file.name);
  $.send('/scenarios/loadFile', formData, {
    processData: false,
    contentType: false,
    success: function (data) {
      ivrTree.onUploadFile({id: data.file_id, name: data.file_name});
    }
  });
});
$('body').on('click', '.leaf-block-fade', function (e) {
  var id = $(e.target).parents('.leaf').attr('id');
  if (!id) {
    return;
  }
  ivrTree.selectedBlock(id);
});
$(document).on('click', '.leaf a[onclick]', function () {
  var ivrBlock = $(document).find('.modal-dialog .modal-body .ivr-block-input');

  if (ivrBlock.length > 1) {
    $(ivrBlock).not(':first').find('span.h4:last-of-type').removeClass('hide');
  }
});