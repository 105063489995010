// Устанавливаем маски
(function() {
  setMaskPhone($('.mask-phone'));
  setMaskGlobalPhone($('.mask-phone-global'));
  setMaskIp($('.mask-ip4'));
  setMaskMultipleIp($('.mask-ip4-multi'));
}());

// Установка масок в модальном окне
$(document).on('change click blur keydown', '.modal-body .form-control', function() {
  if ($(this).hasClass('mask-phone')) {
    setMaskPhone($(this));
  } else if ($(this).hasClass('.mask-phone-global')) {
    setMaskGlobalPhone($(this));
  } else if ($(this).hasClass('mask-ip4')) {
    setMaskIp($(this));
  } else if ($(this).hasClass('mask-ip4-multi')) {
    setMaskMultipleIp($(this));
  }
});

/**
 * Функция устанавливает маску для номеров телефонов РФ
 *
 * @author Sedov Stas
 * @param {object} target Поле с номером телефона
 */
function setMaskPhone(target) {
  $(target).mask('+7 (000) 000-00-00',  {
    'placeholder': "+7 (xxx) xxx-xx-xx"
  });
}

/**
 * Функция устанавливает маску для международных номеров телефонов
 *
 * Маски номеров телефонов стран мира: http://8sot.su/
 * @see masksGlobalPhones.js
 *
 * @author Sedov Stas
 * @param {object} target Поле с номером телефона
 */
function setMaskGlobalPhone(target) {
  var option = {
    onKeyPress: function(cep, event, currentField, options){
      var mask = '+0 (000) 000-00-00-000-000';
      var str = event.target.value.replace(/[^\+0-9]/g, '').replace('+8', '+7');

      // Применяем маски международных номеров телефона
      $(getMasksGlobalPhone()).each(function(key, val){
        $.each(val, function(index, elem) {
          if(str.match(elem.pattern)) mask = elem.mask;
        });
      });

      $(target).mask(mask, options);
    }
  };

  $(target).mask('+0 (000) 000-00-00-000-000', option);
}

/**
 * Функция устанавливает маску для IP-адреса
 *
 * @author Sedov Stas
 * @param {object} target Поле с IP-адресом
 */
function setMaskIp(target) {
  $(target).mask('099.099.099.099/099.099.099.099', {
    'placeholder': "xxx.xxx.xxx.xxx/xxx.xxx.xxx.xxx"
  });
}

/**
 * Функция устанавливает маску для для нескольких IP-адресов в строке через запятую
 *
 * @author Sedov Stas
 * @param {object} target Поле с IP-адресом
 */
function setMaskMultipleIp(target) {
  var mask = '099.099.099.099/099.099.099.099,'.repeat(6);

  var option =  {
    onKeyPress: function(cep, e, field, options) {
      var masks = ['099.099.099.099/099.099.099.099,'];
      var count = 6;
      var mask = '';

      // если в строке запятая, то дублируем шаблон маски
      if(~cep.lastIndexOf(',')) {
        mask = new Array(count + 1).join(masks[0]).slice(0, -1);
      }
      else {
        mask = masks[0];
      }

      $(target).mask(mask, options);
    }
  };

  $(target).mask(mask, option, {
    'placeholder': "xxx.xxx.xxx.xxx/xxx.xxx.xxx.xxx"
  });
}