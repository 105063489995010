function generateApiKey() {
  $('#genApiKey').submit(function (e) {
    e.preventDefault();
    Confirm('Вы действительно хотите сформировать новый ключ API?<br>Старый ключ перестанет действовать.', function () {
      $.send(e.target.action, {
        'apiKey': $('input[name=keyApi]').val()
      }, function (result) {
        $('input[name=keyApi]').val(result.apiKey);
      })
    })
  });
}